/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { i18n } from 'utils';
import Analytics from 'analytics';
import { Card, Icon } from '@tesla/design-system-react';
import { iconChevronSmall90, iconCheckFilled, iconRepeatFilled } from '@tesla/design-system-icons';
import { SIMILAR_DESIGNS_MODAL, MODAL_MEDIUM } from 'dictionary';

const Entry = ({
  showPreOrderSwap,
  setCurrentDesign,
  initialDesign,
  initialConfigByGroup,
  getEarlyDeliveryDesigns,
  openModal,
  selectedConfig,
  resetToCustomDesign,
  isGalleryState,
  isModalOpen,
  model,
}) => {
  useEffect(() => {
    if (showPreOrderSwap) {
      Analytics.fireInteractionEvent('similar-design-shown');
    }
  }, [showPreOrderSwap]);

  if (!showPreOrderSwap && !selectedConfig) {
    return null;
  }
  const fetchVehicleDesigns = () => {
    if (!isModalOpen) {
      openModal({
        componentName: SIMILAR_DESIGNS_MODAL,
        props: {
          containerCss: 'tds-flex--col_1of5 tds-flex--align-center',
          genericWrapper: true,
          size: MODAL_MEDIUM,
        },
      });
      setCurrentDesign({
        initialDesign,
        initialConfigByGroup,
      });
      getEarlyDeliveryDesigns({ fetchSimiliarDesigns: true });
      Analytics.fireInteractionEvent('similar-design-click');
    }
  };

  return (
    <div className="coin-preorderswap-entry-container">
      <Choose>
        <When condition={!selectedConfig}>
          <button onClick={fetchVehicleDesigns} className="tds-o--fullwidth">
            <Card density="dense" className="tds-text--center">
              <div className="tds-flex--center tds-flex--space-between tds-flex-gap--8 tds-o-flex-direction--row tds-o--fullwidth">
                <div className="tds-flex tds-flex-gap--8 tds-o-flex-direction--row tds-flex--center">
                  <Icon data={iconRepeatFilled} className="tds-o-steering-wheel" />
                  <div className="tds-text--body tds-o-flex-direction--column">
                    <span className="tds-text--medium tds--text-align-left tds-text--contrast-high">
                      {i18n(
                        `EarlyDelivery.choose_similar_design_available_${
                          isGalleryState ? 'soon' : 'now'
                        }`
                      )}
                    </span>
                    <span className="tds-text--contrast-low tds--text-align-left tds-text--contrast-low">
                      {i18n('EarlyDelivery.similar_design_promotion', {}, null, {
                        specificOverride: model,
                        returnNullWhenEmpty: true,
                      })}
                    </span>
                  </div>
                </div>
                <Icon data={iconChevronSmall90} />
              </div>
            </Card>
          </button>
        </When>
        <Otherwise>
          <Card density="dense" className="tds-text--center">
            <div className="tds-flex--center tds-flex--space-between tds-flex-gap--8 tds-o-flex-direction--row tds-o--fullwidth">
              <div className="tds-flex-gap--8 tds-o-flex-direction--row tds-flex--center">
                <Icon data={iconCheckFilled} className="tds-o-success-check-icon" />
                <div className="tds-text--body tds-o-flex-direction--column">
                  <span className="tds-text--medium tds--text-align-left tds-text--contrast-high">
                    {i18n('EarlyDelivery.similar_design_promotion_updated', {}, null, {
                      specificOverride: model,
                      returnNullWhenEmpty: true,
                    })}
                  </span>
                  <span className="tds-text--contrast-low tds--text-align-left tds-text--contrast-low">
                    {i18n('EarlyDelivery.similar_design_promotion_updated_items', {}, null, {
                      specificOverride: model,
                      returnNullWhenEmpty: true,
                    })}
                  </span>
                </div>
              </div>
              <button
                onClick={() => {
                  resetToCustomDesign();
                  Analytics.fireInteractionEvent('similar-design-undo');
                }}
                className="tds-link tds-text--contrast-low tds-o-align-self--flex-start"
              >
                {i18n('common.undo')}
              </button>
            </div>
          </Card>
        </Otherwise>
      </Choose>
    </div>
  );
};

Entry.propTypes = {
  showPreOrderSwap: bool.isRequired,
  setCurrentDesign: func.isRequired,
  initialDesign: shape({}).isRequired,
  initialConfigByGroup: shape({}).isRequired,
  getEarlyDeliveryDesigns: func.isRequired,
  openModal: func.isRequired,
  selectedConfig: string,
  resetToCustomDesign: func.isRequired,
  isGalleryState: bool.isRequired,
  model: string.isRequired,
};

Entry.defaultProps = {
  selectedConfig: '',
};

export default Entry;
