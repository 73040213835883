import React, { useEffect } from 'react';
import { string, func, arrayOf, shape, bool } from 'prop-types';
import { connect } from 'react-redux';
import { TabList, FormInputDropdown, FormLabel, FormItem } from '@tesla/design-system-react';
import classNames from 'classnames';
import { DEFAULT_FIN_TABS, PAYMENT_FIN_TABS, FinanceTypes, REGISTRATION_TYPE_PRIVATE } from 'dictionary';
import { getSelectedFinanceProduct, getFinanceTabs } from 'selectors';
import { selectTab, toggleFinancePanelDrivertype, setRegistrationType } from 'actions';
import { i18n } from '../../common/utils';
import _debounce from 'lodash/debounce';

const FinanceToggle = ({
  selectedTab,
  financeTabs = [],
  label,
  tabsSource = DEFAULT_FIN_TABS,
  onTabSelect,
  onChangeId,
  isCompact,
  showWithSingleItem,
  setDriverType,
  className = '',
  variant,
  animated,
  shouldShowDriverType,
  driverType,
  finplatDefaultCustomerType,
  setRegistrationType,
}) => {

  useEffect(() => {
    /*
      driverType is initially null. Then it's set based on the default finplat product just once
      Later driverType can change based on user selection
     */
    if (!driverType && shouldShowDriverType && finplatDefaultCustomerType) {
      setDriverType(finplatDefaultCustomerType);
    }
  }, [driverType, shouldShowDriverType, finplatDefaultCustomerType]);

  let items = financeTabs.map(({ id, ...fields }) => ({ ...fields, id, value: id, targetPanelId: `${tabsSource}-${id}-panel` }));

  if (!showWithSingleItem && items.length === 1) {
    return null;
  }

  const hasBusinessProducts = items.some(item => ['business', 'CT_BUSINESS'].some(type => item.customerType?.includes(type)));
  let options = items;

  const shouldSplitBasedOnDriverType = hasBusinessProducts && shouldShowDriverType;

  if (shouldSplitBasedOnDriverType) {
    /**
     * Find and clone Cash option for business for dropdown select
     */
    const businessCash = { ...items.find(item => item.financeType === FinanceTypes.CASH), id: FinanceTypes.CASH_BUSINESS };
    items = [businessCash, ...items];

    const customerTypeLabels = {
      private: i18n('FinancingOptions.driver_type_personal'),
      business: i18n('FinancingOptions.driver_type_business')
    }

    const formatOption = ({ id, label }, type) => ({
      value: id,
      id: `${type}-${id}`,
      fullLabel: label,
      label: customerTypeLabels[type] ? `${customerTypeLabels[type]}: ${label}` : label,
      customerType: type
    })

    const productsByCustomerType = items.reduce((acc, { customerType = [], ...item }) => {
      /**
       * If no customer type is defined (usually, non cash, FinCMS ALD products, add to both)
       */
      if (!customerType.length && item.financeProductId) {
        acc.private.push(formatOption(item, 'private'));
        acc.business.push(formatOption(item, 'business'));

        return acc
      }

      if (['private', 'personal', 'CT_PRIVATE'].some(type => customerType.includes(type)) || item.id === FinanceTypes.CASH) {
        acc.private.push(formatOption(item, 'private'))
      }

      if (['business', 'CT_BUSINESS'].some(type => customerType.includes(type)) || item.id === FinanceTypes.CASH_BUSINESS) {
        acc.business.push(formatOption(item, 'business'))
      }

      return acc
    }, { private: [], business: [] })

    options = [
      {
        value: 'private',
        label: customerTypeLabels.private,
        disabled: true,
      },
      ...productsByCustomerType.private,
      {
        id: 'separator',
        value: 'separator',
        fullLabel: <hr />,
        disabled: true,
      },
      {
        value: 'business',
        label: customerTypeLabels.business,
        disabled: true,
      },
      ...productsByCustomerType.business
    ]
  }

  const onChange = ({ value }) => {
    if (!value) return;

    const selectedOption = options.find(option => option.value === value);

    if (!hasBusinessProducts && shouldShowDriverType) {
      setRegistrationType(REGISTRATION_TYPE_PRIVATE)
    }
    if (selectedOption.customerType && shouldSplitBasedOnDriverType) {
      setDriverType(selectedOption.customerType);
    }
    onTabSelect(value, onChangeId);
  }

  // Make sure the tab labels aren't to squashed 
  const itemsCharLength = items.reduce((total, item) => total + `${item.label}`.length, 0);

  if (items.length > 3 || (itemsCharLength > 25 && [DEFAULT_FIN_TABS, PAYMENT_FIN_TABS].includes(tabsSource)) || shouldSplitBasedOnDriverType) {
    return (
      <div className={classNames('FinanceToggle dropdown', className)}>
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormInputDropdown
            label={label}
            enableSearch={false}
            name="finance-options-dropdown-selector"
            onOptionSelect={onChange}
            options={options}
            selected={selectedTab}
          />
        </FormItem>
      </div>
    )
  }

  return (
    <div className={classNames('FinanceToggle tabList', className)}>
      <TabList
        variant={variant}
        selected={selectedTab}
        animated={animated}
        fullWidth
        id={`${tabsSource}-tablist`}
        isCompact={isCompact}
        data-id="summary-finance-tabs"
        tabs={options}
        onTabChange={_debounce((e) => onChange({ value: e.target.id }),100)}
        className="tds-o-tab-list__overflow tds-width-full"
      />
    </div>
  )
};

const mapStateToProps = (state, { tabsSource = DEFAULT_FIN_TABS } ) => ({
  selectedTab: getSelectedFinanceProduct(state),
  financeTabs: getFinanceTabs(state, { sourceType: tabsSource }),
  shouldShowDriverType: state.FinancingOptions?.showFinanceProductsByDriverType,
  driverType: state?.FinancingOptions?.driverType,
  finplatDefaultCustomerType: state?.SummaryPanel?.finplatDefaultCustomerType,
})

const mapDispatchToProps = {
  onTabSelect: selectTab,
  setDriverType: toggleFinancePanelDrivertype,
  setRegistrationType: setRegistrationType,
}

FinanceToggle.propTypes = {
  onTabSelect: func.isRequired,
  setDriverType: func.isRequired,
  financeTabs: arrayOf(shape({})),
  tabsSource: string,
  onChangeId: string,
  label: string,
  isCompact: bool,
  variant: string,
  showWithSingleItem: bool,
  animated: bool,
  shouldShowDriverType: bool
};

FinanceToggle.defaultProps = {
  financeTabs: [],
  tabsSource: DEFAULT_FIN_TABS,
  onChangeId: 'FinanceToggle',
  label: '',
  isCompact: false,
  variant: 'underline',
  showWithSingleItem: false,
  animated: false,
  shouldShowDriverType: false
};

export default connect(mapStateToProps, mapDispatchToProps)(FinanceToggle);