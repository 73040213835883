/* eslint-disable react-hooks/exhaustive-deps */
/* eslint no-shadow: 0 */
/* eslint react/jsx-curly-newline: 0 */
import React, { useEffect, useRef, useState, Suspense } from 'react';
import { arrayOf, bool, func, instanceOf, shape, string } from 'prop-types';
import { Relevant } from 'informed';
import _includes from 'lodash/includes';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _upperFirst from 'lodash/upperFirst';
import _debounce from 'lodash/debounce';
import { Checkbox, Form, Input, PhoneInput, Select } from '@tesla/informed-tds';
import { FormFieldset } from '@tesla/design-system-react';
import { getVatNumberFormat, getVatNumberRegex, isvalidVatNumber } from '@tesla/intl-id';
import { getIDRegex, validateEntity, validatePerson } from '@tesla/coin-common-components';
import PhoneNumberVerification from '../PhoneNumberVerification';
import {
  ACCOUNT_TYPE_TITLE,
  CHAMBER_OF_COMMERCE,
  CHINESE_TITLE,
  CITIZEN_ID_NO,
  EMAIL,
  EMAIL_CONFIRM,
  FIELD_COMPANY_NAME,
  FIELD_DELIVERY_STATE_PROVINCE,
  FIELD_PRIVATE_VAT_ID,
  FIELD_VAT_ID,
  FIRST_NAME,
  IDENTIFICATION_NUMBER,
  IDENTIFICATION_TYPE,
  INVALID_EMAIL,
  INVALID_FIRST_NAME,
  INVALID_LAST_NAME,
  INVALID_PHONE_NUMBER,
  MIDDLE_NAME,
  LAST_NAME,
  LOCAL_NAME,
  PARMIT_NO,
  PASSPORT_NO,
  PASSWORD,
  PHONE_NUMBER,
  REGISTRATION_TYPE,
  REGISTRATION_TYPE_BUSINESS,
  REGISTRATION_TYPE_PRIVATE,
  UI_DATA_IDS,
  EMIRATE_VALUES,
  Models,
  PHONE_TITLE,
  NEW,
  TITLE_SUB_STATUS,
} from 'dictionary';
import {
  companyNameValidator,
  error__minLen,
  firstLastNameValidator,
  htmlToReact,
  i18n,
  isValidSiretNumber,
  regexValidator,
  enhancedCnIdNumberValidator,
} from 'utils';
import { isEmail } from '@tesla/coin-common-components';
import classnames from 'classnames';
import CompanySearch from './CompanySearch';
import AccountFormRegistrationType from './RegistrationType';
import CrossBorderRegistration from './CrossBorderRegistration';

const AccountFormRepresentation = props => {
  const formApiRef = useRef();
  const {
    market,
    showAccountTypeSwitch,
    registrationType,
    extraBusinessFields,
    showLocalName,
    showCompanyAgentLabel,
    extraPersonalFields,
    userFilledInData,
    initialEmailValue,
    verifierName,
    verificationStatusExists,
    registration,
    identificationLabel,
    showAccountDetailDisclaimer,
    setValidFlag,
    updateAccountDetails,
    updateDeliveryDetails,
    updateAccountIDTypeLabel,
    accountFormValid,
    isIntlPhoneInputEnabled,
    isCompanyAgentIdEnabled,
    emailConfirmEnabled,
    isBusinessVerificationEnabled,
    allowCrossBorderRegistration,
    phone,
    phoneCountryCode,
    defaultAccountType,
    isInventory,
    disableCompanySearch,
    hidePlaceHolderInput,
    serverError,
    showPrivateFields,
    setVerifiedPhoneNumber,
    isVerifyPhoneNumberEnabled,
    verifyPhoneNumberHost,
    verifyPhoneNumberUid,
    verifyPhoneNumberFlow,
    model,
    isNonBrandNewConsentEnabled,
    titleStatus,
    titleSubStatus,
    locale,
    taxOfficesList,
    usesOfInvoiceList,
    defaultUseOfInvoice,
  } = props;

  const [verifyPhoneResult, setVerifyPhoneResult] = useState(null);

  useEffect(() => {
    switch (serverError) {
      case INVALID_FIRST_NAME:
        formApiRef?.current?.setError('firstName', i18n('common.errors__firstLastNameNotValid'));
        break;
      case INVALID_LAST_NAME:
        formApiRef?.current?.setError('lastName', i18n('common.errors__firstLastNameNotValid'));
        break;
      case INVALID_EMAIL:
        formApiRef?.current?.setError('email', i18n('common.errors__emailNotValid'));
        break;
      case INVALID_PHONE_NUMBER:
        formApiRef?.current?.setError('phoneNumber', i18n('common.errors__phonenumberNotValid'));
        break;
    }
  }, [serverError]);

  const businessVatFormat =
    extraBusinessFields.includes(FIELD_VAT_ID) || isBusinessVerificationEnabled
      ? getVatNumberFormat(market, REGISTRATION_TYPE_BUSINESS)
      : null;

  const identificationLabelTitle = identificationLabel ? i18n(identificationLabel) : '';
  const idTypeOptions = [CITIZEN_ID_NO, PARMIT_NO, PASSPORT_NO];
  const idTypes = idTypeOptions.map(idTypeOpt => {
    return {
      label: i18n(`Review.${idTypeOpt}`),
      key: idTypeOpt,
      value: idTypeOpt,
    };
  });

  // validations
  const nameValidator = value => {
    if (value && firstLastNameValidator(value, market)) {
      return;
    } else {
      return i18n('common.errors__firstLastNameNotValid');
    }
  };

  const emailValidator = (value, { emailConfirm }) => {
    const errorText = i18n('common.errors__emailNotValid');
    const isValid = value && isEmail(value?.trim());
    if (market === 'CN') {
      if (emailConfirm?.toLowerCase().trim() !== undefined) {
        formApiRef?.current.validateField('emailConfirm');
      }
      // the regexp comes from ansman/validate.js 0.11.1 using in tesla-sso repo. https://stash.teslamotors.com/projects/ie/repos/tesla-sso/browse
      // eslint-disable-next-line no-useless-escape
      const isExtraValid = /^[a-z0-9\u007F-\uffff!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9\u007F-\uffff!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z]{2,}$/i.test(
        value
      );
      if (isValid && isExtraValid) {
        return;
      } else {
        return errorText;
      }
    }

    if (isValid) {
      return;
    } else {
      return errorText;
    }
  };

  const emailConfirmValidator = (value, { email }) => {
    if (value?.toLowerCase().trim() === email?.toLowerCase().trim()) {
      return;
    } else {
      return i18n('common.errors__emailNotEqual');
    }
  };

  /*eslint-disable */
  let phoneNumberRegEx = /^[0-9\+\(\)\[\]\s\.\-\#\*]+$/;
  const phoneNumberValidator = value => {
    const errorText = i18n('common.errors__phonenumberNotValid');
    if (market === 'CN') {
      const formatted = String(value).replace(/\D/g, '');
      if (value && formatted.length < 8) {
        return error__minLen(8);
      } else if ((value && formatted.length > 15) || (value && !phoneNumberRegEx.test(value))) {
        return errorText;
      } else {
        return;
      }
    }
    if (market !== 'CN') {
      if (value && value.length < 8) {
        return error__minLen(8);
      }
      return value && !phoneNumberRegEx.test(value) ? errorText : undefined;
    }
  };

  /* This function can be removed when the validation library supports checksum validation */
  const isValidVatCheckSum = (market, value, registrationType) => {
    const checksumValidationCountries = ['TR'];
    if (!checksumValidationCountries.includes(market)) {
      return true;
    }
    if (registrationType === REGISTRATION_TYPE_PRIVATE) {
      return validatePerson(market, value).isValid ?? false;
    }
    return validateEntity(market, value).isValid ?? false;
  };

  const vatIdValidator = (value, values) => {
    const { registrationType } = values;
    if (
      typeof value !== 'undefined' &&
      value.length &&
      (!isvalidVatNumber(market, value, registrationType) ||
        !isValidVatCheckSum(market, value, registrationType))
    ) {
      if (registrationType === REGISTRATION_TYPE_PRIVATE && registration.showPrivateFields) {
        return i18n('common.errors__privateVatNumberNotValid', {
          VAT_NUMBER_FORMAT: getVatNumberFormat(market, registrationType),
        });
      }
      return i18n('common.errors__vatNumberNotValid', {
        VAT_NUMBER_FORMAT: getVatNumberFormat(market, registrationType),
      });
    }

    return;
  };

  let companyNumberPlaceholder = '';
  let companyNumberFormatter = '';
  let companyNumberMask = '';
  if (market === 'DE') {
    companyNumberPlaceholder = 'HRB 1234';
    companyNumberFormatter = value => {
      let startArray = [];

      if (/^P/i.test(value)) {
        startArray.push(/(P|p)/, /(R|r)/);
      }

      if (/^V/i.test(value)) {
        startArray.push(/(V|v)/, /(R|r)/);
      }

      if (/^G/i.test(value)) {
        startArray.push(/(G|g)/, /(N|n)/, /(R|r)/);
      }

      if (/^H/i.test(value)) {
        startArray.push(/(H|h)/, /(R|r)/, /(A|a|B|b)/);
      }

      // minimum of 3 numbers
      const numberCount = Math.max(`${value}`.split('').filter(v => /\d/.test(v)).length, 3);
      const numberArray = Array(Math.min(numberCount, 6)).fill(/\d/);

      return [...startArray, ' ', ...numberArray, ' ', /[A-Z]/i, /[A-Z]/i, /[A-Z]/i];
    };
    companyNumberMask = value => (value != null ? `${value}`.trimEnd().toUpperCase() : value);
  } else if (market === 'IS') {
    companyNumberPlaceholder = getVatNumberFormat(market, REGISTRATION_TYPE_PRIVATE);
  }
  const chamberOfCommerceValidator = (value, values, { regex = '' }) => {
    const { registrationType } = values;
    let isValidCompanyNumber = false;

    if (typeof value !== 'undefined' && value.length) {
      if (market === 'GB') {
        isValidCompanyNumber =
          value.match(/^\d{8}$/) || value.match(/^SC\d{6}$/) || value.match(/^NI\d{6}$/);
      } else if (market === 'DE') {
        isValidCompanyNumber = value.match(/^(HRA|HRB|PR|VR|GNR)( \d{3,6})( ([A-Z]{1,3}))?$/);
      } else if (market === 'FR') {
        isValidCompanyNumber = isValidSiretNumber(value);
      } else if (market === "QA") {
        isValidCompanyNumber = registrationType === REGISTRATION_TYPE_PRIVATE ? /^\d{11}$/.test(value) : /^\d+$/.test(value);
      } else if (market === "IS") {
        isValidCompanyNumber = value.match(getVatNumberRegex(market, REGISTRATION_TYPE_PRIVATE));
      } else if (market === 'LT') {
        isValidCompanyNumber = /^\d{9}$/.test(value);
      } else {
        isValidCompanyNumber = value.match(
          regex || getVatNumberRegex(market, registrationType) || /^\d{18}$/
        );
      }
    }

    if (!isValidCompanyNumber) {
      return i18n('common.errors__pattern_chamber_of_commerce', { COMPANY_NUMBER_FORMAT: companyNumberPlaceholder }, null, { specificOverride: registrationType });
    } else {
      return;
    }
  };

  const identificationNumberValidator = (value, { identificationType = CITIZEN_ID_NO }) => {
    const regex = new RegExp(getIDRegex(market, identificationType));
    if (typeof value === 'undefined' || !value.length) {
      return;
    }
    let isValidIdPattern = regex.test(value);

    if (market === 'CN' && identificationType === CITIZEN_ID_NO) {
      isValidIdPattern = isValidIdPattern && validateDateFromId(value);
      if (
        isInventory
        && isNonBrandNewConsentEnabled
        && titleStatus.toLowerCase() === NEW
        && titleSubStatus === TITLE_SUB_STATUS.NON_BRAND_NEW
      ) {
        isValidIdPattern = isValidIdPattern && enhancedCnIdNumberValidator(value, identificationType);
      }
    }

    if (isValidIdPattern) {
      return;
    }

    return i18n('common.errors__pattern_identification_number');
  };

  const isLeapYear = (year) => {
    year = Number(year);
    return year % 4 === 0 && (year % 100!== 0 || year % 400 === 0);
  };

  const validateDateFromId = (idString) => {
    if (idString?.length < 15) {
      return true;
    }
    const dateStr = idString?.length > 15 ? idString?.slice(6, 14) : ('19' + idString?.slice(6, 12));
    if (isNaN(Number(dateStr))) {
        return false;
    }

    const year = dateStr.slice(0, 4);
    const month = dateStr.slice(4, 6);
    const day = dateStr.slice(6);
    const today = new Date();

    // Year range validation
    if (year > today.getFullYear()) {
      return false;
    }

    // Month and day range validation
    if (month < 1 || month > 12 || day < 1 || day > 31) {
        return false;
    }

    // February validation (consider leap years)
    if (month === '02') {
        return isLeapYear(year) ? day <= 29 : day <= 28;
    }

    // April, June, September, and November validation
    if (['04', '06', '09', '11'].includes(month)) {
        return day <= 30;
    }

    return true;
  };

  const getChineseTitle = () => {
    if (
      registrationType === REGISTRATION_TYPE_BUSINESS &&
      _includes(extraPersonalFields, CHINESE_TITLE)
    ) {
      return userFilledInData?.LastName ? window.tesla?.user?.lastName : null;
    }
    return null;
  };

  const chineseTitleText = getChineseTitle();

  let initialValues = Object.entries(userFilledInData).reduce((acc, [key, value]) => {
    switch (key) {
      case ACCOUNT_TYPE_TITLE:
        acc[`${REGISTRATION_TYPE[0].toLowerCase() + REGISTRATION_TYPE.slice(1)}`] = value;
        break;
      case PHONE_TITLE:
        acc[PHONE_TITLE] = {
          country: phoneCountryCode || market,
          number: phone || value,
          code: phoneCountryCode,
        };
      default:
        acc[`${key[0].toLowerCase() + key.slice(1)}`] = value;
    }
    return acc;
  }, {});

  if (chineseTitleText) {
    initialValues = {
      ...initialValues,
      chineseTitle: chineseTitleText,
    };
  }

  const accountDataRef = useRef(null);
  const accountFormValidRef = useRef(accountFormValid);
  const updateAccountForm = formState => {
    const { values, errors } = formState;
    const {
      registrationType: accountRegType,
      vatId = null,
      privateVatId = null,
      identificationType = null,
      identificationNumber = null,
      localName = '',
      firstName = '',
      middleName = '',
      lastName = '',
      email = null,
      phoneNumber = {},
      companyName = null,
      companyNumber = null,
      companyDetails,
      nonResidentPerson = false,
      nonResidentCompany = false,
      privateRegistrationCountry = null,
      businessRegistrationCountry = null,
      nationalRegistrationNumber = null,
      taxOfficeName = null,
    } = values;
    let { number } = phoneNumber || {};
    if (market === 'CN') {
      number = phoneNumber;
    }
    const { country = '' } = phoneNumber || {};
    const regAccountType = accountRegType || registrationType;
    const error = !!(Object.keys(errors).length);
    const storeBusinessFieldValues = !!(regAccountType === REGISTRATION_TYPE_BUSINESS);
    const storePrivateFieldValues = !!(regAccountType === REGISTRATION_TYPE_PRIVATE);
    const storeIdentificationTypeValue =
      storeBusinessFieldValues && _includes(extraBusinessFields, IDENTIFICATION_TYPE);
    const nationalId = storePrivateFieldValues
      ? companyNumber || companyDetails?.companyNumber || null
      : null;
    const formattedPhoneNumber = String(number ?? phoneNumber).replace(/\D/g, '');
    const companyCountryCode = nonResidentCompany
      ? businessRegistrationCountry
      : companyDetails?.companyCountryCode;

    const taxableInvoice = storePrivateFieldValues ? values?.taxableInvoice : companyDetails?.taxableInvoice;
    let privateVatIDNumber = storePrivateFieldValues ? privateVatId || nationalRegistrationNumber : null;
    let useOfInvoice = storePrivateFieldValues ? values?.useOfInvoice : companyDetails?.useOfInvoice;
    if (taxableInvoice ===  false && defaultUseOfInvoice) {
      useOfInvoice = defaultUseOfInvoice;
      privateVatIDNumber = null;
    }

    const mappedValues = {
      LocalName: localName,
      FirstName: firstName,
      MiddleName: middleName,
      CCFirstName: firstName,
      LastName: lastName,
      CCLastName: lastName,
      Email: email?.trim() || email,
      PhoneNumber: formattedPhoneNumber,
      PhoneCountry: country, // Country Code of the entered phone number
      AccountType: showAccountTypeSwitch
        ? regAccountType || REGISTRATION_TYPE_PRIVATE
        : defaultAccountType,
      CompanyName: storeBusinessFieldValues
        ? companyName || companyDetails?.companyName || null
        : null,
      CompanyNumber: storeBusinessFieldValues
        ? companyNumber || companyDetails?.companyNumber || null
        : null,
      VatId: storeBusinessFieldValues ? vatId : null,
      CompanyId:
        storeBusinessFieldValues && !nonResidentCompany ? companyDetails?.companyId || null : null,
      CompanyAddress1: storeBusinessFieldValues ? companyDetails?.companyAddress1 || null : null,
      CompanyAddress2: storeBusinessFieldValues ? companyDetails?.companyAddress2 || null : null,
      CompanyCity: storeBusinessFieldValues ? companyDetails?.companyCity || null : null,
      CompanyPostalCode: storeBusinessFieldValues
        ? companyDetails?.companyPostalCode || null
        : null,
      CompanyCountryCode: storeBusinessFieldValues ? companyCountryCode || null : null,
      PrivateVatId: privateVatIDNumber,
      IdentificationType:
        storePrivateFieldValues || storeIdentificationTypeValue ? identificationType : '',
      IdentificationNumber: identificationNumber || nationalId,
      CompanyState: companyDetails?.companyState || null,
      CompanyCounty: companyDetails?.companyCounty || null,
      OfficeType: companyDetails?.officeType || null,
      BranchName: companyDetails?.branchName || null,
      BranchId: companyDetails?.branchId || null,
      CompanyDistrict: companyDetails?.companyDistrict || null,
      CompanyProvince: companyDetails?.companyProvince || null,
      TaxOfficeName: (storePrivateFieldValues ? taxOfficeName : companyDetails?.taxOfficeName) || null,
      NonResidentPerson: nonResidentPerson,
      NonResidentCompany: nonResidentCompany,
      PrivateRegistrationCountry: privateRegistrationCountry,
      BusinessRegistrationCountry: businessRegistrationCountry,
      RequestedTaxableInvoice: taxableInvoice || null,
      UseOfTaxableInvoice: useOfInvoice || null,
    };

    if (
      market === 'CN' &&
      _isEmpty(identificationType) &&
      _isEmpty(identificationNumber) &&
      model !== Models.ROADSTER
    ) {
      updateAccountIDTypeLabel({
        value: CITIZEN_ID_NO,
      });
    }
    if (error && accountFormValid) {
      if (accountFormValidRef.current) {
        setValidFlag(false);
        accountFormValidRef.current = false;
      }
    } else {
      if (!error && !accountFormValidRef.current) {
        if (isVerifyPhoneNumberEnabled) {
          if (verifyPhoneResult) {
            setValidFlag(true);
            accountFormValidRef.current = true;
          }
        } else {
          setValidFlag(true);
          accountFormValidRef.current = true;
        }
      }
    }
    if (JSON.stringify(accountDataRef.current) === JSON.stringify(mappedValues)) {
      return;
    }
    if (isVerifyPhoneNumberEnabled && verifyPhoneResult) {
      const { phone, code } = verifyPhoneResult;
      mappedValues.PhoneNumber = phone;
      mappedValues.VerifyPhoneCode = code;
    }
    updateAccountDetails(mappedValues);
    accountDataRef.current = mappedValues;
  };

  const debouncedUpdateAccountForm = _debounce(updateAccountForm, 500);

  const verifyPhoneMetaData = {
    uid: verifyPhoneNumberUid,
    flow: verifyPhoneNumberFlow,
    hostUrl: verifyPhoneNumberHost,
  };
  const onVerifyPhoneSuccess = res => {
    const { code, phone } = res || {};
    setVerifyPhoneResult(res);
    setVerifiedPhoneNumber({ phoneNumber: phone, verifyPhoneCode: code });
    const { valid } = formApiRef?.current?.getFormState() || {};
    if (valid) {
      setValidFlag(true);
      accountFormValidRef.current = true;
    }
  };
  const onVerifyPhoneReset = () => {
    setValidFlag(false);
    accountFormValidRef.current = false;
    setVerifyPhoneResult(null);
    setVerifiedPhoneNumber({ phoneNumber: '', verifyPhoneCode: '' });
  };

  const identificationTypeChanged = ({ value }) => {
    updateAccountIDTypeLabel({
      value,
    });
  };

  const isAbuDhabiEntitySplitEnabled = window.tesla?.isAbuDhabiEntitySplitEnabled ?? false;
  /* A component with a fat-arrow function causes the field to lose focus while typing */
  function renderPrivateVatIdField() {
    return (
        <Input
            name="privateVatId"
            id={FIELD_PRIVATE_VAT_ID}
            data-id={UI_DATA_IDS?.accountSection?.vatIDInput}
            label={i18n('Review.BillingInfo.Form.private_vat_id')}
            placeholder={!hidePlaceHolderInput ? getVatNumberFormat(market, REGISTRATION_TYPE_PRIVATE) : null}
            validate={vatIdValidator}
            validateOn="change"
            required={registration?.privateVatIdRequired}
            keep={{ value: true, touched: true }}
            className="tds-o-form-input-wrap"
        />
   )
  };

  return (
    <Form
      formApiRef={formApiRef}
      initialValues={initialValues}
      errorMessage={{
        required: _upperFirst(i18n('common.errors__required')),
      }}
      validateOnMount
      onChange={debouncedUpdateAccountForm}
      validateOn="change-blur"
    >
      <FormFieldset>
        {/* Registration Type */}
        <AccountFormRegistrationType />

        {/* Local Name */}
        <Relevant when={() => showLocalName}>
          <Input
            name="localName"
            label={i18n('Review.account_localname_label')}
            keep={{ value: true, touched: true }}
            id={LOCAL_NAME}
            data-id={UI_DATA_IDS?.accountSection?.localNameInput}
          />
        </Relevant>

        {/* Company Name */}
        <Relevant
          when={({ formState }) =>
            formState.values.registrationType !== REGISTRATION_TYPE_BUSINESS ||
            !isBusinessVerificationEnabled
          }
        >
          <Relevant
            when={({ formState }) =>
              (formState.values.registrationType === REGISTRATION_TYPE_BUSINESS ||
                defaultAccountType === REGISTRATION_TYPE_BUSINESS) &&
              _includes(extraBusinessFields, FIELD_COMPANY_NAME)
            }
          >
            <Input
              name="companyName"
              id={FIELD_COMPANY_NAME}
              data-id={UI_DATA_IDS?.accountSection?.companyNameInput}
              label={i18n('Review.account_company_name_label')}
              required
              keep={{ value: true, touched: true }}
              validateOn="change"
              validate={companyNameValidator}
              validateOnMount
              className="tds-o-form-input-wrap"
            />
          </Relevant>

          {/* Company Number */}
          <Relevant
            when={({ formState }) =>
              (formState.values.registrationType === REGISTRATION_TYPE_BUSINESS &&
                _includes(extraBusinessFields, CHAMBER_OF_COMMERCE)) ||
              (formState.values.registrationType === REGISTRATION_TYPE_PRIVATE &&
                _includes(extraPersonalFields, CHAMBER_OF_COMMERCE)) && (market !== 'QA')
            }
          >
            <Input
              name="companyNumber"
              id={CHAMBER_OF_COMMERCE}
              data-id={UI_DATA_IDS?.accountSection?.companyNumberInput}
              label={i18n('Review.account_chamber_of_commerce_label', null, null, { specificOverride: registrationType})}
              validate={chamberOfCommerceValidator}
              validateOn="change"
              required
              keep={{ value: true, touched: true }}
              placeholder={companyNumberPlaceholder}
              formatter={companyNumberFormatter}
              mask={companyNumberMask}
              validateOnMount
              className="tds-o-form-input-wrap"
            />
          </Relevant>

          {/* Chinese Title */}
          <Relevant
            when={({ formState }) =>
              showAccountTypeSwitch &&
              formState.values.registrationType === REGISTRATION_TYPE_PRIVATE &&
              _includes(extraPersonalFields, CHINESE_TITLE) &&
              !!chineseTitleText
            }
          >
            <Input
              name="chineseTitle"
              id={CHINESE_TITLE}
              label={i18n('Review.account_chinese_title_label')}
              disabled
              keepState
            />
          </Relevant>

          {/* Vat Id */}
          <Relevant
            when={({ formState }) =>
              formState.values.registrationType === REGISTRATION_TYPE_BUSINESS &&
              _includes(extraBusinessFields, FIELD_VAT_ID)
            }
          >
            <Input
              name="vatId"
              id={FIELD_VAT_ID}
              data-id={UI_DATA_IDS?.accountSection?.vatIDInput}
              label={i18n('Review.BillingInfo.Form.vat_id')}
              placeholder={businessVatFormat}
              validate={vatIdValidator}
              validateOn="change"
              required
              keep={{ value: true, touched: true }}
              className="tds-o-form-input-wrap"
            />
          </Relevant>

          {/* Private Vat Id (Above First Name) */}
          <Relevant
            when={({ formState }) =>
              formState.values.registrationType === REGISTRATION_TYPE_PRIVATE &&
              registration.showPrivateFields &&
              !registration.showPrivateVatIdBelowLastName
            }
          >
            {renderPrivateVatIdField()}
          </Relevant>
          <Relevant
            when={({ formState }) =>
              showCompanyAgentLabel &&
              formState.values.registrationType === REGISTRATION_TYPE_BUSINESS
            }
          >
            <div className="tds-o-label-agent_company_buyer">
              <label className="tds-form-label">
                <span className="tds-form-label-text">{i18n('Review.agent_company_buyer')}</span>
              </label>
            </div>
          </Relevant>
        </Relevant>
        <div
          className={classnames('tds-form-fieldset', {
            'tds-flex form-account-group': market === 'CN',
          })}
          style={{ width: '100%' }}
        >
          <Choose>
            <When condition={market === 'CN'}>
              {/* Last Name */}
              <Input
                name="lastName"
                id={LAST_NAME}
                data-id={UI_DATA_IDS?.accountSection?.lastNameInput}
                label={i18n('Review.account_lastname_label')}
                maxLength={50}
                validate={nameValidator}
                required
                className="tds-o-form-input-wrap"
              />
              {/* First Name */}
              <Input
                name="firstName"
                id={FIRST_NAME}
                data-id={UI_DATA_IDS?.accountSection?.firstNameInput}
                label={i18n('Review.account_firstname_label')}
                maxLength={50}
                validate={nameValidator}
                required
                className="tds-o-form-input-wrap"
              />
            </When>
            <Otherwise>
              {/* First Name */}
              <Input
                name="firstName"
                id={FIRST_NAME}
                data-id={UI_DATA_IDS?.accountSection?.firstNameInput}
                label={i18n('Review.account_firstname_label')}
                maxLength={50}
                validate={nameValidator}
                required
                className="tds-o-form-input-wrap"
              />
              <If condition={market === 'PH'}>
                {/* Middle Name */}
                <Input
                    name="middleName"
                    id={MIDDLE_NAME}
                    data-id={UI_DATA_IDS?.accountSection?.middleNameInput}
                    label={i18n('Review.account_middlename_label')}
                    maxLength={50}
                    validate={value => { if (value) { return nameValidator(value) }}}
                    className="tds-o-form-input-wrap"
                />
              </If>
              {/* Last Name */}
              <Input
                name="lastName"
                id={LAST_NAME}
                data-id={UI_DATA_IDS?.accountSection?.lastNameInput}
                label={i18n('Review.account_lastname_label')}
                maxLength={50}
                validate={nameValidator}
                required
                className="tds-o-form-input-wrap"
              />
            </Otherwise>
          </Choose>
        </div>

        {/* National ID number QA */}
        <Relevant
          when={({ formState }) =>
            (formState.values.registrationType === REGISTRATION_TYPE_PRIVATE &&
              _includes(extraPersonalFields, CHAMBER_OF_COMMERCE)) && (market === 'QA')
          }
        >
          <Input
            name="companyNumber"
            id={CHAMBER_OF_COMMERCE}
            data-id={UI_DATA_IDS?.accountSection?.companyNumberInput}
            label={i18n('Review.account_chamber_of_commerce_label', null, null, { specificOverride: registrationType})}
            validate={chamberOfCommerceValidator}
            validateOn="change"
            required
            keep={{ value: true, touched: true }}
            placeholder={companyNumberPlaceholder}
            formatter={companyNumberFormatter}
            mask={companyNumberMask}
            validateOnMount
            className="tds-o-form-input-wrap"
          />
        </Relevant>

        {/* Private Vat Id (Below Last Name) */}
        <Relevant
          when={({ formState }) =>
            formState.values.registrationType === REGISTRATION_TYPE_PRIVATE &&
            registration.showPrivateFields &&
            registration.showPrivateVatIdBelowLastName
          }
        >
          {renderPrivateVatIdField()}
        </Relevant>

        <Relevant
            when={({ formState }) => formState.values.registrationType === REGISTRATION_TYPE_PRIVATE}
        >
          <If condition={allowCrossBorderRegistration}>
            <CrossBorderRegistration />
          </If>
          <If condition={showPrivateFields?.length}>
            {showPrivateFields?.map(field => {
              const fieldName = typeof field === 'object' ? field?.fieldname : field;
              switch (fieldName) {
                case 'nationalRegistrationNumber':
                  return (
                      <Input
                          name={fieldName}
                          id={fieldName}
                          label={i18n(`Review.${fieldName}`)}
                          validate={value => regexValidator(value, field)}
                          validateOn="change"
                          required
                          keep={{ value: true, touched: true }}
                      />
                  );
                case 'taxableInvoice':
                  return (
                      <Checkbox
                          label={i18n('Review.taxable_invoice')}
                          name={fieldName}
                          id={fieldName}
                          defaultValue={false}
                      />
                  );
                case 'privateVatId':
                  return (
                      <Relevant
                          when={({ formState }) => formState.values.taxableInvoice}
                      >
                        {renderPrivateVatIdField()}
                      </Relevant>
                  );
                case 'taxOfficeName':
                  if (taxOfficesList.length) {
                    return (
                        <Relevant
                            when={({ formState }) => formState.values.taxableInvoice}
                        >
                          <Select
                              name={fieldName}
                              id={fieldName}
                              label={i18n('Review.tax_office_label')}
                              options={
                                [
                                  { label: i18n(`common.select`), value: '', disabled: true },
                                  ...taxOfficesList.map((id) => {
                                    const label = i18n(`Review.taxOffices.${id}`, null, null, {
                                      returnNullWhenEmpty: true
                                    });
                                    return { label: label ? label : id, value: id }}
                                  ),
                                ]
                              }
                              required
                          />
                        </Relevant>
                    )
                  }
                  return null;
                case 'useOfInvoice':
                  if (usesOfInvoiceList.length) {
                    return (
                        <Relevant
                            when={({ formState }) => formState.values.taxableInvoice}
                        >
                          <Select
                              name={fieldName}
                              id={fieldName}
                              label={i18n('Review.use_of_invoice')}
                              options={
                                [
                                  { label: i18n(`common.select`), value: '', disabled: true },
                                  ...usesOfInvoiceList.map((id) => {
                                    const label = i18n(`Review.usesOfInvoice.${id}`, null, null, {
                                      returnNullWhenEmpty: true
                                    });
                                    return { label: label ? label : id, value: id }}
                                  ),
                                ]
                              }
                              required
                          />
                        </Relevant>
                    )
                  }
                  return null;
              }
            })}
          </If>
        </Relevant>

        {/* Email */}
        <div className="email-validation-disclaimer">
        <Input
          name="email"
          id={EMAIL}
          data-id={UI_DATA_IDS?.accountSection?.emailAddressInput}
          label={i18n('Review.account_email_label')}
          maxLength={50}
          validate={emailValidator}
          validateOn="change"
          required
          className="tds-o-form-input-wrap"
          initialValue={initialEmailValue}
          />
        <If condition={market === 'CN'}>
          <p className="tds-text--caption account-details-disclaimer tds-o-margin-top--3x">
            {htmlToReact(i18n('Review.account_email_reminder_text'))}
          </p>
        </If>
        <If condition={verificationStatusExists}>
              <p className="tds-text--caption account-details-disclaimer">
                {htmlToReact(i18n('Review.prepopulate_email_confirm_text', {PROVIDER_NAME: verifierName}))}
              </p>
        </If>
        </div>
        {/* Email validation */}
        <If condition={emailConfirmEnabled}>
          <div className="email-validation-disclaimer">
            <Input
              name="emailConfirm"
              id={EMAIL_CONFIRM}
              data-id={UI_DATA_IDS?.accountSection?.confirmEmailAddressInput}
              label={i18n('Review.account_email_confirm_label')}
              maxLength={50}
              validate={emailConfirmValidator}
              validateOn="change"
              showErrorIfDirty
              onPaste={e => e.preventDefault()}
              required
              className="tds-o-form-phone-input-wrap"
              initialValue={initialEmailValue}
              />
            <If condition={market !== 'CN'}>
              <p className="tds-text--caption account-details-disclaimer">
                {htmlToReact(i18n('Review.account_email_confirm_text', {
                  COMMUNICATION_URL: `/${locale}/communication-preferences`,
                }, null))}
              </p>
            </If>
          </div>
        </If>
        {/* Phone Number */}
        <Choose>
          <When condition={isIntlPhoneInputEnabled}>
            <PhoneInput
              label={i18n('Review.account_phonenumber_label')}
              name="phoneNumber"
              id={PHONE_NUMBER}
              initialValue={{ country: phoneCountryCode || market, number: phone }}
              required
              data-id={UI_DATA_IDS?.accountSection?.phoneNumberInput}
              className="tds-o-form-phone-input-wrap"
              placeholder={hidePlaceHolderInput ? '' : null}
              autocomplete="phone"
              errorMessage={i18n('common.errors__phonenumberNotValid')}
              showErrorIfDirty
              validate={({ number }) => {
                if (!number) {
                  return i18n('common.errors__phonenumberNotValid');
                }
              }}
            />
          </When>
          <When condition={!isVerifyPhoneNumberEnabled}>
            <Input
              name="phoneNumber"
              id={PHONE_NUMBER}
              label={i18n('Review.account_phonenumber_label')}
              validate={phoneNumberValidator}
              validateOn="change"
              showErrorIfDirty
              required
              data-id={UI_DATA_IDS?.accountSection?.phoneNumberInput}
              className="tds-o-form-input-wrap"
            />
          </When>
          <Otherwise>
          <Suspense
            key="Suspense.PhoneNumberVerification"
            fallback={
              <div
                className="tds-spinner tds-spinner--fade_in"
                style={{ position: 'absolute', height: '450px' }}
              ></div>
            }
          >
            <PhoneNumberVerification
              metaData={verifyPhoneMetaData}
              onSuccess={onVerifyPhoneSuccess}
              onReset={onVerifyPhoneReset}
            />
          </Suspense>
          </Otherwise>
        </Choose>
        <If condition={isAbuDhabiEntitySplitEnabled && !isInventory}>
          <Select
            name="deliveryStateProvince"
            label={i18n('DeliveryEmirate.label')}
            options={[
              { label: i18n('DeliveryEmirate.select'), value: '', disabled: true },
              ...Object.keys(EMIRATE_VALUES).map(v => {
                const label = i18n(`DeliveryEmirate.states.${v}`);
                return { label, value: EMIRATE_VALUES[v] };
              }),
            ]}
            onChange={({ value }) => {
              updateDeliveryDetails({ [FIELD_DELIVERY_STATE_PROVINCE]: value });
            }}
            required
          />
        </If>

        {/* Identification Type and Number */}
        <Relevant when={() => showAccountTypeSwitch}>
          <Relevant
            when={({ formState }) =>
              (isCompanyAgentIdEnabled &&
                formState.values.registrationType === REGISTRATION_TYPE_BUSINESS &&
                _includes(extraBusinessFields, IDENTIFICATION_TYPE)) ||
              (formState.values.registrationType === REGISTRATION_TYPE_PRIVATE &&
                _includes(extraPersonalFields, IDENTIFICATION_TYPE))
            }
          >
            <Select
              name="identificationType"
              label={i18n('Review.account_identification_type_label')}
              options={idTypes}
              onChange={identificationTypeChanged}
              key={`personalFields__${IDENTIFICATION_TYPE}`}
              initialValue={idTypeOptions ? idTypeOptions[0] : ''}
              keep={{ value: true, touched: true }}
            />
          </Relevant>

          <Relevant
            when={({ formState }) =>
              (isCompanyAgentIdEnabled &&
                formState.values.registrationType === REGISTRATION_TYPE_BUSINESS &&
                _includes(extraBusinessFields, IDENTIFICATION_NUMBER)) ||
              (formState.values.registrationType === REGISTRATION_TYPE_PRIVATE &&
                _includes(extraPersonalFields, IDENTIFICATION_NUMBER))
            }
          >
            <Input
              name="identificationNumber"
              id={IDENTIFICATION_NUMBER}
              label={identificationLabelTitle + i18n('Review.id_type_suffix')}
              minLength={1}
              maxLength={30}
              validate={identificationNumberValidator}
              errorMessage={{
                minLength: error__minLen(1),
              }}
              required
              keep={{ value: true, touched: true }}
              className="tds-o-form-input-wrap"
            />
          </Relevant>
        </Relevant>
        {/* Account Disclaimer */}
        <Relevant when={() => showAccountDetailDisclaimer}>
          <p className="tds-text--caption account-details-disclaimer tds-o--maxinline--none">
            {htmlToReact(i18n('Review.account_detail_disclaimer'))}
          </p>
        </Relevant>
        <Relevant
          when={({ formState }) =>
            formState.values.registrationType === REGISTRATION_TYPE_BUSINESS &&
            isBusinessVerificationEnabled
          }
        >
          <CompanySearch
            chamberOfCommerceValidator={chamberOfCommerceValidator}
            companyNumberPlaceholder={companyNumberPlaceholder}
            companyNumberFormatter={companyNumberFormatter}
            companyNumberMask={companyNumberMask}
            businessVatFormat={businessVatFormat}
            vatIdValidator={vatIdValidator}
            disableCompanySearch={disableCompanySearch}
            allowCrossBorderRegistration={allowCrossBorderRegistration}
            taxOfficesList={taxOfficesList}
            usesOfInvoiceList={usesOfInvoiceList}
          />
        </Relevant>
      </FormFieldset>
    </Form>
  );
};

AccountFormRepresentation.propTypes = {
  title: string,
  extraPersonalFields: instanceOf(Array),
  updateAccountDetails: func.isRequired,
  updateDeliveryDetails: func.isRequired,
  updateAccountIDTypeLabel: func.isRequired,
  market: string.isRequired,
  setValidFlag: func.isRequired,
  registrationType: string,
  registration: shape({
    showPrivateFields: bool,
    privateVatIdRequired: bool,
    vatIdRequired: bool,
  }),
  showLocalName: bool.isRequired,
  showAccountTypeSwitch: bool,
  showCompanyAgentLabel: bool,
  extraBusinessFields: arrayOf(string),
  identificationLabel: string,
  showAccountDetailDisclaimer: bool.isRequired,
  userFilledInData: shape().isRequired,
  accountFormValid: bool.isRequired,
  locale: string,
  isCompanyAgentIdEnabled: bool.isRequired,
  isIntlPhoneInputEnabled: bool,
  setDrivertype: func.isRequired,
  financeProduct: string.isRequired,
  selectTab: func.isRequired,
  isInline: bool,
  emailConfirmEnabled: bool.isRequired,
  isBusinessVerificationEnabled: bool,
  allowCrossBorderRegistration: bool,
  phone: string,
  phoneCountryCode: string,
  defaultAccountType: string,
  isInventory: bool.isRequired,
  disableCompanySearch: bool,
  hidePlaceHolderInput: bool,
  serverError: string,
  setVerifiedPhoneNumber: func,
  isVerifyPhoneNumberEnabled: bool,
  verifyPhoneNumberHost: string,
  verifyPhoneNumberUid: string,
  verifyPhoneNumberFlow: string,
  model: string,
};

AccountFormRepresentation.defaultProps = {
  title: '',
  extraPersonalFields: [],
  registrationType: '',
  registration: {},
  showAccountTypeSwitch: false,
  extraBusinessFields: [],
  identificationLabel: '',
  locale: '',
  isIntlPhoneInputEnabled: false,
  isInline: false,
  isBusinessVerificationEnabled: false,
  allowCrossBorderRegistration: false,
  showAccountDetailDisclaimer: false,
  phone: '',
  phoneCountryCode: '',
  defaultAccountType: '',
  disableCompanySearch: false,
  hidePlaceHolderInput: false,
  serverError: null,
  model: '',
};

export default AccountFormRepresentation;
