import _get from 'lodash/get'
import _set from 'lodash/set'
import _has from 'lodash/has'
import _map from 'lodash/map'
import _find from 'lodash/find'
import _findKey from 'lodash/findKey'
import _reduce from 'lodash/reduce'

import { Loan, Lease, Calculator } from '@web/tesla-rest-ds-services'
import { formatCurrency, setFormatCurrencyOptions, formatMonthlyPrice } from '@tesla/coin-common-components';
import { i18n } from 'utils';
import { isUsedInventory } from 'selectors';

export function getAvailableViewsPerSize(state, size) {
    /*
     * getAvailableViewsPerSize() Is going to generate the available views on the size that you required in an array of URL and views.
     * @param {[string]} state , current state
     * @param {[string]} size , the size for the image that we need.
     */
    const compositorUrl = state.Compositor.compositor_url
    const optionString = state.Compositor.option_string
    const model = state.Compositor.model

    return state.Compositor.available_views.map((view) => {
        return {
            url: `${compositorUrl}?options=${optionString}&view=${view}&model=${model}&size=${size}`,
            view
        }
    })
}


export function getOptionsByCategory(state, category) {
    let response = {}
    _map(_get(state, 'OMS.pricebook.categories.' + category + '.options'), (option) => {
        response[option] = _get(state, 'OMS.pricebook.options.' + option)
    })
    return response
}

/**
 * Get vat rate for forward calculation if applicable
 * Currently used for Iceland only. But eventually European markets will need this if we need to show post-vat amounts when lexicon has pre-vat amounts
 * [IMPORTANT] All other markets should return 1 since it's used for multiplication with prices
 */
export const getVatRateForForwardCalculation = state => {
    const showPricesWithForwardCalculatedVat =
        state?.SummaryPanel?.showPricesWithForwardCalculatedVat;
    const isNetPriceInLexicon = state?.OMS?.lexicon?.metadata?.vat === false;
    const vatPercent = state?.OMS?.lexicon?.fees?.vat_percent?.percent ?? 0;
    if (showPricesWithForwardCalculatedVat && isNetPriceInLexicon && vatPercent && !isUsedInventory(state)) {
        return 1 + (vatPercent / 100);
    }
    return 1;
};

export function FormatAmount(state, option, price, financeType, cashPrice) {
    let extraPriceData, extraPrice;
    const displayDoubleCurrency = _get(state, "App.displayDoubleCurrency");
    const { isCoinReloaded } = state?.App || {};
    const { termLength = 0 } = _get(state, 'Pricing.finplat.output.inputs') || {};

    if (displayDoubleCurrency) {
      const extraPriceContexts =
        _get(state, "Pricing.calculatorResult.data.extraPriceContexts") || {};
      if (Object.keys(extraPriceContexts).length > 0) {
        extraPriceData = Object.values(extraPriceContexts)[0] || {};
      }
      const unmatchedPrice = _get(
        _find(_get(extraPriceData, "apiResults.price.unselectedOptions", []), {
          code: "unSelectedOptions",
        }) || {},
        `price.${option}`
      );
      const extraPriceAmount = _get(
        _get(extraPriceData, "apiResults.price.matchedCodes", []).filter(
          (matchedCode) => matchedCode.code === option
        ),
        "[0].price",
        unmatchedPrice
      );

      if (extraPriceAmount) {
        extraPrice = {
          amount: extraPriceAmount,
          currency: Object.keys(extraPriceContexts)[0],
        };
      }
    }
    const vatRateForForwardCalculation = getVatRateForForwardCalculation(state) || 1;
    switch (financeType) {
        case 'finplat':
            const optionPrice = termLength && price ? Math.round(price/termLength) : price;
            return {
                price,
                extraPrice,
                formattedCashPrice: formatCurrency(cashPrice * vatRateForForwardCalculation),
                formattedPrice: formatCurrency(cashPrice * vatRateForForwardCalculation),
            }
        case 'lease':
            return {
                price,
                extraPrice,
                formattedCashPrice: formatCurrency(cashPrice),
                formattedPrice: formatCurrency(cashPrice)
                // turning off monthly price for options until calculation is figured out
                //formattedPrice: formatMonthlyPrice(price)
            }
        case 'loan':
            return {
                price,
                extraPrice,
                formattedCashPrice: formatCurrency(cashPrice),
                formattedPrice: formatCurrency(cashPrice)
                // turning off monthly price for options until calculation is figured out
                //formattedPrice: formatMonthlyPrice(price)
            }
        case 'cash':
        default:
            return {
                price,
                extraPrice,
                formattedCashPrice: formatCurrency(cashPrice * vatRateForForwardCalculation),
                formattedPrice: formatCurrency(cashPrice * vatRateForForwardCalculation)
            }
    }
}

export function AmountCalculator(state, extraParameters) {
    const pricebook = _get(extraParameters, 'applyDeltas') ? state.OMS.oms_pricebook : state.OMS.pricebook

    const parameters = Object.assign({}, state.OMS.oms_params, _get(state, 'Pricing.calculatorParameters', {}), extraParameters)
    const financeType = _get(state, 'Pricing.financeType') || parameters.financeType;


    let apiResponses = _get(state, 'Pricing.calculatorResult.data') || {};
    const { err } = apiResponses;

    if (err) {
        return apiResponses;
    }

    const optionFeesOnce     = _get(pricebook, 'meta_data.option_fees.once', [])

    return (option, optionPrice=0) => {
        const noNegativePrice = price=>price < 0 ? 0 : price
        if(!option && !optionPrice) {
            switch (financeType) {
                case 'lease':
                    return FormatAmount(state, option,
                        noNegativePrice(extraParameters.afterSavings ? _get(apiResponses.result, 'lease.netMonthlyPayment') : _get(apiResponses.result, 'lease.monthlyPayment')),
                        financeType,
                        noNegativePrice(extraParameters.afterSavings ?  _get(apiResponses.result, 'cash.netPrice') : _get(apiResponses, 'apiResults.price.vehiclePrice')))
                case 'loan':
                    return FormatAmount(state, option,
                        noNegativePrice(extraParameters.afterSavings ? _get(apiResponses.result, 'loan.netMonthlyPayment') : _get(apiResponses.result, 'loan.monthlyPayment')),
                        financeType,
                        noNegativePrice(extraParameters.afterSavings ?  _get(apiResponses.result, 'cash.netPrice') : _get(apiResponses, 'apiResults.price.vehiclePrice')))
                default:
                case 'cash':
                    return FormatAmount(state, option,
                        noNegativePrice(extraParameters.afterSavings ?  _get(apiResponses.result, 'cash.netPrice') : _get(apiResponses, 'apiResults.price.vehiclePrice')),
                        financeType,
                        noNegativePrice(extraParameters.afterSavings ?  _get(apiResponses.result, 'cash.netPrice') : _get(apiResponses, 'apiResults.price.vehiclePrice')))
            }
        }
        let fallbackPrice = 0
        if (pricebook) {
            fallbackPrice = _get(pricebook, `options.${option}.pricing.price`)
        } else {
            const unmatchedPrice = _get((_find(_get(apiResponses, 'apiResults.price.unselectedOptions', []), { 'code': 'unSelectedOptions'}) || {}), `price.${option}`)
            fallbackPrice = unmatchedPrice || _get(state.OMS.lexicon, `options.${option}.price`)
        }
        const price = optionPrice || _get(_get(apiResponses, 'apiResults.price.matchedCodes', []).filter(matchedCode=>matchedCode.code === option), '[0].price', fallbackPrice)

        if (optionFeesOnce.includes(option)) {
            return {
                price,
                formattedPrice: formatCurrency(price)
            }
        }
        return FormatAmount(state, option, price, financeType, price);
    }
}

export function CurrentPricing(state) {
    return ['lease', 'loan'].reduce((result, financeType)=>{

        const parameters = Object.assign({}, state.OMS.oms_params, {
            fuelDist: _get(state, 'SummaryPanel.fuelDistPer'),
            fuelPrice: _get(state, 'SummaryPanel.fuelPrice'),

            financeType,
            leaseDistance: _get(state, 'SummaryPanel.leaseDistance'),
            leaseTerm: _get(state, 'SummaryPanel.leaseTerm'),
            loanTerm: _get(state, 'SummaryPanel.loanTerm'),
            downPayment: _get(state, 'SummaryPanel.downPaymentPercent'),

            commute: _get(state, 'SummaryPanel.commute'),

            discounts: [{code: _get(state, 'Pricing.discount.referral.code', null), value: _get(state, 'Pricing.discount.referral.value', 0), title: _get(state, 'Pricing.discount.referral.title', null)}],
            regionCode: _get(state, 'SummaryPanel.region_code'),
            balloonPayment: _get(state, 'SummaryPanel.balloonPayment')
        })

        return Object.assign(result, Calculator.total({
            Incentives: state.Financial.fms_incentives,
            Fees:state.Financial.fms_fees,
            Loan: state.Financial.fms_loan,
            PricebookOptions:state.OMS.pricebook,
            Lexicon:state.OMS.lexicon,
            Configuration: {options:state.Configuration.option_codes}
        }, parameters).result)
    },{})
}

export function getLocalizedUrl(urlPath, state) {

    let locale = state.Locale.design_studio.locale
    let baseURL = state.App.baseUrl

    return `${baseURL}/${locale}/${urlPath}`
}

// -- GB DOWNPAYMENT HELPERS --
export function getDownPaymentPercent(downPaymentAmount, grossPrice) {
    return Math.round((downPaymentAmount / grossPrice) * 100)
}

/**
 * TODO: Integrate into tesla-rest-financial-services and actions dispatch chain
 * Called from GB->Lease->DownPayment
 * @param {Object} appState - application state object
 * @return {[type]} [description]
 */
export function getDownPaymentInfo(appState) {

    const maxPercent = _get(appState, 'SummaryPanel.lease.downpayment.maxPercent')
    const grossPrice = _get(appState, 'Pricing.calculatorResult.data.apiResults.price.totalPrice')

    const downPaymentPercent = _get(appState, 'SummaryPanel.downPaymentPercent')
    const downPaymentAmount = Math.round(_get(appState, 'Pricing.lease.LeasingAPI.leaseAmount.downPayment'))
    const minDownPaymentAmount = _get(appState, 'SummaryPanel.lease.downpayment.minAmount', -1)
    const maxDownPaymentAmount = (grossPrice / 100) * maxPercent

    let outOfRangeError = (downPaymentAmount < minDownPaymentAmount) || (downPaymentAmount > maxDownPaymentAmount)

    return {
        grossPrice,
        downPaymentPercent,
        downPaymentAmount,
        minDownPaymentAmount,
        maxDownPaymentAmount,
        outOfRangeError
    }
}

export function mapFinanceType(type,market) {
    let financeTypesMap = {
        'LEASE': 'TESLA_LEASE',
        'LOAN': 'TESLA LENDING',
        'CASH': 'CASH'
    }

    //Downstream system is not setup for canada lending yet, so Tesla Lending order type needs to be mapped to cash
    if (market == 'CA') {
        financeTypesMap['FINANCE'] = 'CASH'
    }

    /**
     * TWS-8104
     * HK - No Payment method selected when "Finance" tab is selected
     **/
    if (market == 'HK') {
        financeTypesMap['FINANCE'] = 'FINANCE'
    }

    /**
     * TWS-7608
     * Remove Tesla Leasing Finance Application
     **/
    if (market == 'GB') {
        financeTypesMap['LEASE'] = 'TESLA LENDING'
    }

    /**
     * TWS-7608
     * Remove Tesla Leasing Finance Application
     **/
    if (market == 'NO') {
        financeTypesMap['FINANCE'] = 'TESLA_FINANCE'
    }


    const normalizedType = type.toUpperCase()
    if (_has(financeTypesMap, normalizedType)) {
        return financeTypesMap[normalizedType]
    }
    else {
        //check if value exists (schema to state)
        const typeKey = _findKey(financeTypesMap, normalizedType)
        if (typeof typeKey !== 'undefined') {
            return financeTypesMap[typeKey]
        }
    }
    return financeTypesMap.CASH
}

export function stateToSchema(state, cb, extra = {}) {
    const { oms_params } = state.OMS
    const { market, language } = oms_params
    const orderType     = _get(state, 'SummaryPanel.selected_tab', '').toUpperCase();
    const order_context = _get(state, 'App.application_context', 'Unified Configurator')
    const version       = _get(window, 'tesla.version', '0.0.0')
    const configurator_version = version

    let mainBlob = Object.assign({}, {
      id:                   _get(state, 'Configuration.id'),
      financetotal:         0,
      leasetotal:           0,
      order_type:           mapFinanceType(orderType,market).toUpperCase(),
      market:               market,
      name:                 _get(state, 'Configuration.rn'),
      options_price_string: _get(state, 'Pricing.price_string'),
      rn:                   _get(state, 'Configuration.rn'),
      saved_locale:         `${language.toLowerCase()}_${market.toUpperCase()}`,
      subtotal:             _get(state, 'Pricing.subtotal'),
      total:                null,
      total_excluding_vat:  0,
      vat_amount:           _get(state, 'Financial.fees.current.vat_percent[0].amount') ?  0  : 0,
      vat_percent:          _get(state, 'Financial.fees.current.vat_percent[0].percent') ? 0  : 0,
      doc_fee:              _get(state, 'Financial.fees.total.once')   || null,
      order_context,
      configurator_version,
      referralData:         _get(state, 'Pricing.discount.referral.data') || {},
      regionCode:           _get(state, 'Location.Components.SummaryPanel.regionCode')

    }, extra)

    if(state.Configuration.savedConfiguration){
        const finalOptions      = mainBlob.manufacturing_string.split(',')
        const initialOptions    = state.Configuration.savedConfiguration.manufacturing_string.split(',')
        mainBlob = Object.assign({},  state.Configuration.savedConfiguration, mainBlob,{
            options_added: finalOptions.filter(option=>!initialOptions.find(targetOption=>targetOption===option)).toString(),
            options_deleted: initialOptions.filter(option=>!finalOptions.find(targetOption=>targetOption===option)).toString()
        })
    }

    cb ? cb(null, mainBlob) : null

    return mainBlob
}

function mapDiscountsToType(discounts = []) {
    return discounts.reduce((result,discount)=>{
        return Object.assign(result, {[`${discount.Type}`.toLowerCase()]: {
            value: Math.abs(discount.Amount),
            code: null,
            data: {discount: Math.abs(discount.Amount), title: discount.Type.toLowerCase()},
            title: discount.Type.toLowerCase()
        }})
    }, {})
}

export function schemaToState(schema) {
    let state = {}

    _set(state, 'OMS.oms_params.market',                           schema.market)
    _set(state, 'OMS.oms_params.model',                            schema.model_code)
    schema.model_variant ? _set(state, 'OMS.oms_params.variant',   schema.model_variant) : null
    _set(state, 'OMS.configset_name',                              schema.configset_name)
    _set(state, 'OMS.saved_locale',                                schema.saved_locale)
    _set(state, 'Pricing.finance.LoanAPI.object.loanAmount.financedAmount', schema.financetotal)
    _set(state, 'Financial.fees.current.vat_percent[0].amount',    schema.vat_amount)
    _set(state, 'Financial.fees.current.vat_percent[0].percent',   schema.vat_percent)

    _set(state, 'Configuration.option_codes',                      schema.manufacturing_string.split(','))
    _set(state, 'Configuration.id',                                schema.id)
    _set(state, 'Configuration.rn',                                schema.name)
    _set(state, 'Configuration.rn',                                schema.rn)

    _set(state, 'Pricing.price_string',                            schema.options_price_string)
    _set(state, 'Pricing.receivedAt.localeString',                 schema.pricing_date)
    _set(state, 'Pricing.subtotal',                                schema.subtotal)
    _set(state, 'Pricing.total',                                   schema.total)
    _set(state, 'Pricing.total',                                   schema.total_excluding_vat)
    _set(state, 'Pricing.discount',                                mapDiscountsToType(schema.FeesDiscounts))

    const options = _reduce(schema.options, (result, option_blob, option_key)=>{
        let massagedOption = {[option_blob.value || option_key]:{}}
        //missing:financed_price
        //missing:default_pair
        _set(massagedOption, 'pricing.price',              option_blob.list_price)
        _set(massagedOption, 'pricing.name',               option_blob.name)
        _set(massagedOption, 'pricing.price',              option_blob.price)
        _set(massagedOption, 'pricing.price_indicator',    option_blob.price_indicator)
        _set(massagedOption, 'rules.col',                  option_blob.value)
        _set(massagedOption, 'rules.requires',             schema.requires)
        _set(massagedOption, 'rules.toggle_pair',          schema.toggle_pair)
        _set(massagedOption, 'rules.valid_pairs',          schema.valid_pairs)
        _set(massagedOption, 'rules.excludes',             schema.excludes)
        _set(massagedOption, 'rules.toggle_map',           schema.toggle_map)
        _set(massagedOption, 'rules.ui_excludes',          schema.ui_excludes)
        _set(massagedOption, 'rules.required_by',          schema.required_by)

        option_blob.user_selected ? _set(state, 'Configuration.user_selected_options',
            [].concat(_get(state, 'Configuration.user_selected_options') || [], [option_key])
        ) : null

        return Object.assign(result, massagedOption)

    }, {})
    _set(state, 'OMS.saved_config_delta.meta_data.model',                   schema.asset_code)
    _set(state, 'OMS.saved_config_delta.meta_data.base_price',              schema.base_price_default)
    _set(state, 'OMS.saved_config_delta.meta_data.currency',                schema.currency)
    _set(state, 'OMS.saved_config_delta.meta_data.model_code',              schema.model_code)
    _set(state, 'OMS.saved_config_delta.meta_data.model_name',              schema.model_name)
    _set(state, 'OMS.saved_config_delta.meta_data.name',                    schema.mytesla_pricebook)
    _set(state, 'OMS.saved_config_delta.meta_data.tax_credit',             -Math.abs(schema.tax_credit))
    _set(state, 'OMS.saved_config_delta.options', options)
    _set(state, 'OMS.saved_config_delta.option_codes',           schema.manufacturing_string.split(','))

    return state
}

export function userSelectedOption(state, option){
    return !!_find(state.Configuration.user_selected_options_by_group, (group, optionCode)=>optionCode === option)
}

/**
 * Return ony user-visible option codes
 * @param  {Object} state - Redux app state
 * @return {Array} - returns array of option codes
 */
export function getUserVisibleOptions(state) {

    const currentOptions = _get(state, 'Configuration.option_codes')
    const optionDetail   = _get(state, 'OMS.pricebook.options')
    const mappedOptions  = _reduce(currentOptions, (result, optCode)=>{
       let opt = optionDetail[optCode]
       if(_get(opt, 'pricing.no_ui') === false){
            result.push(optCode)
       }
       return result
    }, [])

    return mappedOptions
}

/**
 * Shortcut function to get json strings blob for a component
 * @param {Object} state - application state
 * @param {String} componentName - name of locale component
 * @return {Object} returns scoped locale object
 */
export function getStrings(state, componentName) {

    if (!state) {
        console.log('getComponentStrings: state parameter required')
    }

    let strings = _get(state.Locale, `design_studio.strings.${componentName}`)

    if (!strings) {
        console.log('getComponentStrings: No localization found for ', componentName)
    }

    return strings;
}


export function compositorOptionString(state, options) {
  return options.map(option=>{
    const col = _get(state.OMS.pricebook, `options.${option}.rules.col`);
    return `${col ? col + "-" : ""}${option}`
  }).toString()
}

/**
 * Method to parse object data that requires mapping to translations
 * @param {Object} object - data to map translations to
 * @param {Object} state - application state
 * @return {Object} returns object with mapped translations
 */
export function grabFromTranslations(object, state) {
    return object.translations_data ? _reduce(object.translations_data, (result, translations_target, key) => {
        return Object.assign(result, {
            [key]: _get(state, translations_target)
        })
    },{}) : {}
}

export function getPostOrderSwapCustomAvailability(state) {
    const stateProvince = _get(state, 'ReviewDetails.product.orderDetails.StateProvince', '');
    const postOrderSwapCustomAvailability = _get(state, 'ReviewDetails.postOrderSwapCustomAvailability', {});
    const IsAtLocation = _get(state, 'ReviewDetails.product.data.IsAtLocation', false);
    let customAvailability = IsAtLocation ? '' : postOrderSwapCustomAvailability['intransit'] 
    customAvailability = customAvailability || postOrderSwapCustomAvailability[stateProvince];
    return customAvailability ? i18n('PostOrderSwap.availableInDays', {AVAILABILITY: customAvailability}) : '';
}

/**
 * Method to get target value after operations
 * @param {String} operator - Operator (substract, add, etc.)
 * @param {Object} app_state - application state object
 * @param {Number} value - Price value
 * @param {Object} options - Array of options to check against
 * @param {Object} selected_options - User selected options
 * @param {String} state_target - Target location on an application state
 * @param {String} price_target - Target price on the option object
 * @return {Boolean} True | False - Value match results
 */
export function getTargetValue(operator, app_state, value, options, selected_options, state_target, price_target) {
    if (!options.length || !state_target || !price_target) {
        return value
    }
    return options.reduce((result, option) => {
        if (selected_options.includes(option)) {
            const option_price = parseInt(_get(app_state, `${state_target}.${option}.${price_target}`, 0))
            switch (operator) {
                case 'substract':
                    return (value - Math.abs(option_price))
                case 'add':
                    return (value + Math.abs(option_price))
            }
            return value
        }
        else {
            return value
        }
    }, value)
}

/**
 * Method to determine is user location state is within gallery state list
 * @param {Object} state - application state
 * @param {String} locationProvince - state/province of delivery location
 * @return {Boolean}  True | False - state is gallery state 
 */
export function isGalleryState(state, locationProvince) {
    const galleryStatesList = _get(state, 'ReviewDetails.galleryStates', []);
    const stateCode = _get(state, 'ReviewDetails.DeliveryDetails.stateCode', null);
    return galleryStatesList.includes(stateCode) || galleryStatesList.includes(locationProvince);
}

/**
 * Method to determine is user location state is factory gated or pre production vehicle
 * @param {Object} state - application state
 * @return {Boolean}  True | False - is pre production vehicle 
 */
export function isPreProduction(state) {
    return !(_get(state, 'ReviewDetails.product.data.IsFactoryGated') || _get(state, 'ReviewDetails.product.data.FactoryGatedDate'));
}

/**
 * Method to match target value against defined rules
 * @param {String} target_value - target price to match
 * @param {Object} rules - vehicle price comparison rules
 * @param {Object} app_state - application state object
 * @return {Boolean} True | False - Value match results
 */
export function matchesTargetValue(target_value, rules, app_state) {
    const selected_options = _get(app_state, 'Configuration.option_codes', [])
    target_value = _reduce(rules, (result, options, operator) => {
        const state_target  = _get(options, 'target', '')
        const price_target  = _get(options, 'price_target', '')
        const options_list  = _get(options, 'array') || _get(options, 'options') || [];
        result = getTargetValue(operator, app_state, result, options_list, selected_options, state_target, price_target)
        return result
    }, target_value)

    return  _reduce(rules.operators, (result, value, operator) => {
        switch (operator) {
            case '>':
                return (target_value > value)
            case '<':
                return (target_value < value)
            case '=':
                return (target_value == value)
            case '>=':
                return (target_value >= value)
            case '<=':
                return (target_value <= value)
            case '!=':
                return (target_value != value)
            default:
                return result
        }
    }, false)
}

// Set locale and currency for intl-number
const { App = {}, DSServices = {} } = window.tesla;
const locale = _get(App, 'locale');
const lookUp = _get(DSServices, 'KeyManager.keys.Lexicon[0].key', '');
const lexicon = DSServices[lookUp];
const currencyCode = _get(lexicon, 'metadata.currency_code', '');
setFormatCurrencyOptions(locale, currencyCode);
