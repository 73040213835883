/* eslint no-case-declarations: 0 */

import _get from 'lodash/get';
import _set from 'lodash/set';
import _has from 'lodash/has';
import _omit from 'lodash/omit';
import _merge from 'lodash/merge';
import _uniq from 'lodash/uniq';
import _toUpper from 'lodash/toUpper';
import _unset from 'lodash/unset';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import { map, filter } from 'rxjs/operators';
import _upperFirst from 'lodash/upperFirst';

import { localizeUrl } from '@tesla/parse-locale';

import Analytics from 'analytics';
import {
  getUiOptionsNotInConfiguration,
  stateToSchema,
  smoothScrollToError,
  memoize,
  i18n,
  getAllPricingContexts,
  clearAccessorySelected,
  getDeliveryLocationDetails,
  getStaticAddress,
  getVatRateForForwardCalculation,
  constructUrl,
  getCookieInfoOfLatestLexiconLoading,
  getTrafficSource,
  getTrafficSourceHistory,
  getActivitySessionId,
} from 'utils';

import {
  getDeviceType,
  getModelCode,
  getActiveFinanceTypes,
  getLeaseTerm,
  getLoanTerm,
  getDownPayment,
  getLoanDistance,
  getBalloonPayment,
  getLeaseMileage,
  getInterestRate,
  getFuelDistance,
  doWeConsiderTermForGas,
  getFinanceType,
  getPostOrderUrl,
  getPaymentSignUrl,
  getProcessSwapUrl,
  getCardData,
  isClientSideEncryptionEnabledMarket,
  getEncryptorParams,
  isInventory,
  getInventoryBasePrice,
  getInventoryOptionPricing,
  shouldExcludeDefaultDownpaymentVAT,
  getDepositAmount,
  getFinanceProductId,
  getFinanceProductData,
  getFinanceProductDistancesOffered,
  stateToOrderSchema,
  getDestinationAndDocFee,
  getFuelPrice,
  getMaintenanceAmount,
  getProductTypeMapping,
  getAccessoriesPayload,
  getElectricityPrice,
  getFuelEfficiency,
  getAccessoriesTotal,
  getMultiFlexDetails,
  getAcknowledgementDocuments,
  getEstimateDeliveryDate,
  isEnterprisePurchaseOrder,
  getAccessoriesPayloadPO,
  getSavedConfiguration,
  getAddOnToGrossPrice,
  getModel,
  getMarket,
  getRegistrationAge,
  getProvincesList,
  getDistrictsList,
  getTrimPrice,
  getFlexOptions,
  getSwapOptionsList,
  getSwapInitialOptionsList,
  getReferralCredit,
  isReservationToOrder,
  getTaxesAndFees,
  isUsedInventory,
  getAdvocate,
  getTmpDeliveryDetailsNormalized,
  getDefaultDownPaymentOverride,
  getTransportationFeeAmount,
  getFuelMonthsByFinanceType,
  getTrimCode,
  getResidualInfo,
  getSubscriptions,
  checkIfNV35Enabled,
  getSavedTrimCode,
  getPostOrderSwapUpgradesPayload,
  isEsignFlowEnabled,
  getStandardFinancePlanInfo,
  getCurrentFinancePlanInfo,
  isInventoryOrderForPreOrderSwap,
  getSwapType,
} from 'selectors';

import { Storage } from '@tesla/coin-common-components';

import {
  LOCATION_CHANGED,
  LOCAL_STORAGE_KEY,
  PRICE_CHANGED,
  CHANGE_HERO_VIEW_TO,
  CHANGE_HERO_TYPE_TO,
  LOADER_START,
  LOADER_FINISH,
  POST_ORDER_START,
  POST_ORDER_SUCCESS,
  POST_SAVE_CONFIG_SUCCESS,
  POST_ORDER_FAIL,
  LOCATION_SOURCE_CHANGE,
  PAYMENT_FAILED,
  SESSION_TIME_OUT,
  CONFIG_SAVE_TIME_OUT,
  ADDRESS_REGISTRATION,
  STRIPE_PAYMENTS,
  PAYMENT_TYPE_POS,
  PAYMENT_TYPE_CC,
  PAYMENT_TYPE_WT,
  PAYMENT_TYPE_WECHAT,
  PAYMENT_TYPE_REDIRECT,
  FIELD_PICKUP_LOCATION,
  FIELD_REQUIRED,
  INVENTORY_USED,
  INVENTORY_NEW,
  FinanceTypes,
  UNKNOWN_ERROR,
  ENTERPRISE_ORDER_PROCESS_ERROR,
  ENTERPRISE_ORDER_PROCESS_SUCCESS,
  CONTEXT_DEFAULT,
  REGISTRATION_TYPE_PRIVATE,
  DRIVER_TYPE_PERSONAL,
  PARSE_VEHICLE_UPGRADES,
  COMPLETE_CONFIGURATION_CHANGED,
  ENTERPRISE_ORDER_UPDATED,
  REGISTRATION_TYPE_BUSINESS,
  DELIVERY_DETAILS_CHANGED,
  USER_ZIPCODE_MODAL,
  MODAL_REQUIRED,
  MODAL_LARGE,
  MODAL_SMALL,
  MODAL_FULLSCREEN,
  LOAD_LATEST_LEXICON_MODAL,
  UPDATE_PROVINCE_ADDRESS,
  UPDATE_DISTRICT_ADDRESS,
  ERROR_VIEW_ONLY,
  NAVIGATION_SELECT_KEY,
  NAVIGATION_VIEW_OVERVIEW,
  APPROXIMATE_MATCH,
  EXACT_MATCH,
  POST_PROCESS_SWAP_COMPLETE,
  POST_PROCESS_SWAP_FAIL,
  POST_ORDER_SWAP_ERROR_MODAL,
  MOBILE_APP_ACTION_SHOW_HEADER,
  MOBILE_APP_ACTION_FETCH_PRODUCT_LIST,
  NAVIGATION_VIEW_CONFIRMATION,
  MS_RAVEN_TRIMS,
  MX_RAVEN_TRIMS,
  SAW_APPROXIMATE_MATCH,
  SAW_EXACT_MATCH,
  TIME_SPENT_OF_PUBLIC_ORDER_FORM_FILLING,
  SUMMARY_PANEL_FINANCE_TERM_CHANGE,
  LEGACY_MODEL3_TRIM_CODE,
  SUMMARY_PANEL_LEASE_TERM_CHANGE,
  TYPE_INVENTORY_SWAP,
} from 'dictionary';
import {
  setOption,
  closeAllModals,
  updateDeliveryDetails,
  getInventoryAvailability,
  setHasAvailableInventory,
  openModal,
  updateDistrictList,
  updatePostalCodeList,
  resetVehicleDesign,
  navigationSelectKey,
  restorePreviousConfig,
  setRedirectDetails,
  togglePostOrderConfirmationPage,
} from 'actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Calculator, Configurator } from '@web/tesla-rest-ds-services';
import { request } from 'utils/requestAgent';
import { getSuperRegion } from '@tesla/intl-display-names';
import { formatCurrency } from '@tesla/coin-common-components';

import CreditCardEncryptor from '../../../common/cc_encryptor';

export function updateRegistration() {
  return (dispatch, getState) => {
    const state = getState();
    const address = _get(state, `Location.sources.${ADDRESS_REGISTRATION}`, {});

    dispatch({
      type: LOCATION_SOURCE_CHANGE,
      sourceName: ADDRESS_REGISTRATION,
      sourceData: address,
    });
  };
}

function setCodeWeaversLocalStorage(params, massagedData) {
  const {
    trimOption,
    financingPartners,
    leaseDistance,
    leaseDownPayment,
    leaseTerm,
    loanDistance,
    loanDownPayment,
    loanTerm,
    selectedLeaseDistance,
    selectedLeaseTerm,
    selectedLoanDistance,
    selectedLoanTerm,
    isInventory: isInventoryState,
    mileage,
    modelCode,
  } = params;
  const {
    calculatorResult: { grossPrice },
    financeType,
  } = massagedData;
  const config = {
    identifier: `${modelCode},${_get(trimOption, 'code')}`,
    identifierType: 'TeslaDescription',
    isNew: !isInventoryState || mileage <= 50,
    cashPrice: grossPrice,
    mileage,
    registration: {
      date: null,
      number: null,
    },
    isFranchiseApproved: false,
    term: {
      defaultValue: financeType === 'lease' ? selectedLeaseTerm : selectedLoanTerm,
      minimumValue: financeType === 'lease' ? Math.min(...leaseTerm) : Math.min(...loanTerm),
      maximumValue: financeType === 'lease' ? Math.max(...leaseTerm) : Math.max(...loanTerm),
    },
    deposit: {
      defaultValue: financeType === 'lease' ? leaseDownPayment : loanDownPayment,
    },
    annualMileage: {
      defaultValue: financeType === 'lease' ? selectedLeaseDistance : selectedLoanDistance,
      minimumValue:
        financeType === 'lease' ? Math.min(...leaseDistance) : Math.min(...loanDistance),
      maximumValue:
        financeType === 'lease' ? Math.max(...leaseDistance) : Math.max(...loanDistance),
    },
    specificProductType: financeType === 'lease' ? 'PCP' : 'HP',
  };

  financingPartners.forEach(partner => {
    const { storageId } = partner;

    localStorage.setItem(storageId, JSON.stringify(config));
  });
}

/**
 * Triggered when there is a price change.
 * @param response
 * @param calculatorParameters
 * @param codeWeaversParameters
 * @return {Object} contains pricing numbers and their localized versions
 */
function priceChange(response, calculatorParameters, codeWeaversParameters) {
  const dateObject = new Date();
  const { result, financeType, apiResults } = response;
  // Price
  const {
    matchedCodes: prices_per_code,
    priceString: price_string,
    vehiclePrice: total,
    subtotalPrice: subtotal,
  } = apiResults.price;
  const manufacturing_string = prices_per_code.map(option => option.code).toString();

  // Cash
  const { grossPrice, netPrice } = result;

  // Finance or Leasing
  const netPaymentMonthly = _get(result, 'netMonthlyPayment', 0);

  // Finance
  const financeApiResult = _get(result, 'LoanAPI.loanAmount', {});
  const {
    monthlyPayment: financePaymentMonthly,
    amountDueAtSigning: financeAmountDueAtSigning,
    interestRate: financeInterestRate,
    term: financeTerm,
    residualAmount: financeResidualAmount,
  } = financeApiResult;

  // Leasing
  const leaseApiResult = _get(
    result,
    'LeasingAPI.leaseAmount',
    _get(result, 'lease.LeaseAPI.leaseAmount', {})
  );
  const {
    monthlyPayment: leasePaymentMonthly,
    amountDueAtSigning: leaseAmountDueAtSigning,
    interestRate: leaseInterestRate,
    residualAmount: leaseResidualAmount,
  } = leaseApiResult;

  const massagedData = {
    prices_per_code,
    price_string,
    manufacturing_string,
    financeType,
    [financeType]: result,
    // dumping unchanged api results for debugging
    calculatorResult: response,

    total,
    subtotal,
    grossPrice,
    netPrice,
    netPaymentMonthly,

    financeAmountDueAtSigning,
    financeTerm,
    financeInterestRate,
    financePaymentMonthly,
    financeResidualAmount,

    leaseAmountDueAtSigning,
    leasePaymentMonthly,
    leaseInterestRate,
    leaseResidualAmount,
  };

  setCodeWeaversLocalStorage(codeWeaversParameters, massagedData);

  return {
    type: PRICE_CHANGED,

    data: response,

    massagedData,

    calculatorParameters,

    receivedAt: {
      timestamp: Date.now(),
      localeString: dateObject.toLocaleString(),
    },
  };
}

export const getVehicleUpgradesData = (action$, state$) =>
  action$.pipe(
    filter(action => [COMPLETE_CONFIGURATION_CHANGED].includes(action.type)),
    map(() => dispatch => {
      const state = state$.value;
      const vehicleUpgrades = [];
      const selectedOptionCodes = [];
      const mutationIds = [];
      const highlightFeatureGroups = [];
      const featureIncludedInConfig = [];
      const hideActionField = [];
      const upgradeGroups = [];
      let showBasicAutopilotInFeatureList = false;
      const { ReviewDetails, CustomGroups, App } = state;
      const { isCoinReloaded } = App || {};
      const isBaseApSelected = _get(CustomGroups, 'AUTOPILOT.selected', false);
      const {
        inventoryUpgrades = {},
        inventoryUpgradesInAlternateCurrency = {},
        product,
        featureHighlightGroups = [],
        inventoryFeatureHighlights,
      } = ReviewDetails;
      let featureList = {};
      const { isInventory: isInventoryProduct = false, data: inventoryData } = product || {};
      const { CurrencyCode, CurrencyCodeDisplay, CurrencyCodeAlternate } = inventoryData || {};
      if (!isInventoryProduct) {
        return;
      }
      const conditionalPricingUpgrades = inventoryUpgrades?.CONDITIONAL_PRICING;
      const upgrades = Object.keys(inventoryUpgrades).sort();
      if (upgrades.length) {
        const vatRateForForwardCalculation = getVatRateForForwardCalculation(state) || 1;
        upgrades.reduce((res, grp) => {
          const currentGrp = CustomGroups[grp];
          if (!currentGrp) {
            return res;
          }
          const { current = [], available = [], code: groupCode } = currentGrp || {};
          let selectedUpgrade = {};
          const currentOpts = [];
          current.forEach(elm => {
            const { selected, code, cash_price, long_name, name, price_indicator } = elm;
            // if selected and has optionCode push to selectedOptionsCode array for conditional pricing check
            if (selected && code) {
              selectedOptionCodes?.push(code);
            }
            const upgradeOpt = inventoryUpgrades[grp].find(opt => {
              const { monroney_difference: { added = [] } = {} } = opt;
              return added.includes(code);
            });
            const upgradeOptInAltCurrency = inventoryUpgradesInAlternateCurrency[grp]?.find(opt => {
              const { monroney_difference: { added = [] } = {} } = opt;
              return added.includes(code);
            });
            const { id } = upgradeOpt || {};
            // TODO: clean up once upgrades api is called from UI instead of bff injection
            const upgradePrice = conditionalPricingUpgrades ? elm?.price : upgradeOpt?.monroney?.find(x => x?.code === code)?.price || 0;
            const upgradePriceAltCurrency = upgradeOptInAltCurrency?.monroney?.find(x => x?.code === code)?.price || 0;
            const uiPrice = upgradePrice || cash_price || 0;
            const formattedPrice = uiPrice
              ? formatCurrency(uiPrice * vatRateForForwardCalculation)
              : price_indicator;
            const extraPrice = upgradePriceAltCurrency
              ? {
                  amount: upgradePriceAltCurrency,
                  currency: CurrencyCodeAlternate,
                }
              : undefined;
            if (upgradeOpt) {
              // TODO: revisit for downgrade flow
              if (selected) {
                selectedUpgrade = {
                  uiSelected: selected,
                  optionCode: code,
                  optionName: long_name || name,
                  formattedPrice,
                  formattedCashPrice: formattedPrice,
                  extraPrice,
                  ...(CurrencyCodeDisplay
                    ? {
                        currencyContext: {
                          [CurrencyCode]: cash_price,
                          [CurrencyCodeDisplay]: upgradePrice,
                        },
                      }
                    : {}),
                  ...(CurrencyCodeAlternate
                    ? {
                        currencyContext: {
                          [CurrencyCode]: cash_price,
                          [CurrencyCodeAlternate]: extraPrice?.amount,
                        },
                      }
                    : {}),
                  ...upgradeOpt,
                };
                id && mutationIds.push(id);
              }
              currentOpts.push({
                ...elm,
                ...upgradeOpt,
                price: upgradePrice,
                upgradePrice,
                formattedPrice,
                formattedCashPrice: formattedPrice,
                extraPrice,
              });
            } else if (isCoinReloaded) {
              currentOpts.push(elm);
            }
          });
          const availableMapped = _reduce(
            available,
            (res, group) => {
              res.push({ ...group, group: currentOpts });
              return res;
            },
            []
          );
          if (currentOpts?.length) {
            res.push({
              ...currentGrp,
              ...selectedUpgrade,
              current: currentOpts,
              available: availableMapped,
            });
            upgradeGroups.push(groupCode);
          }
          return res;
        }, vehicleUpgrades);
      }

      // checks if conditional pricing is available
      // remapps vehicle upgrades state and monroney with updated pricing.
      if (conditionalPricingUpgrades?.length && selectedOptionCodes?.length > 1) {
        conditionalPricingUpgrades?.forEach(conditionalUpgrade => {
          if (
            conditionalUpgrade?.monroney_difference?.added?.every(v =>
              selectedOptionCodes?.includes(v)
            )
          ) {
            vehicleUpgrades?.forEach(upgrade => {
              const updatedMonroney = [];
              upgrade?.monroney.forEach((opt, idx) => {
                const conditionalOpt = conditionalUpgrade?.monroney?.find(v => v.code === opt.code);
                const conditionalOptPrice = conditionalOpt?.price || 0;
                if (conditionalOpt && opt?.price !== conditionalOpt?.price) {
                  updatedMonroney.push(conditionalOpt);
                  if (upgrade?.optionCode === opt.code) {
                    upgrade.price = conditionalOptPrice;
                    upgrade.upgradePrice = conditionalOptPrice;
                    upgrade.formattedCashPrice = formatCurrency(conditionalOptPrice);
                    upgrade.formattedPrice = formatCurrency(conditionalOptPrice);
                  }
                } else {
                  updatedMonroney.push(opt);
                }
              });
              upgrade.monroney = updatedMonroney;
            });
          }
        });
      }

      if (!inventoryFeatureHighlights) {
        featureHighlightGroups.forEach(grp => {
          const currentGrp = CustomGroups[grp];
          const { currentSelected = [], code: groupCode } = currentGrp || {};
          if (currentSelected.length) {
            const [currentSelectedFirst] = currentSelected;
            const { price = 0, code } = currentSelectedFirst;
            const selectedOption = {
              ...currentGrp,
              ...currentSelected[0],
            };
            // Adding Price check to find option codes included in base config with price 0 (e.g Base AP, leagcy - AP w/Convenience)
            let result = price ? highlightFeatureGroups : featureIncludedInConfig;
            if (isCoinReloaded && upgradeGroups.includes(groupCode)) {
              result = featureIncludedInConfig;
            }
            if (!hideActionField.includes(code)) {
              hideActionField.push(code);
              result.push({ ...selectedOption });
            }
          }
        });
        const isAppendConnectivityTrial = highlightFeatureGroups.length === 1
          && highlightFeatureGroups[0]?.code === CustomGroups['CONNECTIVITY_TRIAL']?.currentSelected?.[0]?.code;
        featureList = {
          hideActionField,
          inventoryFeatureHighlights: {
            highlightFeatureGroups: isAppendConnectivityTrial ? [...featureIncludedInConfig, ...highlightFeatureGroups] : highlightFeatureGroups,
            featureIncludedInConfig,
            showBasicAutopilotInFeatureList,
          },
        };
      }
      dispatch({
        type: PARSE_VEHICLE_UPGRADES,
        payload: {
          vehicleUpgrades,
          vehicleUpgradesCategories: _reduce(
            vehicleUpgrades,
            (res, v) => {
              return [...new Set([...res, v.code])];
            },
            []
          ),
          mutationIds,
          ...featureList,
        },
      });
    })
  );

export const updatePricesEpic = (action$, state$) => {
  return action$.pipe(
    filter(action => {
      const state = state$.value;
      const { ApplicationFlow = {}, ReviewDetails = {}, App = {} } = state;
      const {
        actionsToUpdatePrices = [],
        commonActionsToUpdatePrices = [],
        inventoryActionsToUpdatePrices = [],
      } = ReviewDetails || {};
      const priceUpdateActions = [].concat(
        commonActionsToUpdatePrices,
        actionsToUpdatePrices,
        inventoryActionsToUpdatePrices
      );
      const { initializeOn, isInitialized } = ApplicationFlow || {};
      const { isGetUserLocationEnabled } = App || true;
      return (
        initializeOn.includes(action.type) ||
        ((isInitialized || !isGetUserLocationEnabled) && priceUpdateActions.includes(action.type))
      );
    }),
    map(({ userInput = {}, type: actionType = '' }) => {
      const state = state$.value;
      const {
        OMS = {},
        ReviewDetails = {},
        SummaryPanel = {},
        FinanceModal = {},
        FinancingOptions = {},
        Pricing = {},
        Financial = {},
        Configuration: { option_codes: configuration_option_codes = [], options_by_group } = {},
        TradeIn: { unformatted_price } = {},
        CustomGroups = {},
        App = {},
        ApplicationFlow = {},
      } = state;
      const { oms_params = {}, lexicon = {} } = OMS;
      const { market } = oms_params;
      const financeProductType = getProductTypeMapping(state);

      let option_codes = configuration_option_codes;
      if (market === 'CN') {
        const { base_configuration = [] } = lexicon || {};
        const ravenTrimCode = [...MX_RAVEN_TRIMS, ...MS_RAVEN_TRIMS];
        const isRaven = configuration_option_codes.filter(
          code => ravenTrimCode.indexOf(code) !== -1
        ).length;

        if (isRaven) {
          option_codes = base_configuration;
        }
      }

      const {
        vehicleUpgrades = [],
        product,
        AccountDetail: { AccountType = '' } = {},
        RegistrationDetail: {
          RegistrantType: registrationType,
          RegistrationAddress: registrationAddress = {},
        } = {},
        vehicleDesign: { swapConfig = {}, isEarlyDesignSelected } = {},
        checkDynamicIncentive,
      } = ReviewDetails;
      const {
        orderPlacedDate,
        isReservationToOrderFlow,
        verificationStatus = {},
      } = ApplicationFlow;
      const savedConfiguration = getSavedConfiguration(state);
      const isEditDesign = Boolean(savedConfiguration);
      const {
        isEnterpriseOrder,
        showFederalTaxBanner,
        isPostOrderSwap,
        isPreOrder = false,
        showSavingsAfterTaxCredit,
        isCoinReloaded = false,
        isFinplatV2Enabled = false,
      } = App;
      const { options: trimOptions = [] } = CustomGroups?.TRIM || {};
      const { discounts = [], cash: { grossPrice } = {}, calculatorResult } = Pricing;
      const {
        fms_incentives,
        fms_fees,
        fms_tcc,
        fms_loan,
        fms_lease,
        userSelectedIncentives,
        fms_finplat
      } = Financial;
      const {
        benefitType,
        includeServiceFee,
        residualAmount,
        interestRateType,
        commute,
        ecoTaxReduction = 'other',
        loanType = {},
        selectedIncentives = [],
        selected_tab,
        deliveryRegion,
        userLeaseAmount,
      } = SummaryPanel;
      let { region_code } = SummaryPanel;
      region_code = market === 'US' && !region_code ? 'CA' : region_code;
      const {
        showOrderFee = false,
        driverType = '',
        applyForFinancing: { financingPartners = [] } = {},
        includeTeslaInsurance = false,
        showRegistrationAboveSummary = false,
      } = FinancingOptions;
      const { selectedView } = FinanceModal;
      const { data: inventoryData = {}, isUsedInventory, isInventory } = product || {};
      const {
        Odometer = 0,
        RegistrationDetails = {},
        IsDemo = false,
        IsLegacy = false,
        Year,
        Discount = 0,
        TradeInType = null,
        TrimCode,
        TrimName,
        PurchasePriceMap,
        ActualVesselArrivalDate,
        FirstRegistrationDate = null,
        OptionCodePricing,
      } = inventoryData || {};
      const { apiResultsPerTrim, extraPriceContexts: extraPriceContextsData } =
        calculatorResult?.data || {};
      const {
        registrationCount = 0,
        State: regStateCode = null,
        LicenseStatus = false,
        firstRegistered = null,
      } = RegistrationDetails || {};
      // Previously, this depended on the SummaryPanel.selected_tab, but with the
      // new finance modal we need the loan info present to be able to fill the Loan Calculator.
      const recalculateAll = ['TR'].includes(market);
      const iterations = getActiveFinanceTypes(state, !recalculateAll);
      const currentFinanceType = getFinanceType(state);
      const financeProductData = getFinanceProductData(state);
      const financeProductId = getFinanceProductId(state);
      const financePlanId = _get(state, `Forms.${currentFinanceType}Data.params.financePlanId`, '');
      const fallBackFinancePlanAppliedTo = _get(state, `Forms.fallBackFinancePlanAppliedTo`, '');
      let fallbackFinancePlan;
      const trimCode = getTrimCode(state);
      if (
        fallBackFinancePlanAppliedTo.indexOf(trimCode) > -1 &&
        actionType !== 'SUMMARY_PANEL_DOWNPAYMENT_CHANGE'
      ) {
        fallbackFinancePlan = _get(state, `Forms.fallbackFinancePlan`, '');
      }
      const ipRegionCode = _get(state, 'Location.components.summaryPanel.regionCode');
      const geoLocationRegionCode = _get(state, 'Location.sources.geoIp.region.regionCode');
      const { StateProvince: regStateProvince } = registrationAddress || {};
      const regionCode = regStateProvince || ipRegionCode || region_code || geoLocationRegionCode;
      const odometerMileage = parseFloat(Odometer) || 0;
      const vehicleYear = Year || new Date().getFullYear();
      const showDynamicIncentive = showFederalTaxBanner && checkDynamicIncentive;
      const priceContext = state.App.pricingContext;
      const { credit: referralCredit = 0, includedInPayments } = getReferralCredit(state);
      const leaseTerm = getLeaseTerm(state);
      const loanTerm = getLoanTerm(state);
      const options = {
        ...oms_params,
        fuelDist: getFuelDistance(state),
        fuelPrice: getFuelPrice(state),
        electricityPrice: getElectricityPrice(state),
        fuelEfficiency: getFuelEfficiency(state),
        maintenanceAmount: getMaintenanceAmount(state),
        considerTermForGas: doWeConsiderTermForGas(state),
        options: option_codes,
        leaseDistance: getLeaseMileage(state),
        leaseTerm,
        userLeaseAmount,
        loanTerm,
        loanDistance: getLoanDistance(state),
        loanApr: getInterestRate(state),
        balloonPayment:
          getBalloonPayment(state) ||
          getResidualInfo(state, financePlanId && { selectedTerm: loanTerm })?.value,
        benefitType,
        includeServiceFee,
        residualAmount,
        interestRateType,
        // for congestion charge
        commute,
        ecoTaxReduction,
        tradeInAmount: unformatted_price,
        discounts,
        applyDeltas: false,
        regionCode,
        loanType,
        vehiclePrice: isEarlyDesignSelected && !isCoinReloaded ? swapConfig?.PurchasePrice : null,
        teslaInsurance: includeTeslaInsurance,
        extraFeeAmount: getAddOnToGrossPrice(state),
        newRegState: regStateCode !== regionCode,
        transportationFee: getTransportationFeeAmount(state),
        prevRegistered: registrationCount > 0 || odometerMileage > 50 || IsDemo,
        isInventory: !!isInventory,
        isEarlyDesignSelected,
        licenseStatus: LicenseStatus,
        financeProductId,
        financePlanId,
        selectedIncentives,
        vehicleYear,
        deliveryFee: getDestinationAndDocFee(state),
        upgrades: [],
        orderFee: showOrderFee ? getDepositAmount(state) : 0,
        registrationAge: getRegistrationAge(state),
        vatRateForForwardCalculation: getVatRateForForwardCalculation(state) || 1,
        optionCodes: configuration_option_codes,
        optionsByGroup: options_by_group,
        showDynamicIncentive,
        apiResultsPerTrim,
        trimOptions,
        priceContext,
        isEnterpriseOrder,
        recalculateResultsPerTrim:
          showDynamicIncentive || actionType !== COMPLETE_CONFIGURATION_CHANGED,
        trimPrice: getTrimPrice(state),
        referralCredit: includedInPayments ? referralCredit : 0,
        taxesAndFees: getTaxesAndFees(state),
        months: getFuelMonthsByFinanceType(state),
        inventoryDiscount: isEarlyDesignSelected && !isCoinReloaded ? swapConfig?.Discount : 0,
        isPreorder: isPreOrder,
        isReservationToOrderFlow,
        showSavingsAfterTaxCredit,
        userSelectedIncentives,
        verificationStatus,
        swapDiscount: isCoinReloaded && isEarlyDesignSelected ? swapConfig?.Discount || 0 : 0,
        ...(isFinplatV2Enabled ? { fms_finplat } : {}), // need to pass finplat data to calculate apiResultsPerTrim
      };

      if (isInventory) {
        options.vehiclePrice = getInventoryBasePrice(state);
        // Inventory price base for markets with multiple pricing contexts
        options.vehiclePriceMap = PurchasePriceMap;
        options.odometerMileage = odometerMileage;
        options.inventory = (isUsedInventory ? INVENTORY_USED : INVENTORY_NEW).toLowerCase();
        options.inventoryDiscount = parseFloat(Discount);
        options.isLegacy = IsLegacy;
        options.ActualVesselArrivalDate = ActualVesselArrivalDate;
        // TWS-44459: calculate DK registration tax off of discounted (Purchase Price) price for
        // new inventory that is has never been registered (Odometer < 50)
        // fleet new inventory as well as used inventory tax is calculated off of pre-discounted price
        // TWS-44702: IE is always using pre-discounted (total price) for VRT
        const useDiscountedPrice = market === 'DK' && odometerMileage < 50 && !isUsedInventory;
        if (useDiscountedPrice) {
          options.vehiclePriceExAccessories = options.vehiclePrice;
        }
        if (market === 'IS' && isUsedInventory) {
          options.tradeInType = TradeInType;
        }
        if (market === 'CN' && !isUsedInventory) {
          options.optionCodePricing = _get(
            state,
            'ReviewDetails.product.data.OptionCodePricing',
            null
          );
        }
        if (market === 'AU') {
          options.firstRegistered = firstRegistered || FirstRegistrationDate;
        }
        options.upgrades = vehicleUpgrades;
        options.deliveryRegion = deliveryRegion || regionCode;
        options.isDemo = IsDemo;
        options.inventoryPricePerOption = getInventoryOptionPricing(state);
        if (_get(inventoryData, 'FederalIncentives.IsTaxIncentiveEligible') && !isEnterpriseOrder) {
          options.showDynamicIncentive = true;
        }
      }
      const unselectedOptions = getUiOptionsNotInConfiguration(state);
      const extraPriceContexts = Object.values(getAllPricingContexts([priceContext]));
      const parameters = {
        Incentives: fms_incentives,
        Fees: fms_fees,
        TCC: fms_tcc,
        Lexicon: lexicon,
        Configuration: { options: option_codes, UnselectedOptions: unselectedOptions },
        UnselectedOptions: unselectedOptions,
      };
      let customerType = driverType;
      if (customerType === DRIVER_TYPE_PERSONAL) {
        customerType = REGISTRATION_TYPE_PRIVATE;
      }
      customerType = customerType || AccountType;
      customerType = registrationType || customerType;
      if (
        (isEnterpriseOrder && !showRegistrationAboveSummary) ||
        tesla?.Order?.orderDetails?.registrantType === 'COMPANY'
      ) {
        customerType = REGISTRATION_TYPE_BUSINESS;
      }
      options.customerType = customerType;
      const accessoriesTotal = getAccessoriesTotal(state);
      // Accessories are calculated as part of T4B order. Calc VAT
      if (isEnterpriseOrder && accessoriesTotal > 0) {
        options.accessoriesPrice = accessoriesTotal;
      }
      if ((isEditDesign || isPostOrderSwap) && orderPlacedDate) {
        options.orderPlacedDate = orderPlacedDate;
      }
      const memoizedTotalCash = memoize(Calculator.total, {
        ...options,
        financeProductType,
        financeType: 'cash',
      });
      const cashResult = memoizedTotalCash(
        {
          ...parameters,
        },
        {
          ...options,
          financeProductType,
          financeType: 'cash',
          extraPriceContexts,
          extraPriceContextsData,
        }
      );
      const useCurrentFormDownpayment =
        market === 'CN' &&
        (actionType === SUMMARY_PANEL_FINANCE_TERM_CHANGE ||
          actionType === SUMMARY_PANEL_LEASE_TERM_CHANGE);
      const response = {};
      let incentives = {};
      let fees = {};
      let tcc = {};
      const excludeDefaultDownpaymentVAT = shouldExcludeDefaultDownpaymentVAT(state, {
        financeType: currentFinanceType,
        financeProductId,
      });
      const leaseDownPayment = getDownPayment(state, {
        grossPrice: cashResult.grossPrice,
        excludeVAT: excludeDefaultDownpaymentVAT,
        VATAmount: _get(cashResult, 'variables.fees.vat_percent.total', null),
        financePlanId: userInput?.financePlanId,
        useCurrentFormDownpayment: useCurrentFormDownpayment,
      });
      const loanDownPayment = getDownPayment(state, { grossPrice: cashResult.grossPrice });
      const loanDownPaymentSelection = getDownPayment(state, {
        grossPrice: cashResult.grossPrice,
        preservePercentage: true,
        financePlanId: userInput?.financePlanId || fallbackFinancePlan,
        loanTerm: options?.loanTerm,
        useCurrentFormDownpayment: useCurrentFormDownpayment,
      });

      const trimOption = _get(state, 'CustomGroups.TRIM.currentSelected[0]', {
        code: TrimCode,
        long_name: TrimName,
      });
      const trim =  trimOption.code || getTrimCode(state);

      // Prefill trimCode in userInput
      if (!userInput.trimCode) {
        userInput = {
          ...userInput,
          trimCode: trimOption.code || getTrimCode(state),
        };
      }

      iterations.map(financeType => {
        switch (financeType) {
          case FinanceTypes.CASH:
            incentives = _get(cashResult, 'apiResults.incentives');
            fees = _get(cashResult, 'apiResults.fees');
            tcc = _get(cashResult, 'apiResults.tcc');
            _merge(response, cashResult);
            break;
          case FinanceTypes.LOAN:
            const standardFinancePlan = getStandardFinancePlanInfo(state);
            const standardFinancePlanId = standardFinancePlan?.value;
            const currentFinancePlanInfo = getCurrentFinancePlanInfo(state);
            const currentFinancePlanId = currentFinancePlanInfo?.value;
            const isCurrentPlanPromotion = currentFinancePlanInfo?.isPromotionPlan;
            const getFinanceResult = financePlanId => {
              const memoizedTotalLoan = memoize(Calculator.total, {
                ...options,
                downPayment: loanDownPaymentSelection,
                financeType: 'loan',
                financePlanId,
              });
              return memoizedTotalLoan(
                {
                  ...parameters,
                  Loan: fms_loan,
                },
                {
                  ...options,
                  downPayment: loanDownPaymentSelection,
                  financeType: 'loan',
                  trimOptions,
                  priceContext,
                  financePlanId,
                }
              );
            };
            const financeResult = getFinanceResult(currentFinancePlanId);
            if (market === 'CN' && isCurrentPlanPromotion) {
              const correspondingStandardFinanceResult = getFinanceResult(standardFinancePlanId);
              _merge(financeResult, { correspondingStandardFinanceResult });
            }
            incentives = _get(financeResult, 'apiResults.incentives');
            fees = _get(financeResult, 'apiResults.fees');
            tcc = _get(financeResult, 'apiResults.tcc');
            _merge(response, financeResult);
            break;
          case FinanceTypes.LEASE:
            const aldDownPaymentPercent = _get(state, 'ALD.downPaymentPercent');
            const memoizedTotalLease = memoize(Calculator.total, {
              ...options,
              downPayment: leaseDownPayment,
              financeType: 'lease',
              aldDownPaymentPercent,
              financePlanId: userInput?.financePlanId,
            });
            const leaseResult = memoizedTotalLease(
              {
                ...parameters,
                Lease: fms_lease,
              },
              {
                ...options,
                downPayment: leaseDownPayment,
                financeType: 'lease',
                trimOptions,
                priceContext,
                aldDownPaymentPercent,
                financePlanId: userInput?.financePlanId ?? options.financePlanId,
              }
            );
            incentives = _get(leaseResult, 'apiResults.incentives');
            fees = _get(leaseResult, 'apiResults.fees');
            tcc = _get(leaseResult, 'apiResults.tcc');
            _merge(response, leaseResult);
            break;
          case FinanceTypes.FINPLAT: {
            const downPaymentOverride = getDefaultDownPaymentOverride(state, financeProductId);
            const { finplatUserInputs: inputs } = state.Forms || {};
            const userInputFlags = inputs?.flags || {};
            const savedUserInputs = inputs?.[`${financeProductId}`] || {};
            const regState = regionCode ? { registrationState: regionCode } : {};
            const regType = registrationType
              ? { registrationType: _upperFirst(registrationType) }
              : {};
            const finplatOptions = {
              ...options,
              userInput: {
                ...downPaymentOverride,
                ...userInputFlags,
                ...savedUserInputs,
                ...userInput,
                ...regState,
                ...regType,
                coeBid: FinancingOptions?.coeBidAmount || null,
              },
              financeProductData,
              selectedForm: selectedView,
              financeType: 'finplat',
              financeProductType,
              vehiclePriceWithFees: grossPrice,
            };
            const memoizedFinplat = memoize(Calculator.total, finplatOptions);
            const finplatResult = memoizedFinplat(
              {
                ...parameters,
              },
              finplatOptions
            );
            incentives = _get(finplatResult, 'apiResults.incentives');
            fees = _get(finplatResult, 'apiResults.fees');
            tcc = _get(finplatResult, 'apiResults.tcc');
            _merge(response, finplatResult);
            break;
          }
          default:
            return null;
        }
        return null;
      });

      // incentives and fees must not be merged as it can result in incorrect values
      // being applied for specific financeTypes override inventives and
      // fees with those available from final iteration
      _set(response, 'apiResults.incentives', incentives);
      _set(response, 'apiResults.fees', fees);
      _set(response, 'apiResults.tcc', tcc);

      if (iterations.includes(response.financeType)) {
        // if monthly payment is about to show a negative number, set it to zero (0)
        [
          'result.netMonthlyPayment',
          'result.loan.monthlyPayment',
          'result.loan.netMonthlyPayment',
          'result.loan.financedAmount',
          'result.lease.monthlyPayment',
          'result.lease.netMonthlyPayment',
          'result.lease.monthlyPaymentWithoutVat',
          'result.lease.netMonthlyPaymentWithoutVat',
        ].forEach(path => {
          if (_has(response, path) && _get(response, path) < 1) {
            _set(response, path, 0);
          }
        });
      }

      const leaseProps = _get(financeProductData, 'leaseTermsOffered', {});
      const loanProps = _get(financeProductData, 'loanTermsOffered', {});
      return priceChange(
        response,
        {
          ...options,
          financeType: selected_tab,
        },
        {
          financingPartners,
          trimOption,
          leaseDistance: getFinanceProductDistancesOffered(state),
          leaseTerm: _get(leaseProps, 'leaseTerm', []),
          loanDistance: _get(loanProps, 'loanDistance', []),
          loanTerm: _get(loanProps, 'loanTerm', []),
          selectedLeaseDistance: getLeaseMileage(state),
          selectedLeaseTerm: getLeaseTerm(state),
          selectedLoanDistance: getLoanDistance(state),
          selectedLoanTerm: getLoanTerm(state),
          leaseDownPayment,
          loanDownPayment,
          isInventory,
          mileage: Odometer,
          modelCode: getModelCode(state),
          vehicleYear,
        }
      );
    })
  );
};

export function orderSchemaToState({ config, isLegacy }) {
  const optionCodes = config.mktOptionCodes.map(option => option.code);
  return {
    Configuration: {
      option_codes: optionCodes,
      option_codes_saved: optionCodes,
      call_toggle_option: true,
      isLegacy,
    },
  };
}

export function stateToOrderSchemaSX({ state, baseSchema }) {
  const schema = stateToSchema(state);

  const { config: baseConfig } = baseSchema;
  const { totalPrice, currencyCode, countryCode, model } = baseConfig;
  const modelOption = getModelCode(state);
  const configSchema = {
    ...schema,
    asset_code: model,
    base_price_default: _get(state, `OMS.lexicon.options.${modelOption}.price`),
    model_name: _get(state, `OMS.lexicon.options.${modelOption}.name`),
  };
  return {
    config: {
      ...baseConfig,
      extraConfig: {
        ...configSchema,
        subtotal: totalPrice,
        base_price_default: totalPrice,
        currency: currencyCode,
        market: countryCode,
      },
    },
  };
}

/**
 * Returns promise with encrypted credit card token
 * @param  {Object} state [description]
 * @return {[type]}       [description]
 */
export async function getCreditCardToken(state) {
  const { encryptor, time, key } = getEncryptorParams(state);
  return CreditCardEncryptor.encrypt(encryptor, getCardData(state), key, time);
}

/**
 * Returns schema for inventory order endpoint (m3 inventory only at this point)
 * @param  {Object} options.state [redux state]
 * @return {[type]}               [description]
 */
export async function inventory_stateToOrderWithPaymentSchema({ state }) {
  // Encrypt payment source
  const paymentDetail = _get(state, 'Payment.PaymentDetail', {});
  const cvc = _get(paymentDetail, 'CreditCardDetail.VerificationNumber');
  const accountNumber = _get(paymentDetail, 'CreditCardDetail.AccountNumber');
  const creditCardToken = _get(paymentDetail, 'CreditCardDetail.CreditCardToken');
  const generationtime = _get(state, 'Payment.time');
  const encryptor = _get(state, 'Payment.encryptor');
  const countryCode = paymentDetail.CountryCode || _get(state, 'OMS.oms_params.market');
  const region = getSuperRegion(countryCode);
  const isBurstMode = _get(state, 'App.isBurstMode');
  const { App = {}, ApplicationFlow = {}, Pricing = {} } = state || {};
  const { isInventorySwapEnabled = false, isCoinReloaded } = App;
  const { numberOfTimesPaymentFailed = 0 } = ApplicationFlow || {};
  const encryptorKeyName =
    encryptor === STRIPE_PAYMENTS && region.code === 'REEU' && !isBurstMode ? 'key_other' : 'key';
  const key = _get(state, `Payment.configs.${encryptor}.${encryptorKeyName}`);
  const creditCardDetail = _get(paymentDetail, 'CreditCardDetail', {});
  const invoiceType = _get(state, 'Payment.InvoiceType', {});
  const inventoryData = _get(state, 'ReviewDetails.product.data', {});
  const { swapConfig = {}, isEarlyDesignSelected, earlyVehicleDesigns = [] } = _get(
    state,
    'ReviewDetails.vehicleDesign',
    {}
  );
  const vehicleCity = _get(state, 'ReviewDetails.DeliveryLocations.vehicleCity', '');
  const esignFlowEnabled = isEsignFlowEnabled(state);

  const {
    CurrencyCode = '',
    PurchasePriceMap = {},
    PurchasePrice = 0,
    VIN = '',
    OptionCodeData: optionCodeData = [],
    Public_Hash = '',
    Model: model = '',
  } = isEarlyDesignSelected ? swapConfig : inventoryData || {};

  let ccDetail = creditCardDetail;
  const { RedirectPaymentName, IsOffline } = paymentDetail;

  if (creditCardToken != null) {
    const lastFourDigits = _get(paymentDetail, 'CreditCardDetail.LastFourDigits');
    ccDetail = {
      ...creditCardDetail,
      CreditCardToken: creditCardToken,
      LastFourDigits: lastFourDigits,
    };
    ccDetail = _omit(ccDetail, ['AccountNumber', 'VerificationNumber']);
  } else if (accountNumber && cvc && isClientSideEncryptionEnabledMarket(state)) {
    const cardData = getCardData(state);
    // Process payment encryption
    const encryptedData = await CreditCardEncryptor.encrypt(
      encryptor,
      cardData,
      key,
      generationtime
    );

    if (encryptedData) {
      ccDetail = {
        ...creditCardDetail,
        CreditCardToken: encryptedData,
        LastFourDigits: accountNumber.substr(-4),
      };
      ccDetail = _omit(ccDetail, ['AccountNumber', 'VerificationNumber']);
    } else {
      // Error encrypting data
      return false;
    }
  }

  const depositAmountWithType = getDepositAmount(state, true);
  const orderAmount = _get(depositAmountWithType, 'orderPayment', 0);
  const paymentSubType = _get(depositAmountWithType, 'paymentSourceSubType', null);

  // This is assuming Creditcard:
  let Payment = {
    ...paymentDetail,
    ...(ccDetail
      ? {
          CreditCardDetail: ccDetail,
        }
      : {}),
    ...(RedirectPaymentName
      ? {
          PaymentType: RedirectPaymentName,
          IsOffline,
        }
      : {}),
    OrderAmount: orderAmount,
    PaymentSourceSubType: paymentSubType,
  };

  // assuming apple pay
  const payment = _get(state, 'ApplePay.payment');

  // Check for Apple Pay:
  if (payment) {
    Payment = {
      ...Payment,
      ApplePay: {
        SessionToken: btoa(JSON.stringify(_get(payment, 'token.paymentData'))),
      },
      PaymentType: 'APPLEPAY',
      PayorName: `${_get(payment, 'billingContact.givenName', '')} ${_get(
        payment,
        'billingContact.familyName',
        ''
      )}`,
      CreditCardDetail: null,
    };
  }

  // Check for Wire Transfer
  const chosenPaymentType = _get(paymentDetail, 'PaymentType', PAYMENT_TYPE_CC);
  if (
    chosenPaymentType === PAYMENT_TYPE_WT ||
    chosenPaymentType === PAYMENT_TYPE_POS ||
    chosenPaymentType === PAYMENT_TYPE_REDIRECT
  ) {
    _unset(Payment, 'CreditCardDetail');
  }

  const { platform, countryCode: market, language } = _get(state, 'App');
  const useExisting = _get(state, 'Payment.CreditCardDetail.IsProfileExists', false);
  const VehiclePrice = isCoinReloaded && isEarlyDesignSelected ? Pricing?.total : PurchasePriceMap[CurrencyCode] || PurchasePrice;
  const { CreditCardDetail } = _get(state, 'Payment.PaymentDetail', {});
  const SaveWithProfile = _get(CreditCardDetail, 'AgreedToSaveProfile', false);
  const BrowserInfo = {
    ...platform,
    DeviceType: getDeviceType(state),
    trafficSource: getTrafficSource(),
    trafficSourceHistory: getTrafficSourceHistory(),
    isInventorySwapEnabled,
    numberOfTimesPaymentFailed,
    activitysessionId: getActivitySessionId(),
  };
  const isUsedInventory = _get(state, 'ReviewDetails.product.isUsedInventory', false);
  const isComboInventory = _get(state, 'ReviewDetails.product.isComboInventory', false);
  const isSalesReferred = _get(state, 'ReviewDetails.isSalesReferred', false);

  const LastFourDigits = accountNumber ? accountNumber.substring(accountNumber.length - 4) : null;
  const multiFlexDetail = getMultiFlexDetails(state);
  const flexOptions = getFlexOptions(state);
  const acknowledgementDocuments = getAcknowledgementDocuments(state);
  const isDm = _get(state, 'App.isDm', false);
  const deliveryLocation = getDeliveryLocationDetails(state);
  const inventoryCity =
    countryCode === 'CN' && !isUsedInventory && isComboInventory && vehicleCity
      ? { vehicleCity }
      : {};
  // Map inventory flow
  let extraSwapData = {};
  if (isEarlyDesignSelected) {
    const configData = stateToOrderSchema({ state });
    extraSwapData = {
      customerConfig: getSwapInitialOptionsList(state),
      swapOptionsList: getSwapOptionsList(state),
      swapType: getSwapType(state),
    };
  }
  const requestObj = _omitBy(
    {
      BrowserInfo: {
        ...BrowserInfo,
        isPublicReferred: isSalesReferred,
        isDm,
      },
      Vin: VIN || Public_Hash,
      ...(esignFlowEnabled ? { esignFlowEnabled } : {}),
      isUsedInventory,
      market,
      language,
      model,
      useExisting,
      VehiclePrice,
      optionCodeData,
      SaveWithProfile,
      RegistrationDetail: _get(state, 'ReviewDetails.RegistrationDetail', {}),
      referralCode: _get(state, 'ApplicationFlow.referral.referralCode', null),
      Payment: {
        ...Payment,
        ...(_get(state, 'Payment.CreditCardDetail.IsProfileExists') &&
        !creditCardDetail.AgreedToSaveProfile
          ? {
              ProcessWithProfile: !payment,
              LastFourDigits,
            }
          : {
              LastFourDigits,
            }),
        isV3Payment: true,
      },
      Accessories: getAccessoriesPayload(state),
      AccountDetails: _get(state, 'ReviewDetails.AccountDetail', {}),
      DeliveryDetails: getTmpDeliveryDetailsNormalized(state),
      TwEFapiaoProfile: invoiceType,
      UserLocation: _get(state, 'Location.sources.geoIp.location', {}),
      multiFlexDetail,
      acknowledgementDocuments,
      hasVehicleHistoryReport: _get(state, 'App.isAutoCheckEnabled', false),
      flexOptions,
      subscriptions: getSubscriptions(state),
      Configs: {
        config: {
          currencyCode: CurrencyCode,
        },
      },
      isSwap: isEarlyDesignSelected,
      ...deliveryLocation,
      ...inventoryCity,
      ...extraSwapData,
    },
    _isNil
  );

  return requestObj;
}

export async function stateToOrderWithPaymentSchema({ state }, instrumentDetails, uniqueId) {
  const { App, ReviewDetails, OMS, ApplicationFlow, DeliveryTiming = {} } = state;
  const {
    isBurstMode,
    platform = {},
    isDm = false,
    isRegistrationDetailDisabled = false,
    isNativePaymentEnabled,
    isInventorySwapEnabled = false,
    isCoinReloaded = false,
  } = App;
  const { numberOfTimesPaymentFailed = 0 } = ApplicationFlow || {};
  const rn = _get(state, 'Configuration.rn', '');
  const ts = new Date().getTime();

  let UniqueId = uniqueId || `${rn}-${ts}`;
  if (rn === '') {
    UniqueId = `${_get(state, 'ReviewDetails.AccountDetail.Email')}-${ts}`;
  }

  // Gather orderSchema data
  const orderSchema = _omit(stateToOrderSchema({ state }), ['comments', 'impersonatedUserName']);
  const { inventoryLocation = undefined } = ReviewDetails || {};
  const { postalCode = '' } = inventoryLocation || {};

  // Encrypt payment source
  const paymentDetail = _get(state, 'Payment.PaymentDetail', {});
  const cvc = _get(paymentDetail, 'CreditCardDetail.VerificationNumber');
  const accountNumber = _get(paymentDetail, 'CreditCardDetail.AccountNumber');
  const creditCardToken = _get(paymentDetail, 'CreditCardDetail.CreditCardToken');
  const generationtime = _get(state, 'Payment.time');
  const encryptor = _get(state, 'Payment.encryptor');
  const countryCode = paymentDetail.CountryCode || _get(state, 'OMS.oms_params.market');
  const region = getSuperRegion(countryCode);
  const encryptorKeyName =
    encryptor === STRIPE_PAYMENTS && region.code === 'REEU' && !isBurstMode ? 'key_other' : 'key';
  const key = _get(state, `Payment.configs.${encryptor}.${encryptorKeyName}`);
  const creditCardDetail = _get(paymentDetail, 'CreditCardDetail', {});
  const invoiceType = _get(state, 'Payment.InvoiceType', {});
  const { earlyVehicleDesigns = [], pickupLocations = null, isEarlyDesignSelected } = _get(
    state,
    'ReviewDetails.vehicleDesign',
    {}
  );
  const BrowserInfo = {
    ...platform,
    DeviceType: getDeviceType(state),
    trafficSource: getTrafficSource(),
    trafficSourceHistory: getTrafficSourceHistory(),
    activitysessionId: getActivitySessionId(),
    isInventorySwapEnabled,
    numberOfTimesPaymentFailed,
  };

  let ccDetail = creditCardDetail;
  let deviceFingerprint = null;
  const { RedirectPaymentName, IsOffline } = paymentDetail;

  if (creditCardToken != null) {
    const lastFourDigits = _get(paymentDetail, 'CreditCardDetail.LastFourDigits');
    ccDetail = {
      ...creditCardDetail,
      CreditCardToken: creditCardToken,
      LastFourDigits: lastFourDigits,
    };
    ccDetail = _omit(ccDetail, ['AccountNumber', 'VerificationNumber']);
  } else if (accountNumber && cvc && isClientSideEncryptionEnabledMarket(state)) {
    const cardData = getCardData(state);
    // Process payment encryption
    const encryptedData = await CreditCardEncryptor.encrypt(
      encryptor,
      cardData,
      key,
      generationtime
    );

    if (encryptedData) {
      ccDetail = {
        ...creditCardDetail,
        CreditCardToken: encryptedData,
        LastFourDigits: accountNumber.substr(-4),
      };
      ccDetail = _omit(ccDetail, ['AccountNumber', 'VerificationNumber']);
    } else {
      // Error encrypting data
      return false;
    }
    // Device Fingerprint
    if (_get(state, 'App.adyenDf.enabled')) {
      const dfId = _get(state, 'App.adyenDf.value');
      const dfTarget = dfId ? document.getElementById(dfId) : null;
      deviceFingerprint = dfTarget ? dfTarget.value : null;
    }
  }

  const depositAmountWithType = getDepositAmount(state, true, CONTEXT_DEFAULT);
  const orderAmount = _get(depositAmountWithType, 'orderPayment', 0);
  const paymentSubType = _get(depositAmountWithType, 'paymentSourceSubType', null);

  let Payment = {
    ...paymentDetail,
    ...(ccDetail
      ? {
          CreditCardDetail: ccDetail,
        }
      : {}),
    ...(RedirectPaymentName
      ? {
          PaymentType: RedirectPaymentName,
          IsOffline,
        }
      : {}),
    OrderAmount: orderAmount,
    PaymentSourceSubType: paymentSubType,
  };

  // assuming apple pay
  const payment = _get(state, 'ApplePay.payment');

  // Check for Apple Pay:
  if (payment) {
    Payment = {
      ...Payment,
      ApplePay: {
        SessionToken: btoa(JSON.stringify(_get(payment, 'token.paymentData'))),
      },
      PaymentType: 'APPLEPAY',
      PayorName: `${_get(payment, 'billingContact.givenName', '')} ${_get(
        payment,
        'billingContact.familyName',
        ''
      )}`,
      CreditCardDetail: null,
    };
  }

  // for save design
  const isSaveDesign = _get(state, 'ReviewDetails.isSaveDesignEnabled');
  const accountDetail = _get(state, 'ReviewDetails.AccountDetail', {});
  if (isSaveDesign) {
    Payment = {
      ...Payment,
      PaymentType: 'wt',
      PayorName: `${_get(accountDetail, 'CCFirstName', '')} ${_get(
        accountDetail,
        'CCLastName',
        ''
      )}`,
      CreditCardDetail: null,
      GeoPostalCode: _get(state, 'Location.sources.geoIp.postalCode'),
    };
  }

  // Check for Wire Transfer
  const chosenPaymentType = _get(paymentDetail, 'PaymentType', 'CC');
  if (
    chosenPaymentType === PAYMENT_TYPE_WT ||
    chosenPaymentType === PAYMENT_TYPE_POS ||
    chosenPaymentType === PAYMENT_TYPE_REDIRECT ||
    chosenPaymentType === PAYMENT_TYPE_WECHAT
  ) {
    _unset(Payment, 'CreditCardDetail');
  }

  const eddData = _get(state, 'ReviewDetails.initialEDD', null);
  let initialEDD = null;
  const { isAvailableNow = false } = DeliveryTiming || {};
  if (isAvailableNow) {
    initialEDD = {
      deliveryWindowDisplay: 'Available Now',
      deliveryWindowStart: new Date().toISOString(),
      deliveryWindowEnd: null,
    };
  } else if (!_isEmpty(eddData)) {
    const computedDeliveryWindow = getEstimateDeliveryDate(state);
    initialEDD = {
      deliveryWindowDisplay: computedDeliveryWindow?.deliveryWindowDisplay,
      deliveryWindowStart: computedDeliveryWindow?.deliveryWindowStart,
      deliveryWindowEnd: computedDeliveryWindow?.deliveryWindowEnd,
    };
  }
  const requestObj = {
    initialEDD,
    Payments: {
      Payment: {
        ...Payment,
        isV3Payment: true,
      },
      InstrumentDetails: instrumentDetails,
      UniqueId,
      DeviceFingerprint: deviceFingerprint,
      TwEFapiaoProfile: invoiceType,
      ...(_get(state, 'Payment.CreditCardDetail.IsProfileExists') &&
      !creditCardDetail.AgreedToSaveProfile
        ? {
            ProcessWithProfile: !payment,
          }
        : {}),
    },
    BrowserInfo,
    Configs: orderSchema,
    rn,
    referralCode: _get(state, 'ApplicationFlow.referral.referralCode', null),
    isSaveDesign,
    DeliveryDetails: getTmpDeliveryDetailsNormalized(state),
    Accessories: getAccessoriesPayload(state),
    subscriptions: getSubscriptions(state),
    isReservedPreOrder: _get(window, 'tesla.isReservedPreOrder', false),
    ...(!!inventoryLocation && !!postalCode
      ? {
          deliveryLocationSelectionDetails: {
            searchZipCode: postalCode,
          },
        }
      : {}),
    swapType: getSwapType(state),
    isSwap: isEarlyDesignSelected,
  };

  // Prepare Account creation data
  if (!isReservationToOrder(state)) {
    // Pass user details (name, email, etc.)
    requestObj.AccountDetail = _get(state, 'ReviewDetails.AccountDetail', {});

    // Determine platform values (browser, deviceType, etc.)
    requestObj.BrowserInfo = {
      ...platform,
      DeviceType: getDeviceType(state),
      sourceAdvocate: getAdvocate(),
      trafficSource: getTrafficSource(),
      trafficSourceHistory: getTrafficSourceHistory(),
      activitysessionId: getActivitySessionId(),
      isDm,
      isInventorySwapEnabled,
      numberOfTimesPaymentFailed,
    };
  }
  let RegistrationDetail = _get(state, 'ReviewDetails.RegistrationDetail', {});
  if (isRegistrationDetailDisabled) {
    RegistrationDetail = {
      RegistrantType: _get(state, 'ReviewDetails.AccountDetail.AccountType', ''),
      RegistrationAddress: {
        CountryCode: _get(paymentDetail, 'BillingInfoDetail.CountryCode', ''),
        StateProvince: _get(paymentDetail, 'BillingInfoDetail.StateProvince', ''),
      },
    };
  }
  if (isNativePaymentEnabled) {
    RegistrationDetail = {
      ...RegistrationDetail,
      RegistrantType: '',
    };
  }
  requestObj.RegistrationDetail = RegistrationDetail;

  return requestObj;
}

export function saveDesignSchema({ state }) {
  // Gather orderSchema data
  const orderSchema = _omit(stateToOrderSchema({ state }), [
    'comments',
    'impersonatedUserName',
    'config.sourceStoreInfo',
  ]);
  const isEnterprisePO = isEnterprisePurchaseOrder(state);
  const accessories = isEnterprisePO ? getAccessoriesPayloadPO(state) : undefined;

  const schema = {
    Configs: orderSchema,
    rn: _get(state, 'Configuration.rn', ''),
    referralCode: _get(state, 'ApplicationFlow.referral.referralCode', {}),
    isEnterpriseCustomOrderEdit: _get(state, 'App.isEnterpriseCustomOrderEdit', false),
    accessories,
    subscriptions: getSubscriptions(state),
  };

  return schema;
}

export function postMktConfig() {
  return (dispatch, getState) => {
    dispatch({
      type: LOADER_START,
    });
    const state = getState();
    const schema = saveDesignSchema({ state });

    if (!schema) {
      // Encryption or some other modification failed
      dispatch({
        type: LOADER_FINISH,
      });
      return dispatch({
        type: POST_ORDER_FAIL,
        error: { code: PAYMENT_FAILED },
      });
    }
    schema.isManualAddress = state.Accessories?.googleAddressNotFound;

    dispatch({
      type: POST_ORDER_START,
      data: schema,
    });
    const { App } = state;
    const { routes = {} } = App;
    const isEnterprisePO = isEnterprisePurchaseOrder(state);
    const { saveConfig = '/configurator/api/config_save', updatePurchaseOrderConfig = '' } = routes;
    const apiRoute = isEnterprisePO ? updatePurchaseOrderConfig : saveConfig;
    request
      .post(apiRoute)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Accept', 'application/json')
      .send(schema)
      .end((error, response) => {
        const isEnterprise = _get(state, 'App.isEnterpriseOrder', false);
        const referenceNumber = _get(state, 'Configuration.rn', '');
        if (error) {
          dispatch({
            type: LOADER_FINISH,
          });
          let errorCode = UNKNOWN_ERROR;
          let errorMessage = '';
          if (error.status !== 200) {
            errorCode = _get(response, 'body.error.ErrorCode');
            errorMessage = _get(response, 'body.error.Message');
          }

          if (error.status === 412 && _toUpper(errorMessage) === 'EDIT ORDER TIME EXPIRED') {
            dispatch({
              type: SESSION_TIME_OUT,
              error: { code: CONFIG_SAVE_TIME_OUT },
            });
          }

          if (isEnterprise) {
            return dispatch({
              type: ENTERPRISE_ORDER_PROCESS_ERROR,
              payload: { errorCode, errorMsg: i18n('Enterprise.edit_design_error') },
            });
          }

          return dispatch({
            type: POST_ORDER_FAIL,
            error: { code: errorCode },
          });
        }

        Storage.remove(LOCAL_STORAGE_KEY);
        if (isEnterprisePO) {
          // Redirect to enterprise fulfillment review for PO
          dispatch({
            type: ENTERPRISE_ORDER_UPDATED,
          });
          const { locale, base_url: baseUrl } = App;
          const { fulfillmentPoUpload = '' } = routes;
          const normalizedLocale = locale === 'en_US' ? '' : locale;
          setTimeout(() => {
            dispatch({
              type: LOADER_FINISH,
            });
            window.location.href = localizeUrl(fulfillmentPoUpload, {
              baseUrl,
              locale: normalizedLocale,
              delimiter: '_',
            });
          }, 300);
          return null;
        }
        dispatch({
          type: LOADER_FINISH,
        });
        if (isEnterprise) {
          const modelOption = getModelCode(state);
          const model = _get(state, `OMS.lexicon.options.${modelOption}`, {});
          const modelName = _get(model, 'name', '');
          const refNumber = isEnterprisePO
            ? _get(state, 'Configuration.savedConfiguration.config.purchaseOrderNumber', '')
            : referenceNumber;
          return dispatch({
            type: ENTERPRISE_ORDER_PROCESS_SUCCESS,
            payload: {
              referenceNumber: refNumber,
              msg: i18n('Enterprise.edit_design_success', {
                MODEL: modelName,
                RESERVATION_NUMBER: refNumber,
              }),
            },
            data: schema,
            response,
          });
        }
        return dispatch({
          type: POST_SAVE_CONFIG_SUCCESS,
          data: schema,
          response,
        });
      });
    return null;
  };
}

export function cancelEditOrder(hideLoader = true) {
  return (dispatch, getState) => {
    dispatch({
      type: LOADER_START,
    });
    const state = getState();

    const cancelOrder = {
      editOrderType: 'CANCEL',
      rn: _get(state, 'Configuration.rn', ''),
    };

    const { App } = state;
    const { routes = {} } = App;
    const { editOrder = '/configurator/api/edit_order' } = routes;

    request
      .post(editOrder)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Accept', 'application/json')
      .send(cancelOrder)
      .end(() => {
        if (hideLoader) {
          dispatch({
            type: LOADER_FINISH,
          });
        }
      });
  };
}

export function postMktConfigWithPayment(paymentObj = null) {
  return async (dispatch, getState) => {
    dispatch({
      type: LOADER_START,
    });

    const state = getState();
    const schema = await stateToOrderWithPaymentSchema({ state });

    // wechat offical account pay
    if (state.App.openId) {
      schema.openId = state.App.openId;
    }

    if (!schema) {
      // Encryption or some other modification failed
      dispatch({
        type: LOADER_FINISH,
      });
      return dispatch({
        type: POST_ORDER_FAIL,
        error: { code: PAYMENT_FAILED },
      });
    }
    schema.isManualAddress = state.Accessories?.googleAddressNotFound;

    dispatch({
      type: POST_ORDER_START,
      data: schema,
    });

    const savePaymentURL = getPostOrderUrl(state);
    request
      .post(savePaymentURL)
      .send(schema)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Accept', 'application/json')
      .end((error, response) => {
        dispatch({
          type: LOADER_FINISH,
        });
        if (error) {
          let errorCode = UNKNOWN_ERROR;
          if (error.status !== 200) {
            errorCode = _get(response, 'body.error.ErrorCode');
          }
          const { App } = state;
          const { isLayoutMobile, isLayoutTablet } = App;
          const isDesktop = !isLayoutMobile && !isLayoutTablet;
          smoothScrollToError(errorCode, isDesktop);
          return dispatch({
            type: POST_ORDER_FAIL,
            error: { code: errorCode },
          });
        }
        Storage.remove(LOCAL_STORAGE_KEY);

        dispatch({
          type: POST_ORDER_SUCCESS,
          data: schema,
          response,
        });
        const { body = {} } = response || {};
        const { token, returnUrl } = body || {};
        if (paymentObj.start !== null && token) {
          paymentObj.start(token, { redirectUrl: returnUrl });
        }
      });
    return null;
  };
}

export function postOrderWithPayment(paymentObj = null) {
  return async (dispatch, getState) => {
    dispatch({
      type: LOADER_START,
    });

    const state = getState();

    if (state.App.isViewOnly) {
      dispatch({
        type: LOADER_FINISH,
      });
      return dispatch({
        type: POST_ORDER_FAIL,
        error: {
          code: ERROR_VIEW_ONLY,
        },
      });
    }
    const shouldTriggerPayment = !isEsignFlowEnabled(state);
    const inventoryOrder = isInventory(state) || isInventoryOrderForPreOrderSwap(state);
    const schema = inventoryOrder
      ? await inventory_stateToOrderWithPaymentSchema({ state })
      : await stateToOrderWithPaymentSchema({ state });

    // wechat offical account pay
    if (state.App.openId) {
      schema.openId = state.App.openId;
    }

    if (state.App.isWeChatMiniEnv) {
      schema.isWeChatMiniEnv = state.App.isWeChatMiniEnv;
    }
    const market = getMarket(state);
    if (market === 'CN') {
      const { timeOfClickPlaceOrderButton } = state.ReviewDetails;
      if (timeOfClickPlaceOrderButton) {
        const timeOfClickCreatePublicOrderButton = new Date().getTime();
        const timeSpent = (timeOfClickCreatePublicOrderButton - timeOfClickPlaceOrderButton) / 1000;
        schema.timeSpentOnFormFilling = timeSpent;
        window.gdp &&
          window.gdp('track', TIME_SPENT_OF_PUBLIC_ORDER_FORM_FILLING, {
            is_verify_phone_number_enabled: state.App.isVerifyPhoneNumberEnabled ? 'Yes' : 'No',
            time_spent: timeSpent,
          });
      }
    }

    const pickupLocationRequired = _get(state, 'Payment.pickupLocationRequired');
    const pickupLocation = _get(
      schema,
      `${
        inventoryOrder ? 'Payment.BillingInfoDetail' : 'Payments.Payment.BillingInfoDetail'
      }.PickupLocation`
    );

    if (pickupLocationRequired && !pickupLocation) {
      dispatch({
        type: LOADER_FINISH,
      });
      return dispatch({
        type: POST_ORDER_FAIL,
        error: {
          code: FIELD_REQUIRED,
          field: FIELD_PICKUP_LOCATION,
        },
      });
    }

    if (!schema) {
      // Encryption or some other modification failed
      dispatch({
        type: LOADER_FINISH,
      });
      return dispatch({
        type: POST_ORDER_FAIL,
        error: { code: PAYMENT_FAILED },
      });
    }
    schema.isManualAddress = state.Accessories?.googleAddressNotFound;
    dispatch({
      type: POST_ORDER_START,
      data: schema,
    });
    // Inventory and public orders
    const orderUrl = getPostOrderUrl(state);
    request
      .post(orderUrl)
      .send(schema)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Accept', 'application/json')
      .end((error, response) => {
        dispatch({
          type: LOADER_FINISH,
        });
        if (error) {
          let errorCode = UNKNOWN_ERROR;
          let errorData = {};
          if (error.status !== 200) {
            errorCode = _get(response, 'body.error.ErrorCode');
            errorData = _get(response, 'body.error.ErrorData');
          }

          // Dispatch the order fail
          return dispatch({
            type: POST_ORDER_FAIL,
            error: { code: errorCode, data: errorData },
          });
        }
        clearAccessorySelected();
        dispatch({
          type: POST_ORDER_SUCCESS,
          data: schema,
          response,
        });
        const { body = {} } = response || {};
        const { token, returnUrl } = body || {};
        if (shouldTriggerPayment && paymentObj.start !== null && token) {
          paymentObj.start(token, { redirectUrl: returnUrl });
        }
      });
    return null;
  };
}

export function postPaymentSignWithPayment(paymentObj = null) {
  return async (dispatch, getState) => {
    dispatch({
      type: LOADER_START,
    });

    const state = getState();
    const paymentDetail = _get(state, 'Payment.PaymentDetail', {});
    const countryCode = paymentDetail.CountryCode || _get(state, 'OMS.oms_params.market');
    const currencyCode = paymentDetail.CurrencyCode || '';
    const accountDetail = _get(state, 'ReviewDetails.AccountDetail', {});
    const market = getMarket(state);

    const paymentSignUrl = getPaymentSignUrl(state);
    const schema =
      market === 'CN'
        ? {
            referenceNumber: state?.Configuration?.rn || '',
            payorName: state?.Payment?.PaymentDetail?.PayorName,
            amount: getDepositAmount(state),
            countryCode: countryCode,
            currencyCode: currencyCode,
            email: accountDetail?.Email || '',
            isInventory: isInventory(state),
            isUsed: isUsedInventory(state),
            orderPlacedTimeStamp: state?.ReviewDetails?.timeOfOrderPlacedSuccess || '',
            modelCode: _get(state, 'OMS.oms_params.model'),
          }
        : {};
    request
      .post(paymentSignUrl)
      .send(schema)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Accept', 'application/json')
      .end((error, response) => {
        dispatch({
          type: LOADER_FINISH,
        });
        if (error) {
          let errorCode = UNKNOWN_ERROR;
          let errorData = {};
          if (error.status !== 200) {
            errorCode = _get(response, 'body.error.ErrorCode');
          }
          return dispatch({
            type: POST_ORDER_FAIL,
            error: { code: errorCode, data: errorData },
          });
        }
        !isEsignFlowEnabled(state) &&
          dispatch({
            type: POST_ORDER_SUCCESS,
            data: schema,
            response,
          });
        const { body = {} } = response || {};
        const { initializedPaymentSignedResponse, returnUrl } = body || {};
        if (paymentObj !== null && paymentObj.start !== null && initializedPaymentSignedResponse) {
          paymentObj.start(initializedPaymentSignedResponse, { redirectUrl: returnUrl });
        } else if (initializedPaymentSignedResponse) {
          dispatch(setRedirectDetails({ token: initializedPaymentSignedResponse, returnUrl }));
        }
      });
    return null;
  };
}

export function processPostOrderSwap() {
  return async (dispatch, getState) => {
    Analytics.postSwapTagEvent('review-changes-summary:confirm-changes');
    dispatch({
      type: LOADER_START,
    });
    const state = getState();
    const market = getMarket(state);
    const isCoinReloaded = _get(state, 'App.isCoinReloaded', false);

    // Process post order inventory swap requests
    const processSwapUrl = getProcessSwapUrl(state);
    const reservationNumber = _get(state, 'ReviewDetails.product.data.ReservationNumber', '');
    const vrl = _get(state, 'ReviewDetails.product.data.PickupLocation.Vrl', 0);
    const orderPickUpLocationVrl = _get(
      state,
      'ReviewDetails.product.orderDetails.PickupLocation.Vrl',
      0
    );
    const publicHash = _get(state, 'ReviewDetails.product.data.PickupLocation.Public_Hash', '');
    const isAtLocation = _get(state, 'ReviewDetails.product.data.IsAtLocation');
    const upgradeMktOptions = getPostOrderSwapUpgradesPayload(state);
    const formattedRequest = {
      reservationNumber,
      vrl: market === 'CN' ? Number(orderPickUpLocationVrl) : vrl,
      publicHash,
      upgradeMktOptions,
      isAtLocation,
    };
    request
      .post(processSwapUrl)
      .send(formattedRequest)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Accept', 'application/json')
      .end((error, response) => {
        if (error) {
          let errorCode = UNKNOWN_ERROR;
          let errorMessage = '';
          if (error.status !== 200) {
            errorCode = error.status;
            errorMessage = _get(error, 'response.body', '');
          }
          Analytics.postSwapTagEvent('order-updated:order-updated-failed', errorMessage);

          const hideHeader = JSON.stringify({
            action: MOBILE_APP_ACTION_SHOW_HEADER,
            payload: false,
          });
          window?.ReactNativeWebView?.postMessage(hideHeader);

          dispatch({
            type: LOADER_FINISH,
          });
          dispatch(
            openModal(POST_ORDER_SWAP_ERROR_MODAL, {
              size: MODAL_FULLSCREEN,
            })
          );

          return dispatch({
            type: POST_PROCESS_SWAP_COMPLETE,
            error: { code: errorCode },
          });
        }

        if(isCoinReloaded) {
          dispatch(togglePostOrderConfirmationPage(true));
        }

        Analytics.postSwapTagEvent('order-updated:order-updated-success');
        dispatch(navigationSelectKey({ key: NAVIGATION_VIEW_CONFIRMATION }));
        dispatch({
          type: POST_PROCESS_SWAP_COMPLETE,
        });

        const fetchProductList = {
          action: MOBILE_APP_ACTION_FETCH_PRODUCT_LIST,
        };
        const messageStr = JSON.stringify(fetchProductList);
        window?.ReactNativeWebView?.postMessage(messageStr);

        dispatch({
          type: LOADER_FINISH,
        });
      });
  };
}

export function postPriceAcceptance(type) {
  return async (dispatch, getState) => {
    dispatch({ type: LOADER_START });

    const state = getState();
    const { sibling, uiLocale, isNewOrderProfileUrl = false, routes = {} } = state.App ?? {};
    const { priceAcceptance = '/configurator/api/v3/price_acceptance' } = routes;
    try {
      const rn = state.Configuration?.rn;
      await request.post(priceAcceptance).send({
        type,
        schema: `${type}`.toLowerCase() === 'accept' ? saveDesignSchema({ state }) : { rn },
      });
      const dashboardUrl = isNewOrderProfileUrl
        ? `teslaaccount/order/${rn}`
        : `teslaaccount/profile?rn=${rn}&redirect=no`;
      window.location = constructUrl(dashboardUrl, sibling, uiLocale);
    } catch (err) {
      // Let this be caught by Sentry
      dispatch({ type: LOADER_FINISH });
    }
  };
}

export const changeHeroView = selected_view => ({ type: CHANGE_HERO_VIEW_TO, selected_view });

export const changeHeroType = selected_type => ({ type: CHANGE_HERO_TYPE_TO, selected_type });

export function selectPackage(setPackage = false) {
  return dispatch => {
    let setPackageVal = setPackage;
    if (!setPackage) {
      // map to first package
      setPackageVal = [];
    }
    dispatch({
      type: 'PACKAGE_SELECTED',
      setPackageVal,
    });
    dispatch(setOption({ set: [].concat(setPackageVal) }));
    dispatch(closeAllModals());
  };
}

/**
 * Function to separate configuration changes by source:
 * We needed this so we could fire Google Analytics events for configuration changes
 * initiated by user, and ignore when changed for othe reasons
 *
 * @param {Object} option
 * @param {String} groupCode
 */
export function setOptionExtended(option, groupCode) {
  return (dispatch, getState) => {
    const state = getState();
    const notifyMigratePricing = state?.ReviewDetails?.migratePricing;
    const savedTrimCode = getSavedTrimCode(state);
    const isLegacyToNV35Flow =
      LEGACY_MODEL3_TRIM_CODE.indexOf(savedTrimCode) !== -1 && checkIfNV35Enabled(state);
    if (notifyMigratePricing && !isLegacyToNV35Flow) {
      // Check which group needs pricing migration / preservance
      const preservedPricingGroups = state?.Configuration?.preservedPricingGroups || [];
      const currentConfig = state?.Configuration?.user_selected_options || [];
      const [cookieRn] = getCookieInfoOfLatestLexiconLoading();
      if (
        preservedPricingGroups.includes(groupCode) &&
        !currentConfig.includes(option.set[0]) &&
        (!cookieRn || cookieRn !== state?.Configuration?.rn)
      ) {
        return dispatch(
          openModal(LOAD_LATEST_LEXICON_MODAL, {
            genericWrapper: true,
            size: MODAL_REQUIRED,
            targetTrim: option.set[0],
          })
        );
      }
    }
    const configuration = {
      userSelectedOptions: [].concat(option.set, state.Configuration.user_selected_options),
      ignoreRules: ['RequiredUpgrades', 'ExistInWorld', 'Deprecated'],
      ...option,
    };
    const { query_params: queryParams = {} } = state?.App || {};
    let { skuValidation = false } = queryParams || {};
    skuValidation = !!skuValidation;
    const response = Configurator.setOption(
      {
        Lexicon: state.OMS.lexicon,
        Configuration: { options: state.Configuration.option_codes },
      },
      { ...configuration, skuValidation }
    );
    const { options } = response;
    const userSelectedOptions = _uniq(
      []
        .concat(state.Configuration.user_selected_options || [], (() => option.set || [])() || [])
        .filter(val => options.includes(val))
    );
    const userSelectedOptionsByGroup = Configurator.optionsByGroup(
      { Groups: state.OMS.oms_groups, Lexicon: state.OMS.lexicon },
      { options: userSelectedOptions }
    );
    dispatch(setOption(option));

    Analytics.fireConfigurationChangeTag({
      set: option.set,
      panelName: option.panelName ? option.panelName : null,
      interaction: option.interaction ? option.interaction : null,
      options,
      userSelectedOptionsByGroup,
      modelCode: _get(state, 'OMS.oms_params.model'),
    });
  };
}

export const experimentFlow = (action$, state$) =>
  action$
    .filter(action => {
      if ([LOCATION_CHANGED].includes(action.type)) {
        const experiment = ['FL'].includes(_get(action, 'location.region.regionCode'));

        if (!experiment) {
          window.location = `/${
            state$.value.OMS.oms_params.model === 'mx' ? 'modelx' : 'models'
          }/design${window.location.search}`;
        }
      }
      return null;
    })
    .map(() => ({ type: 'NOOP' }));

export function triggerLoaderAction(actions, asset) {
  return dispatch => {
    actions.map(action => dispatch({ ...action, asset }));
  };
}

export function triggerGenericAction(action) {
  return dispatch => {
    if (action) {
      dispatch({
        type: action,
      });
    }
  };
}

export const updateDeliveryLocationDetails = (action$, state$) =>
  action$.pipe(
    filter(action => [LOCATION_CHANGED].includes(action.type)),
    map(action => dispatch => {
      const state = state$.value;
      const { showDeliveryZipCode } = state?.FinancingOptions || {};
      if (!showDeliveryZipCode) {
        return;
      }
      const {
        location,
        postalCode = null,
        city,
        county,
        country,
        countryCode,
        countryName,
        region,
      } = action?.location || {};
      const { latitude = null, longitude = null } = location || {};
      return dispatch(
        updateDeliveryDetails({
          Latitude: latitude,
          Longitude: longitude,
          PostalCode: postalCode,
          city,
          countryCode,
          countryName: countryName || country,
          county,
          latitude,
          longitude,
          postalCode,
          stateCode: region?.regionCode,
          stateProvince: region?.longName,
        })
      );
    })
  );

export const updateInventoryLocationAvailability = (action$, state$) =>
  action$.pipe(
    filter(action => [DELIVERY_DETAILS_CHANGED].includes(action.type)),
    map(action => dispatch => {
      const state = state$.value;
      const { showDeliveryZipCode } = state?.FinancingOptions || {};
      const { checkAvailableInventoryForMarket } = state?.ReviewDetails || {};
      const { component } = state?.Modal?.selected || {};
      const { Latitude: lat, Longitude: long, stateCode, PostalCode } = action?.payload || {};
      if (!showDeliveryZipCode || !lat || !long || !stateCode || component === USER_ZIPCODE_MODAL) {
        return;
      }
      const market = getMarket(state);
      const model = getModel(state);
      const geoObj = checkAvailableInventoryForMarket ? {} : { lat, long };
      return getInventoryAvailability({ ...{ market, model }, ...geoObj }, (err, res) => {
        if (res) {
          const { available = false, subset = false } = res;
          dispatch(
            setHasAvailableInventory({
              available,
              subset,
              latitude: lat,
              longitude: long,
              postalCode: PostalCode,
              regionCode: stateCode,
            })
          );
        }
      });
    })
  );

export const getDistrictsByProvince = (action$, state$) =>
  action$.pipe(
    filter(action => [UPDATE_PROVINCE_ADDRESS].includes(action.type)),
    map(action => async dispatch => {
      const state = state$.value;
      const provinces = getProvincesList(state);
      const { id } = provinces?.find(x => x.value === action?.province) || {};
      if (!id) {
        return;
      }
      const data = await getStaticAddress(state, { type: 'district', id });
      if (data) {
        dispatch(updateDistrictList(data));
      }
    })
  );

export const getPostalCodesByDistrict = (action$, state$) =>
  action$.pipe(
    filter(action => [UPDATE_DISTRICT_ADDRESS].includes(action.type)),
    map(action => async dispatch => {
      const state = state$.value;
      const districts = getDistrictsList(state);
      const { id } = districts?.find(x => x.value === action?.district) || {};
      if (!id) {
        return;
      }
      const data = await getStaticAddress(state, { type: 'postalCode', id });
      if (data) {
        dispatch(updatePostalCodeList(data));
      }
    })
  );

export const setInitialDesign = (action$, state$) =>
  action$.pipe(
    filter(action => [NAVIGATION_SELECT_KEY].includes(action.type)),
    map(action => async dispatch => {
      const state = state$.value;
      const { key } = action || {};
      const { isInventorySwapEnabled, isCoinReloaded } = state.App || {};
      const { previousUserConfig } = state.Configuration || {};

      if (isCoinReloaded) {
        return;
      }

      if (!isInventorySwapEnabled && !previousUserConfig) {
        return;
      }
      const { vehicleDesign: { isEarlyDesignSelected } = {} } = state.ReviewDetails || {};

      if (key === NAVIGATION_VIEW_OVERVIEW) {
        isEarlyDesignSelected ? dispatch(resetVehicleDesign()) : dispatch(restorePreviousConfig());
      }
    })
  );
