import React from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { shape, bool, arrayOf } from 'prop-types';
import { referralConfigValidation } from 'selectors';
import ReferralBanner from '../ReferralBanner';
import VerificationBanner from '../VerificationBanner';

/* Group notification banner component */
const NotificationBanner = props => {
  const hasNoVerification = _isEmpty(props?.verificationStatus);
  return (
    <div className="notification-banner">
      <If condition={!hasNoVerification}>
        <VerificationBanner {...props} />
      </If>
      <If condition={hasNoVerification}>
        <ReferralBanner {...props} />
      </If>
    </div>
  );
};

function mapStateToProps(state) {
  const { verificationStatus = [], referral: referralData = {} } = state?.ApplicationFlow;
  const validConfigForReferral = referralConfigValidation(state);
  const creditCash = referralData?.cash?.value || 0;
  const creditTrial = referralData?.trials || [];
  const { showReferralZeroCreditDisclaimer = false } = state?.ReviewDetails || {};

  return {
    verificationStatus,
    validConfigForReferral,
    showReferralZeroCreditDisclaimer,
    showCredits: !!(creditCash || creditTrial?.length),
    referralData,
  };
}

NotificationBanner.propTypes = {
  verificationStatus: arrayOf(shape({})),
  validConfigForReferral: bool,
  showReferralZeroCreditDisclaimer: bool,
  showCredits: bool,
  referralData: shape({}),
};

NotificationBanner.defaultProps = {
  verificationStatus: [],
  validConfigForReferral: false,
  showReferralZeroCreditDisclaimer: false,
  showCredits: false,
  referralData: {},
};

export default connect(mapStateToProps, null)(NotificationBanner);
