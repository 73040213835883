import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCustomPricingIndicatorV2, i18n } from 'utils';
import { STATE_LIST, CONTEXT_DEFAULT } from 'dictionary';
import _indexOf from 'lodash/indexOf';
import _get from 'lodash/get';
import { setSummaryPanelRegion } from 'actions';
import { getPricingContext, getInVehicleUnitFee } from 'selectors';
import StateDropdown from '../../components/StateDropdown';
import RegistrationTypeSelector from '../../components/RegistrationTypeSelector';
import TextLoader from '../../components/TextLoader';
import { htmlToReact } from '../../common/utils';
import { formatCurrency } from '@tesla/coin-common-components';
import {
  getIsFalconDeliverySelectionEnabled,
  isMetShowIncentiveCondition,
} from '../../common/selectors';
import { CN_INCENTIVE_PLAN_202411DISCOUNT } from "../../common/dictionary";

class Summary extends Component {
  fields = {};

  getOptionIndicatorOverwrite = option => {
    const {
      configuration,
      CustomGroups,
      priceData,
      includeFeesinBatteryCalculationPayment,
      useOptionPriceOverridePayment,
      trimOptions,
    } = this.props;
    const paymentPageOverride = true;

    return getCustomPricingIndicatorV2(
      [option],
      configuration,
      priceData,
      includeFeesinBatteryCalculationPayment,
      useOptionPriceOverridePayment && trimOptions.includes(option.code),
      CustomGroups,
      paymentPageOverride
    );
  };

  getExtraOptionIndicatorOverwrite = option => {
    const {
      configuration,
      CustomGroups,
      extraPriceData,
      includeFeesinBatteryCalculationPayment,
      useOptionPriceOverridePayment,
      trimOptions,
    } = this.props;
    const paymentPageOverride = true;

    return getCustomPricingIndicatorV2(
      [option],
      configuration,
      extraPriceData,
      includeFeesinBatteryCalculationPayment,
      useOptionPriceOverridePayment && trimOptions.includes(option.code),
      CustomGroups,
      paymentPageOverride
    );
  };

  setFieldRef(fieldName, ref) {
    this.fields[fieldName] = ref;
  }

  render() {
    const {
      discounts,
      baseConfiguration,
      options,
      isPriceIndicatorVisible,
      hideBaseField,
      destinationAndDocFee,
      showDistinctTaxTotal,
      setRegion,
      showRegistrationTypeSelector,
      showRegistrationAboveSummary,
      isNativePaymentEnabled,
      isPostOrderSwap,
      depositAmount,
      showOrderFee,
      interstateTransitFee,
      showVehiclePricePlusFees,
      showFeesSummary,
      extraPriceData,
      showRegionSelectorOrderPage,
      pricingContext,
      referral,
      inUnitFee,
      hideMobilePaymentSummary,
      isFalconDeliverySelectionEnabled,
      cashTabIncentives,
      enableIncentiveInSwap,
      showPricesDetail,
      incentivesLabelKey,
    } = this.props;
    const baseOptions = baseConfiguration?.map(x => x.code) || [];

    return (
      <div className="cf-summary--section tds-o-text_color--20">
        <ol className="tds-list cf-summary-list">
          <If condition={_indexOf(hideBaseField, STATE_LIST) < 0 && showRegionSelectorOrderPage}>
            <li className="tds-list-item cf-territory-dropdown">
              <StateDropdown
                onChange={setRegion}
                id="payment-overview-summary--state-selector"
                data-id="registration-state-select"
              />
            </li>
          </If>
          <If condition={showRegistrationTypeSelector && !isNativePaymentEnabled && !isFalconDeliverySelectionEnabled}>
            <li className="tds-list-item">
              <RegistrationTypeSelector radioName="summary" />
              <span className="summary-page--disclaimer">
                {i18n('Review.summary_page__disclaimer')}
              </span>
            </li>
          </If>
          {discounts.map(discount => (
            <li key={`discount-${discount.key}`} className="tds-list-item tds-flex">
              <span className="label tds-flex-item">{i18n(`Review.${discount.key}`)}</span>
              <span className="value tds-flex--col_1of3">{formatCurrency(discount.value)}</span>
            </li>
          ))}
          {(!hideMobilePaymentSummary || isPriceIndicatorVisible) && baseConfiguration.map((item, key) => (
            <li className="tds-list-item tds-flex" key={`${item.name + key}`}>
              <span className="label--option tds-flex-item">
                {`${item.long_name} ${(() =>
                  (item.spec_description && ` - ${item.spec_description}`) || '')()}`
                  ? htmlToReact(
                      `${item.long_name} ${(() =>
                        (item.spec_description && ` - ${item.spec_description}`) || '')()}`
                    )
                  : ''}
              </span>
              {isPriceIndicatorVisible && (
                <span className="value price-indicator tds-flex--col_1of3">
                  {(item => {
                    const indicatorOption = this.getOptionIndicatorOverwrite(item);
                    const result =
                      typeof indicatorOption === 'number'
                        ? formatCurrency(indicatorOption)
                        : indicatorOption;
                    return indicatorOption !== false ? result : item.formattedPrice;
                  })(item)}
                  <If condition={extraPriceData}>
                    <span className="double-currency">
                      {(item => {
                        const indicatorOption = this.getExtraOptionIndicatorOverwrite(item);
                        const result =
                          typeof indicatorOption === 'number'
                            ? formatCurrency(indicatorOption, {
                                currency: extraPriceData?.currency,
                              })
                            : indicatorOption;
                        return indicatorOption !== false ? result : item.formattedPrice;
                      })(item)}
                    </span>
                  </If>
                </span>
              )}
            </li>
          ))}
          {options.map(option => {
            if (
              (!hideMobilePaymentSummary || isPriceIndicatorVisible) &&
              !baseOptions?.includes(option?.code)
            ) {
              return (
                <li className="tds-list-item tds-flex" data-code={option.code} key={option.code}>
                  <TextLoader
                    key={`Summary:${option.code}`}
                    data={option}
                    tag="span"
                    field="long_name"
                    className="tds-flex-item"
                  />
                  {isPriceIndicatorVisible && (
                    <span className="value price-indicator tds-flex--col_1of3">
                      {(option => {
                        const indicatorOption = option?.option_override_pricing
                          ? this.getOptionIndicatorOverwrite(option)
                          : false;
                        const result =
                          typeof indicatorOption === 'number'
                            ? formatCurrency(indicatorOption)
                            : indicatorOption;
                        return indicatorOption !== false ? result : option.formattedPrice;
                      })(option)}
                      <If condition={option.extraPrice}>
                        <span className="double-currency">
                          {formatCurrency(option.extraPrice.amount, {
                            currency: option.extraPrice.currency,
                            useDynamicRounding: true,
                          })}
                        </span>
                      </If>
                    </span>
                  )}
                </li>
              );
            }
          })}
          <If condition={isPostOrderSwap && enableIncentiveInSwap}>
            <li className="tds-list-item tds-flex">
              <span className="label tds-flex-item">{i18n(incentivesLabelKey)}</span>
              <If condition={!showPricesDetail}>
                <span className="value tds-flex--col_1of3">
                  {formatCurrency(cashTabIncentives?.incentiveInPurchasePrice || 0)}
                </span>
              </If>
            </li>
          </If>
          <If condition={isPriceIndicatorVisible}>
            <If condition={showDistinctTaxTotal && destinationAndDocFee > 0}>
              <li className="tds-list-item tds-flex">
                <span className="label tds-flex-item">
                  {i18n('FinancingOptions.destinationDocfee')}
                </span>
                <span className="value tds-flex--col_1of3">
                  {formatCurrency(destinationAndDocFee)}
                </span>
              </li>
            </If>
            <If
              condition={
                showOrderFee && depositAmount > 0 && !showVehiclePricePlusFees && !showFeesSummary
              }
            >
              <li className="tds-list-item tds-flex">
                <span className="label tds-flex-item">{i18n(isPostOrderSwap ? 'SummaryPanel.order_fee_paid' : 'Review.order_fee')}</span>
                <span className="value tds-flex--col_1of3">
                  {formatCurrency(depositAmount, { currency: pricingContext })}
                </span>
              </li>
            </If>
            <If condition={interstateTransitFee}>
              <li className="tds-list-item tds-flex">
                <span className="label tds-flex-item">{i18n('Review.interstate_transit_fee')}</span>
                <span className="value tds-flex--col_1of3">
                  {formatCurrency(interstateTransitFee)}
                </span>
              </li>
            </If>
            <If
              condition={showDistinctTaxTotal && referral?.credit && referral?.includedInPayments}
            >
              <li className="tds-list-item tds-flex">
                <span className="label tds-flex-item">{i18n('Referral.referrer_name')}</span>
                <span className="value tds-flex--col_1of3">
                  {formatCurrency(-referral?.credit)}
                </span>
              </li>
            </If>
            <If condition={inUnitFee}>
              <li className="tds-list-item tds-flex">
                <span className="label tds-flex-item">{i18n('Review.inVehicleUnit')}</span>
                <span className="value tds-flex--col_1of3">{formatCurrency(inUnitFee)}</span>
              </li>
            </If>
          </If>
        </ol>
      </div>
    );
  }
}

const { shape, string, number, bool, func, arrayOf } = PropTypes;

Summary.propTypes = {
  discounts: arrayOf(shape()),
  baseConfiguration: arrayOf(shape()),
  options: arrayOf(shape()),
  isPriceIndicatorVisible: bool,
  configuration: arrayOf(string),
  CustomGroups: shape({}),
  priceData: shape({}),
  extraPriceData: shape({}),
  includeFeesinBatteryCalculationPayment: bool,
  hideBaseField: arrayOf(string),
  destinationAndDocFee: number,
  showDistinctTaxTotal: bool,
  showRegistrationTypeSelector: bool,
  depositAmount: number,
  showOrderFee: bool,
  setRegion: func,
  useOptionPriceOverridePayment: bool,
  trimOptions: arrayOf(string),
  interstateTransitFee: number,
  showVehiclePricePlusFees: bool,
  showFeesSummary: bool,
  isNativePaymentEnabled: bool,
  isPostOrderSwap: bool,
  showRegionSelectorOrderPage: bool,
  pricingContext: string,
  inUnitFee: number,
  hideMobilePaymentSummary: bool,
  cashTabIncentives: shape({}),
  enableIncentiveInSwap: bool,
  showPricesDetail: bool,
  incentivesLabelKey: string,
};

Summary.defaultProps = {
  isPriceIndicatorVisible: false,
  configuration: [],
  discounts: [],
  baseConfiguration: [],
  options: [],
  CustomGroups: {},
  priceData: [],
  includeFeesinBatteryCalculationPayment: false,
  hideBaseField: [],
  destinationAndDocFee: 0,
  showDistinctTaxTotal: false,
  showRegistrationTypeSelector: false,
  depositAmount: 0,
  showOrderFee: false,
  setRegion: () => {},
  useOptionPriceOverridePayment: false,
  trimOptions: [],
  interstateTransitFee: 0,
  showVehiclePricePlusFees: false,
  showFeesSummary: false,
  isNativePaymentEnabled: false,
  isPostOrderSwap: false,
  showRegionSelectorOrderPage: false,
  pricingContext: '',
  inUnitFee: 0,
  hideMobilePaymentSummary: false,
  isFalconDeliverySelectionEnabled: false,
  cashTabIncentives: {},
  enableIncentiveInSwap: false,
};

function mapStateToProps(state) {
  const { isLayoutMobile, isLayoutTablet, hideMobilePaymentSummary, isPostOrderSwap } =
    state.App || {};
  const { showRegionSelectorOrderPage, showPricesDetail } = state?.ReviewDetails || {};
  const { apiResultsPerTrim, apiResults, extraPriceContexts = {} } =
    state?.Pricing?.calculatorResult?.data || {};
  const displayDoubleCurrency = _get(state, 'App.displayDoubleCurrency');

  let extraPriceData;

  if (displayDoubleCurrency && Object.keys(extraPriceContexts).length > 0) {
    const { apiResultsPerTrim: extraApiResultsPerTrim, apiResults: extraApiResults } =
      Object.values(extraPriceContexts)[0] || {};

    extraPriceData = {
      ...extraApiResults,
      apiResultsPerTrim: extraApiResultsPerTrim,
      currency: Object.keys(extraPriceContexts)[0],
    };
  }
  return {
    selectedStateCode: _get(state, 'SummaryPanel.region_code', null),
    countryCode: _get(state, 'Payment.CountryCode', ''),
    CustomGroups: state.CustomGroups,
    includeFeesinBatteryCalculationPayment: _get(
      state,
      'FinancingOptions.includeFeesinBatteryCalculationPayment',
      false
    ),
    useOptionPriceOverridePayment: _get(
      state,
      'FinancingOptions.useOptionPriceOverridePayment',
      false
    ),
    trimOptions: _get(state, 'CustomGroups.TRIM.options', []),
    priceData: {
      ...apiResults,
      apiResultsPerTrim,
    },
    extraPriceData,
    showVehiclePricePlusFees: state?.ReviewDetails?.showVehiclePricePlusFees || false,
    isNativePaymentEnabled: _get(state, 'App.isNativePaymentEnabled'),
    isPostOrderSwap,
    showRegionSelectorOrderPage,
    pricingContext: getPricingContext(state, CONTEXT_DEFAULT),
    inUnitFee: getInVehicleUnitFee(state),
    hideMobilePaymentSummary: isLayoutMobile || isLayoutTablet ? hideMobilePaymentSummary : false,
    isFalconDeliverySelectionEnabled: getIsFalconDeliverySelectionEnabled(state),
    enableIncentiveInSwap: isMetShowIncentiveCondition(state),
    showPricesDetail,
    incentivesLabelKey: `SummaryPanel.PaymentSummary.${CN_INCENTIVE_PLAN_202411DISCOUNT}`,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setRegion: regionCode => {
      dispatch(setSummaryPanelRegion(regionCode));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Summary);
