/* eslint-disable no-param-reassign */
/* eslint prefer-rest-params: 0 */
import _get from 'lodash/get';
import _has from 'lodash/has';
import _find from 'lodash/find';
import _reduce from 'lodash/reduce';
import _isString from 'lodash/isString';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';
import _indexOf from 'lodash/indexOf';
import _forEach from 'lodash/forEach';
import _flow from 'lodash/flow';
import _filter from 'lodash/filter';
import _intersection from 'lodash/intersection';
import Cookie from 'js-cookie';
import { getSuperRegion, getStates } from '@tesla/intl-display-names';
import { validatePostalCode } from '@tesla/intl-address';
import { formatCurrency, getString } from '@tesla/coin-common-components';

import { includesCodes, includesAnyCode } from './configuration';
import { getPostOrderSwapCustomAvailability, matchesTargetValue, getQueryParameters } from 'utils';
import { request } from 'utils/requestAgent';

import {
  CONTEXT_DEFAULT,
  PAYMENT_SOURCE_RESERVATION,
  INCENTIVE_PLUGIN_GRANT,
  GOOGLE_ADDRESS_GEOMETRY,
  GOOGLE_ADDRESS_COMPONENTS,
  TYPE_CONFIGURATOR,
  TYPE_INVENTORY,
  TAX_REGISTRATION,
  TAX_WINTER_TIRE_FEE,
  INCENTIVE_E_MOBILITY_BONUS,
  INCENTIVE_GOVERNMENT_GRANT,
  INCENTIVE_SELLER_DISCOUNT,
  BUTTON_START,
  BUTTON_END,
  Models,
  REGION_EU,
  REGION_ME,
  REGION_AP,
  ERROR_INVALID_SHIPPING_ADDRESS,
  REGION_NA,
  PICKUP_SERVICE_CENTER,
  TRIM,
  CITY_DUBAI,
  PICKUP_ONLY,
  COMPONENT_CONTEXT_ENTERPRISE,
  COMPONENT_CONTEXT_CONFIGURATOR,
  COMPONENT_CONTEXT_INVENTORY,
  COMPONENT_CONTEXT_SEEKRET,
  COMPONENT_CONTEXT_MATRIX,
} from 'dictionary';

import {
  getCommerceTaxPayload,
  getCommerceTaxEndpoint,
  hasShippingAddress,
  isTaxInclusive,
  getCurrentDeliveryRegion,
  isVehicleOnSite,
  getRegistrationRestrictionStates,
  getModelCode,
  getModelName,
  getTrimCode,
  getFalconDeliverySectionVersion,
} from '../selectors';

import countryStateList from '../country-state-mapping.json';

const memoCache = {};

import { getZipWithExtension } from './format';
import { applySpecsOverrides } from '../../reducers/CustomGroups/Reducer';
import { containsPOBoxAddress } from './geo';

export * from './accessories';
export * from './google';
export * from './device';
export * from './errors';
export * from './format';
export * from './locale';
export * from './price';
export * from './routing';
export * from './storage';
export * from './time';
export * from './type';
export * from './validation';
export * from './geo';
export * from './configuration';
export * from './general';
export * from './services';
export * from './state';
export * from './react';
export * from './detect';
export * from './requestAgent';

/**
 * i18n method.
 * Simple refactor to arrow function is not possible because arrow function
 * dont have the `arguments` object.
 *
 * @param {String} key
 * @param {Object} vars
 * @param {String} contextVal
 * @param {Object} props
 * @return {String}
 */
// eslint-disable-next-line func-names
export const i18n = function(key, vars, contextVal, props = {}) {
  let context = contextVal || 'find_my_tesla';
  const Locale = window.tesla.i18n;
  let vars_val = vars;

  if (arguments.length === 2 && typeof arguments[1] === 'string') {
    context = arguments[1];
    vars_val = null;
  }

  if (context.includes('.')) {
    context = [context, 'strings'];
  } else {
    context = `${context}.strings`;
  }

  const strings = _get(Locale, context, {});

  // This will automatically look for specific overrides of this string
  // (usually used for modelCode overrides).
  if (props.specificOverride) {
    let overrideKey = props.specificOverride;
    if (Array.isArray(props.specificOverride)) {
      const specificOverrideFiltered = props.specificOverride.filter(value => value !== null);
      if (specificOverrideFiltered.length > 1) {
        const superSpecificString = getString(
          [key, ...specificOverrideFiltered].join('__'),
          vars,
          strings,
          {
            ...props,
            returnNullWhenEmpty: true,
          }
        );

        if (superSpecificString !== null) {
          return superSpecificString;
        }

        // Allows for both AND and individual OR behavior, if no specific string is found via AND strategy, individual OR will be used instead
        if (props.specificOverrideOperator === 'BOTH') {
          const andOutput = i18n(key, vars, contextVal, {
            ...props,
            specificOverride: [...specificOverrideFiltered],
            specificOverrideOperator: 'AND',
          });
          if (andOutput === getString(key, vars_val, strings, props)) {
            return i18n(key, vars, contextVal, {
              ...props,
              specificOverrideOperator: 'OR',
            });
          }
          return andOutput;
        }
        else if (props.specificOverrideOperator === 'OR') {
          for (const specificOverride of specificOverrideFiltered) {
            const specificString = getString([key, specificOverride].join('__'), vars, strings, {
              ...props,
              returnNullWhenEmpty: true,
            });

            if (specificString !== null) {
              return specificString;
            }
          }
        } else {
          specificOverrideFiltered.pop();

          return i18n(key, vars, contextVal, {
            ...props,
            specificOverride: specificOverrideFiltered,
          });
        }
      }

      overrideKey = specificOverrideFiltered.join('__');
    }

    const specificString = getString([key, overrideKey].join('__'), vars, strings, {
      ...props,
      returnNullWhenEmpty: true,
    });

    if (specificString !== null) {
      return specificString;
    }
  }

  // if Array then return array of strings through getString function
  // allows ability to add variable replacement
  let translatedString = getString(key, vars_val, strings, props);
  if (Array.isArray(translatedString)) {
    const specificStringObj = Object.assign({}, translatedString);
    const tempArray = [];
    let tempCopy = '';
    _forEach(specificStringObj, (value, idx) => {
      tempCopy = getString(idx, vars_val, specificStringObj, {
        ...props,
        returnNullWhenEmpty: true,
      });
      if (tempCopy) {
        tempArray.push(tempCopy);
      }
    });
    translatedString = tempArray;
  }

  return translatedString;
};

/**
 * @param  {Array} types    Array of pricing types
 * @param {String} type     Pricing type to return value for
 * @param {String} context  Pricing context (defaults to "default")
 * @return {Float} value    Pricing type value
 */
export function getOptionPricing(types, type, context = CONTEXT_DEFAULT) {
  const price_type = _find(types, t => t.type === type && t.context === context);
  return typeof price_type === 'undefined' ? 0 : _get(price_type, 'value', 0);
}

/**
 * Get component vaue from address components
 * @param  {Array} types    Array of address components from API service
 * @param {String} type     Type of component to return
 * @return {Float} value    Value of the requested component
 */
export function getDataFromGeocoding(components, type, key) {
  if (key === GOOGLE_ADDRESS_COMPONENTS && _isArray(components)) {
    const type_component = _find(components, component =>
      _get(component, 'types', []).includes(type)
    );
    return typeof type_component !== 'undefined' ? _get(type_component, 'short_name', '') : null;
  }
  if (key === GOOGLE_ADDRESS_GEOMETRY && _isObject(components)) {
    return _get(components, `${type}`, null);
  }
  return null;
}

export function getCurrentModel() {
  return _get(window, 'tesla.omsParams.model', null);
}

export const isPreOrder = () => _get(window, 'tesla.isPreOrder', false);
export const isTDS7ThemeEnabled = () => _get(window, 'tesla.isTDS7ThemeEnabled', false);
export const isPriceAcceptance = () => window.tesla?.priceAcceptance?.pending ?? false;

export function getCustomDisabled(option = {}, flag = 'disable_override') {
  const override = option[flag];
  if (!override) {
    return;
  }
  let result = flag === 'hidden' ? override.isHidden : override.isDisabled;
  if (_has(override, 'check_selected')) {
    result = _get(override, 'selected', false);
  }
  return result ? _get(override, 'content', result) : false;
}

export function getUiOptionsNotInConfiguration(state, options = []) {
  const configuration = options.length ? options : state.Configuration.option_codes;
  const customGroups = state.CustomGroups;
  const lexiconOptions = state?.OMS?.lexicon?.options || {};
  return _reduce(
    customGroups,
    (unselectedOptions = [], groups) => {
      if (groups.code) {
        _forEach(groups.options, groupOption => {
          if (lexiconOptions[groupOption] && !(_indexOf(configuration, groupOption) > -1)) {
            unselectedOptions.push(groupOption);
          }
        });
      }
      return unselectedOptions;
    },
    []
  );
}

export function getDisclaimerOverride(options, configuration) {
  return _reduce(
    options,
    (res, option) => {
      let disclaimerDetails = _get(option, 'disclaimer_details_override');
      disclaimerDetails =
        _isArray(disclaimerDetails) && disclaimerDetails.length
          ? disclaimerDetails[0]
          : disclaimerDetails;
      const conditionState2 = _get(disclaimerDetails, 'condition_state', 'and');
      const indicatorArr = !!_intersection(
        _get(disclaimerDetails, 'configuration', []),
        configuration
      ).length;
      const indicatorOption = conditionState2 === 'and' ? indicatorArr : !indicatorArr;
      if (!res && indicatorOption) {
        return _get(disclaimerDetails, 'content', true);
      }

      return res;
    },
    false
  );
}

export function getLoadedRN() {
  return _get(window.tesla, 'rn', '') !== '';
}

export function getLoadedVin() {
  return _get(window.tesla, 'vin', '') !== '';
}

export const getAvailableContextTypes = () => {
  const { secondaryContext = '' } = window?.tesla || {};
  const { showMatrixContext = true } = window?.tesla?.App || {};
  const lexiconCopyContext = showMatrixContext ? COMPONENT_CONTEXT_MATRIX : '';
  const contextTypes = [COMPONENT_CONTEXT_ENTERPRISE, COMPONENT_CONTEXT_CONFIGURATOR, COMPONENT_CONTEXT_INVENTORY, COMPONENT_CONTEXT_SEEKRET];
  const contextName = secondaryContext || lexiconCopyContext;
  if (contextName) {
    contextTypes.push(contextName);
  }
  return contextTypes;
}

/**
 * Parse selectedBy rules based on options array provided
 * @param  {Object} selected_by set of rules
 * @param  {Array} options configuration
 * @return {Boolean} matches rules or does not
 * @return {appState} reducer state
 */
export function parseSelectedBy(selected_by, options, availableLayouts = ['desktop'], appState = {}) {
  function onDevice(parameter) {
    return !!parameter.some(e => availableLayouts.includes(e));
  }

  function parsePrice(parameter) {
    const { path } = parameter || {};
    const target = _get(appState, path, 0);
    return matchesTargetValue(target, parameter, appState);
  }

  function parseContext(parameter) {
    const contextTypes = getAvailableContextTypes();
    return !!parameter?.some(e => contextTypes.includes(e));
  }

  function parseLocation(parameter) {
    const regionCode = _get(appState, 'SummaryPanel.region_code', '');
    return _isArray(parameter) ? parameter.includes(regionCode) : parameter === regionCode;
  }

  const isSelectedBy = _filter(selected_by, (parameters, gate) => {
    switch (gate) {
      case 'not':
        return !_reduce(
          parameters,
          (result, parameter) => {
            if (_isObject(parameter)) {
              if (parameter.and) {
                return result || includesCodes(parameter.and, options);
              }
              if (parameter.or) {
                return result || includesAnyCode(parameter.or, options);
              }
              if (parameter.not) {
                return result || !includesAnyCode(parameter.not, options);
              }
              if (parameter.on) {
                return result || onDevice(parameter.on);
              }
              if (parameter.price) {
                return result && parsePrice(parameter.price);
              }
              if (parameter.location) {
                return result && parseLocation(parameter.location);
              }
              if (parameter.context) {
                return result || parseContext(parameter.context);
              }
            }
            return result || includesCodes([parameter], options);
          },
          false
        );

      case 'and':
        return _reduce(
          parameters,
          (result, parameter) => {
            if (_isObject(parameter)) {
              if (parameter.and) {
                return result && includesCodes(parameter.and, options);
              }
              if (parameter.or) {
                return result && includesAnyCode(parameter.or, options);
              }
              if (parameter.not) {
                return result && !includesAnyCode(parameter.not, options);
              }
              if (parameter.on) {
                return result && onDevice(parameter.on);
              }
              if (parameter.price) {
                return result && parsePrice(parameter.price);
              }
              if (parameter.location) {
                return result && parseLocation(parameter.location);
              }
              if (parameter.context) {
                return result && parseContext(parameter.context);
              }
            }
            return result && includesCodes([parameter], options);
          },
          true
        );
      case 'or':
        return _reduce(
          parameters,
          (result, parameter) => {
            if (_isObject(parameter)) {
              if (parameter.and) {
                return result || includesCodes(parameter.and, options);
              }
              if (parameter.or) {
                return result || includesAnyCode(parameter.or, options);
              }
              if (parameter.not) {
                return result || !includesAnyCode(parameter.not, options);
              }
              if (parameter.on) {
                return result || onDevice(parameter.on);
              }
              if (parameter.price) {
                return result && parsePrice(parameter.price);
              }
              if (parameter.location) {
                return result && parseLocation(parameter.location);
              }
              if (parameter.context) {
                return result || parseContext(parameter.context);
              }
            }
            return result || includesAnyCode([parameter], options);
          },
          false
        );
      default:
        return true;
    }
  });

  return !!isSelectedBy.length;
}

/**
 * Get all the applicable extra incentives
 * @param {Array} incentives array of strings or objects with selectedBy config
 * @param {Array} options configuration
 * @param {String} registrationType the current registration type
 * @return {Array} the extra incentives that apply to the current config
 */
export function getApplicableExtraIncentives({
  incentives,
  options = [],
  registrationType,
  governmentIncentivesTotal,
  registrationTax,
  showZeroRegistrationTax,
  winterTireFee,
  governmentGrant,
  financeProductId,
  sellerDiscount,
}) {
  return incentives.reduce((applicableIncentives, currentIncentive) => {
    if (
      _isString(currentIncentive) &&
      !(
        [INCENTIVE_PLUGIN_GRANT, INCENTIVE_E_MOBILITY_BONUS].includes(currentIncentive) &&
        Math.abs(governmentIncentivesTotal) === 0
      ) &&
      !(currentIncentive === TAX_REGISTRATION && Math.abs(registrationTax) === 0) &&
      !(currentIncentive === TAX_WINTER_TIRE_FEE && Math.abs(winterTireFee) === 0) &&
      !(currentIncentive === INCENTIVE_SELLER_DISCOUNT && Math.abs(sellerDiscount) === 0)
    ) {
      applicableIncentives.push(currentIncentive);
    } else if (
      !_isEmpty(currentIncentive.selectedBy) &&
      parseSelectedBy(currentIncentive.selectedBy, options)
    ) {
      applicableIncentives.push(currentIncentive.key);
    } else if (
      !_isEmpty(currentIncentive.registrationType) &&
      currentIncentive.registrationType === registrationType &&
      !(currentIncentive?.key === INCENTIVE_GOVERNMENT_GRANT && Math.abs(governmentGrant) === 0) &&
      !(
        Array.isArray(currentIncentive.hideFor) &&
        currentIncentive.hideFor.includes(financeProductId)
      )
    ) {
      applicableIncentives.push(currentIncentive.key);
    } else if (
      _isString(currentIncentive) &&
      currentIncentive === TAX_REGISTRATION &&
      Math.abs(registrationTax) === 0 &&
      showZeroRegistrationTax === true
    ) {
      applicableIncentives.push(currentIncentive);
    }

    return applicableIncentives;
  }, []);
}

/**
 * Determine which keys in records are required in the filter
 * @param  {Array} arr
 * @param  {Object} params [params to filter values]
 * @return {Object}   object to use as loadash find query
 */
const getFilterKeys = (arr, params) => {
  const filter = {};
  if (arr && arr.length) {
    arr.forEach(item => {
      Object.keys(item).forEach(key => {
        if (key !== 'value' && params[key]) {
          filter[key] = params[key];
        }
      });
    });
    return filter;
  }
  return {};
};

/**
 * Lookup matching value in a certain matrix.
 * @param matrix
 * @param filter
 */
export function findMatrixValue(matrix, filter = {}) {
  const filterKeys = getFilterKeys(matrix, filter);
  const composeChain = _flow([_find, value => (value ? value.value : [])]);
  return composeChain(matrix, filterKeys);
}

export function getCleanEddData() {
  const { eddData } = window.tesla;
  return eddData.map(obj => {
    return {
      ...obj,
      options: obj.options.map(opt => opt.replace(/\s/g, '')),
    };
  });
}

export function showLinkToInventoryPerRegion(state, showLinkToInventoryLDData) {
  const regionName = getCurrentDeliveryRegion(state) || 'other';
  return regionName ? _get(showLinkToInventoryLDData, `${regionName}`, false) : false;
}

/**
 * Get Delivery timing from lexicon for the given trimcode
 */
export function getDeliveryDateFromLexicon(deliveryContent, trimCode) {
  if (!deliveryContent) {
    return '';
  }
  const result = _find(deliveryContent.content, ['selected_by.and[0]', trimCode]);
  return _get(result, 'delivery_description', '');
}

/**
 * Map credit card details from the injection to the correct structure to consume in the reducer.
 *
 * @param creditCardDetail object
 */
export function getPaymentDetailsMapped(creditCardDetail) {
  const { PaymentDetail = {}, CurrencyCode, CountryCode, BillingZipCode = null } = creditCardDetail;
  const {
    PaymentAmount = 0,
    PayorName,
    PayorIDNumber = null,
    VerificationPhone = null,
    VerificationSMSCode = null,
  } = PaymentDetail;

  return {
    PaymentAmount,
    PayorName,
    BillingZipCode,
    PaymentSource: PAYMENT_SOURCE_RESERVATION,
    CurrencyCode,
    CountryCode,
    CreditCardDetail: {
      AgreedToSaveProfile: true,
    },
    PayorIDNumber,
    VerificationPhone,
    VerificationSMSCode,
    AgreementSave: null,
  };
}
/**
 * Get Image Props needed for Autopilot to display on mobile
 *
 * @param group object
 */
export function getImgPropsForMobile(group) {
  const imgContent = _get(group, 'asset.image_content', {});
  return _get(imgContent, 'selected', false) ? imgContent : {};
}

export function wechatPay(payload) {
  return new Promise((resolve, reject) => {
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest',
      payload,
      // {
      //   appId: 'this.payOption.appId',
      //   timeStamp: 'this.payOption.timeStamp',
      //   nonceStr: 'this.payOption.nonceStr',
      //   // prepay_id用等式的格式
      //   package: 'this.payOption.package',
      //   // 微信签名方式：
      //   signType: 'this.payOption.signType',
      //   // 微信签名
      //   paySign: 'this.payOption.paySign',
      // },
      res => {
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          resolve(res);
        } else if (res.err_msg !== 'get_brand_wcpay_request:cancel') {
          reject(res.err_msg);
        }
      }
    );
  });
}

/**
 * Get Registration & Delivery Disclaimer
 *
 * @param {object } vehicle
 * @returns string
 */
export const getDeliveryAndRegistrationDisclaimer = ({ state, vehicle }) => {
  const {
    App: { showRegistrationAndDeliveryDisclaimer },
  } = state;

  if (showRegistrationAndDeliveryDisclaimer && vehicle) {
    const { StateProvince } = vehicle;

    if (StateProvince) {
      return i18n('DeliveryLocation.delivery_registration_disclaimer', {
        StateProvince,
      });
    }

    return null;
  }

  return null;
};

/**
 * Function to check for if any model specific translation is presnt
 * @param string i18nKey e.g: `common.ui`, `financeOptions.cash`
 * @param object modelCode
 * @return boolean
 */
export function hasModelSpecificTranslation(i18nKey, modelCode) {
  return (
    i18n(`${i18nKey}.${modelCode}`, null, null, {
      returnNullWhenEmpty: true,
    }) !== null
  );
}

/**
 * Function to return i18n translation for model specific if present
 * @param string i18nSource e.g: `common.ui`, `financeOptions.cash`
 * @param string modelCode
 * @return string i18n transaltion string
 */
export function geti18nModelSpecificTranslation(source, modelCode) {
  return hasModelSpecificTranslation(source, modelCode)
    ? i18n(`${source}.${modelCode}`)
    : i18n(`${source}`);
}

/**
 * Get Order page disclaimer key
 * @param string disclaimerkey
 * @param object disclaimerObj
 * @return string - i18n key for order page disclaimer
 */
export function getOrderDisclaimerSource(disclaimerKey, modelCode, disclaimerObj = {}) {
  const {
    orderDisclaimerCash,
    loanType,
    modifyOrder,
    showNonRefundPolicy,
    rnLoaded,
    vinLoaded,
    showSecondDepositDisclaimer,
    hasBTCDisclaimer,
  } = disclaimerObj;
  const disclaimer_key = modifyOrder ? 'modifyOrderDisclaimer' : disclaimerKey;
  let disclaimerSrc;
  switch (disclaimer_key) {
    case 'preOrderDisclaimer':
      return 'preOrderPlacementAgreement';
    case 'orderDisclaimer':
      disclaimerSrc = 'orderPlacementAgreement';
      break;
    case 'AU_OrderDisclaimer':
      disclaimerSrc = orderDisclaimerCash
        ? 'orderPlacementAgreementCash'
        : `orderPlacementAgreement__${loanType}`;
      break;
    case 'modifyOrderDisclaimer':
      disclaimerSrc = 'orderModifyAgreement';
      break;
    case 'umweltDisclaimer':
      disclaimerSrc = 'orderPlacementAgreementwithBonus';
      break;
    case 'orderDisclaimerShort':
      disclaimerSrc = 'orderPlacementAgreement__short';
      break;
    case 'orderPaymentRefund':
      disclaimerSrc = showNonRefundPolicy ? 'orderPaymentNotRefundable' : 'orderPaymentRefundable';
      break;
    case 'orderRefundLong':
      disclaimerSrc = 'orderRefundLong';
      break;
    case 'orderNotRefundDeposit':
      disclaimerSrc = 'orderNotRefundDeposit';
      break;
    case 'orderRefundShort':
      disclaimerSrc = showNonRefundPolicy ? 'orderNotRefundShort' : 'orderRefundShort';
      return geti18nModelSpecificTranslation(
        `SummaryPanel.disclaimers.${disclaimerSrc}`,
        modelCode
      );
    case 'orderPaymentSecond':
      disclaimerSrc = i18n(
        `SummaryPanel.disclaimers.${rnLoaded ? 'orderSecondPayment' : 'orderPaymentSecRefundable'}`
      );
      if (_isObject(disclaimerSrc)) {
        disclaimerSrc = _get(
          disclaimerSrc,
          `${vinLoaded ? TYPE_INVENTORY : TYPE_CONFIGURATOR}`,
          ''
        );
      }
      return showSecondDepositDisclaimer ? _get(disclaimerSrc, `${modelCode}`, '') : disclaimerSrc;
    default:
      disclaimerSrc = 'orderPlacementAgreement';
      break;
  }

  const i18nKey = `SummaryPanel.disclaimers.${disclaimerSrc}`;
  return hasModelSpecificTranslation(i18nKey, modelCode)
    ? `${disclaimerSrc}.${modelCode}${hasBTCDisclaimer ? '_btc' : ''}`
    : i18n(i18nKey, null, null, { returnNullWhenEmpty: true });
}

/**
 * Get memoized results of a func
 *
 * @param function  fn
 * @param mixed     params
 *
 * @return mixed Returns results from a func or cache
 */
export const memoize = (fn, params = null) => {
  if (typeof fn !== 'function') {
    return fn;
  }
  return (...args) => {
    const key = params || args;
    const n = `${fn.name}:${JSON.stringify(key)}`;
    if (_has(memoCache, n)) {
      return memoCache[n];
    }
    const result = fn(...args);
    memoCache[n] = result;
    return result;
  };
};

/**
 * Get image alt description from Compositor state.
 *
 * @param state
 * @param view {String}
 * @returns {String}
 */
export const getCompositorViewDescription = (state, view) => {
  if (view === '') {
    return '';
  }
  return _get(state, `Compositor.views.${view}.image_alt_description`, '');
};

/**
 * Parse groupSelectedBy rules based on options array provided
 * @param  {Object} group_selected_by set of rules
 * @param  {Object} CustomGroups groups
 * @return {Boolean} matches rules or does not
 */
export function parseGroupSelectedBy(group_selected_by = {}, CustomGroups) {
  const arr = Object.entries(group_selected_by);
  if (!arr.length) {
    return false;
  }
  for (const [gate, parameter] of arr) {
    switch (gate) {
      case 'and': {
        return parameter.reduce((result, group) => {
          return result && CustomGroups[group].selected;
        }, true);
      }
      case 'or': {
        return parameter.reduce((result, group) => {
          return result || CustomGroups[group].selected;
        }, false);
      }
      case 'not': {
        return !parameter.reduce((result, group) => {
          return result || CustomGroups[group].selected;
        }, false);
      }
      default:
        return true;
    }
  }
}

export const isFSD = trimCode => {
  const basicAutopilot = ['$APPB', '$APBS'];
  const fsd = '$APF2';
  return !basicAutopilot.includes(trimCode) && trimCode === fsd;
};

/**
 * This is used for TTP strings which have modal trigger parts within a sentence
 * We want to stick to using actual buttons here than adding anchor tags in TTP strings
 * This helps with accessibility and use ModalTrigger component which ensures button rendering
 *
 * @param {string} startKey
 * @param {string} endKey
 * @param {string} inputString
 * @returns Object with 3 string parts
 */
export const buttonStringExtractor = (
  inputString,
  startKey = BUTTON_START,
  endKey = BUTTON_END
) => {
  // /(?:{BUTTON_START})(.*)(?:{BUTTON_END})/;
  const buttonStringRegex = new RegExp(`(?:${startKey})(.*)(?:${endKey})`);
  // /.+?(?={BUTTON_START})/
  const firstPartRegex = new RegExp(`.+?(?=${startKey})`);
  // /(?:{BUTTON_END})(.*)$/
  const lastPartRegex = new RegExp(`(?:${endKey})(.*)$`);

  const firstPartTest = firstPartRegex.exec(inputString);
  const buttonPartTest = buttonStringRegex.exec(inputString);
  const lastPart = lastPartRegex.exec(inputString);

  return {
    firstPart: firstPartTest ? firstPartTest[0] : null,
    buttonPart: buttonPartTest ? buttonPartTest[1] : null,
    lastPart: lastPart ? lastPart[1] : null,
  };
};

export const getVehicleType = model => {
  const { MODEL_S, MODEL_X, MODEL_Y, MODEL_3 } = Models;
  if (model === MODEL_S || MODEL_3) {
    return 'sedan';
  } else if (model === MODEL_X || model === MODEL_Y) {
    return 'suv';
  }
};

export const getUpgradeOptionPrice = (upgrades, optionCode) => {
  let res = {};
  if (!upgrades.length || !optionCode) {
    return res;
  }
  upgrades.forEach(opt => {
    const { current = [] } = opt;
    const result = current.find(x => x.code === optionCode);
    if (result) {
      res = {
        basePriceFormatted: result.formattedPrice || '',
        basePrice: result.price || 0,
        extraPrice: result.extraPrice || null,
      };
    }
  });
  return res;
};

/**
 * Check if market is EU.
 *
 * @param {string} market
 * @returns bool Returns if is EU market.
 */
export const isEU = market => {
  return getSuperRegion(market)?.code === REGION_EU;
};

/**
 * Check if market is ME.
 *
 * @param {string} market
 * @returns bool Returns if is ME market.
 */
export const isME = market => {
  return getSuperRegion(market)?.code === REGION_ME;
};

/**
 * Check if market is EMEA.
 *
 * @param {string} market
 * @returns bool Returns if is EMEA market.
 */
export const isEMEAMarket = market => {
  return isEU(market) || isME(market);
};

/**
 * Check if market is APAC.
 *
 * @param {string} market
 * @returns bool Returns if is APAC market.
 */
export const isAPACMarket = market => {
  return getSuperRegion(market)?.code === REGION_AP;
};

export const getShowVehicleDetailsBlock = (countryCode, displayFields, regDetails) => {
  return countryCode === 'KR' || (!_isEmpty(regDetails) && !_isEmpty(displayFields))
    ? displayFields
    : false;
};

/**
 * Get state code from a possible full name
 */
export const getStateCodeFromFullname = (market, region) => {
  const states = getStates(market);
  if (states && region && region !== market) {
    const { code } = states.find(x => x.label === region) || {};
    const result = code || region.split('-')[0];
    return result ? result.replace(/\s/g, '') : '';
  }
  return '';
};

/**
 * return list of fleet sales region code
 */
export const getFleetSalesRegionCode = (market, region) => {
  if (market === 'NZ') {
    let result = region.charAt(0).toLowerCase() + region.slice(1);
    return result ? result.replace(/\s/g, '') : '';
  }
  return getStateCodeFromFullname(market, region);
};

/**
 * Return list of fleet sales region code
 * Filter Sales Region Mapping for AU
 *
 * @param {object} vehicle product
 * @returns {object}
 */
export const filterSalesRegion = () => {
  const { product, isFalconDeliverySelectionEnabled } = window.tesla || {};
  const { deliveryRegion = '', data } = product || {};
  const { FleetSalesRegions = [], CountryCode: market = '', VehicleRegion } = data || {};

  let fleetSalesRegionsArray = null;
  const useFullName = market === 'AE';
  const filteredRegionArr = FleetSalesRegions.filter(region => region !== market);
  let labelMap = {};
  const regionArr = filteredRegionArr.reduce((r, region) => {
    const resCode = getFleetSalesRegionCode(market, region);
    const resLabel = resCode ? region : '';
    const res = useFullName ? resLabel : resCode;
    if (res && !r.includes(res)) {
      r.push(res);
      labelMap[res] = region;
    }
    return r;
  }, []);

  fleetSalesRegionsArray = regionArr.length > 0 ? regionArr : filteredRegionArr;
  const fleetSalesRegions = fleetSalesRegionsArray.map(r => ({
    label: labelMap[r] || r,
    value: r,
  }));

  const regionsList = isFalconDeliverySelectionEnabled?.enabled ? getTerritoryCodeList(market) : fleetSalesRegions;
  const isDeliveryAvailable = regionsList?.find(
    reg => reg?.label === deliveryRegion || reg?.value === deliveryRegion
  )?.value;
  let regionForDelivery = isDeliveryAvailable || regionsList?.[0]?.value;
  regionForDelivery = useFullName
    ? labelMap[regionForDelivery] || regionForDelivery
    : regionForDelivery;

  return {
    showLocationSelection: !isDeliveryAvailable && regionsList?.length > 1,
    fleetSalesRegions,
    fleetSalesRegionsArray: filteredRegionArr,
    deliveryRegion: regionForDelivery || regionsList?.[0]?.value,
    vehicleRegion: VehicleRegion,
  };
};

/**
 * Get object key by value
 *
 * @param {object} Object
 * @returns {string} Object key value
 */
export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

/**
 * Get lexicon multiple currency values from metadata and filter
 * @param {object} state
 * @returns {string} Object primaryCurrency, secondaryCurrency, factor
 */
export const convertMultipleCurrency = state => {
  const conversionRateData = state?.OMS?.lexicon?.metadata?.copy?.conversion_rate;
  if (!conversionRateData) return { show: false };

  const primaryCurrency = Object.keys(conversionRateData).find(currency => Boolean(currency));
  const [secondaryCurrency, factor] = Object.entries(
    conversionRateData[primaryCurrency] || {}
  ).find(entry => Boolean(entry));

  return {
    primaryCurrency,
    secondaryCurrency,
    factor,
  };
};

/*
 * Get image alt description from Compositor state.
 *
 * @param state
 * @param view {String}
 * @returns {String}
 */
export const getCompositorAltMap = (state, view) => {
  if (view === '') {
    return '';
  }
  return _get(state, `Compositor.views.${view}.image_alt_key`, '');
};

/**
 * return list of territory Code
 */
export function getTerritoryCodeList(market) {
  const states = getStates(market);
  if (!states) return [];

  return states.map(region => ({
    label: region.code,
    value: region.code,
  }));
}

/**
 * return list of territory Code
 */
export function getTerritoryNameList(market, language, { useLabelForValue = false } = {}) {
  const states = getStates(market, language);
  if (!states) return [];

  return states.map(region => ({
    label: region.label,
    value: useLabelForValue ? region.label : region.code,
  }));
}

/*
 * Get state list by given countryCode @see CountryAddressList
 * @param countryCode {String} country code
 * @return {Object}
 */
export const getStateList = countryCode => {
  const supportsStates = ['AU', 'CA', 'CN', 'JP', 'US'];
  if (supportsStates.includes(countryCode)) {
    return countryStateList[countryCode];
  }
  return {};
};

/**
 * Get address object which may contains states/cities/districts
 * @param countryCode {String} country code
 * @return {object}
 */
export const getAddressByCountryCode = countryCode => {
  return countryStateList[countryCode] || {};
};

/*
 * Get Default config object for describing springs, which are used to animate
 *
 * @returns {Object}
 */
export const getDefaultAnimationSpringConfig = () => ({ stiffness: 170, damping: 26 });

/**
 * Normalize array of objects to be rendered with Select component
 * @param {*} regionsData
 * @returns {array}
 */
export const getCleanRegionList = ({ regionsData }) => {
  if (regionsData.length > 0) {
    const result = regionsData?.reduce((acc, item) => {
      acc.push({
        value: item['RegionCode'],
        label: item['DisplayName'],
      });
      return acc;
    }, []);

    return result;
  }
  return [];
};

/*
 * Check if payment is accepted (against core -> payment service)
 *
 * @returns {Object}
 */
export async function isPaymentAccepted({ routes, rn, transactionNumber }) {
  const { postPaymentDetails } = routes;
  if (!postPaymentDetails) {
    return false;
  }
  const response = await request
    .post(postPaymentDetails)
    .send({
        referenceNumber: rn,
        transactionNumber,
      }
    )
    .set('X-Requested-With', 'XMLHttpRequest')
    .set('Accept', 'application/json');
  const { body = {} } = response;
  return _get(body, 'isPaymentAccepted', false);
}

export async function getLatestTrimPriceInfo({ routes, market, language, model, trimCode }) {
  const { loadLatestTrimPrice } = routes;
  if (!loadLatestTrimPrice) {
    return false;
  }
  const response = await request.post(loadLatestTrimPrice).send(
    {
      market: market,
      language: language,
      model: model,
      trimCode,
    }
  );

  const { body = {} } = response;
  return body || false;
}

/**
 * Get locales list filtered by available locales
 * @param data {Array} Locale selector data
 * @param locales {Array} Array of available locales
 *
 * @return {Array}
 */
export const getLocalesListByAvailable = (data, locales = []) => {
  if (!locales || !locales.length) {
    return [];
  }
  return data.reduce((res, item) => {
    const regionsFiltered =
      (item.regions &&
        item.regions.reduce((r, reg) => {
          const regionFiltered = reg.countries.reduce((a, country) => {
            const langFiltered = country.languages.filter(lang =>
              locales.includes(lang.localeCode)
            );
            if (langFiltered.length) {
              a.push({
                ...country,
                languages: langFiltered,
              });
            }
            return a;
          }, []);
          if (regionFiltered.length) {
            r.push({
              ...reg,
              countries: regionFiltered,
            });
          }
          return r;
        }, [])) ||
      [];
    if (regionsFiltered.length) {
      res.push({
        ...item,
        regions: regionsFiltered,
      });
    }
    return res;
  }, []);
};

/**
 * Check if region code is allowed (loose validation against being empty if required).
 *
 * @param  object { regionCode countryCode }
 *
 * @return bool return valid or invalid.
 */
export function isRegionCodeValid({ regionCode, isCountryWithStateCode }) {
  if (!isCountryWithStateCode) {
    return true;
  }
  return !!regionCode;
}

/**
 * Check if postal code is valid (loose validation against being empty if required).
 *
 * @param  object { regionCode countryCode }
 *
 * @return bool return valid or invalid.
 */
export function isPostalCodeValid({ postalCode, isNoPostalCodeMarket }) {
  if (isNoPostalCodeMarket) {
    return true;
  }
  return !!postalCode;
}

/**
 * Get normalized shipping address
 *
 * @param  object address
 *
 * @return object normalized shipping address.
 */
export function validateShippingAddress({
  address,
  countryCode,
  isNoPostalCodeMarket,
  isCountryWithStateCode,
}) {
  if (_isEmpty(address)) {
    return null;
  }
  const {
    street2: shippingAddress2 = '',
    city: shippingAddressCity,
    state: shippingAddressState = '',
    street: shippingAddressStreet = '',
    zipCode: shippingAddressZipCode,
    countryCode: shippingCountryCode,
    district = '',
    address2 = '',
    stateProvince = '',
  } = address;
  const nShippingCountryCode = (shippingCountryCode || '').toUpperCase();
  const { postalCode: nShippingPostalCode, postalCodeExtension: nShippingPostalCodeExt } =
    getZipWithExtension({
      postalCode: shippingAddressZipCode,
      countryCode: nShippingCountryCode,
    }) || {};
  const nShippingAddressState = (shippingAddressState || stateProvince || '').toUpperCase();
  const isPOBox =
    (shippingAddressStreet && containsPOBoxAddress(shippingAddressStreet)) ||
    (shippingAddress2 && containsPOBoxAddress(shippingAddress2));
  if (
    !nShippingCountryCode ||
    !isPostalCodeValid({ postalCode: nShippingPostalCode, isNoPostalCodeMarket }) ||
    !isRegionCodeValid({ regionCode: nShippingAddressState, isCountryWithStateCode }) ||
    isPOBox ||
    nShippingCountryCode !== countryCode
  ) {
    return {
      error: ERROR_INVALID_SHIPPING_ADDRESS,
    };
  }
  let overrideAddress = {};
  // COIN-6739: Remap PR for commerce
  if (nShippingCountryCode === 'PR') {
    overrideAddress = { country: 'US', state: 'PR' };
  }
  return {
    street1: shippingAddressStreet,
    street2: shippingAddress2 || address2,
    city: shippingAddressCity,
    district,
    state: nShippingAddressState,
    country: nShippingCountryCode,
    postalCode: nShippingPostalCode,
    postalCodeExt: nShippingPostalCodeExt,
    ...overrideAddress,
  };
}

export const getShipFromAddress = data => {
  if (!data) {
    return {};
  }
  let res = {};
  Object.keys(data)?.forEach(key => {
    res[key] = data?.[key]?.shipFromAddress || {};
  });
  return res;
};

/**
 * Call to get tax data
 * @param  state
 * @param  props
 * @param  dispatch
 * @return {number}  taxData
 */
export const getTaxData = async (state, props = {}) => {
  const isTaxInclusiveMarket = state ? isTaxInclusive(state) : _get(props, 'isTaxInclusive', false);
  if (isTaxInclusiveMarket || (state && !hasShippingAddress(state))) {
    return {
      taxAmount: undefined,
    };
  }
  const { taxUrl = '', taxPayload = null } = props;
  const url = taxUrl || getCommerceTaxEndpoint(state);
  const payload = taxPayload || getCommerceTaxPayload(state);
  // Remap PR back to country for tax call only
  let extraObj = {};
  const { countryCode, deliveryAddress = {} } = payload;
  if (countryCode === 'US' && deliveryAddress?.state === 'PR') {
    extraObj = {
      countryCode: 'PR',
      deliveryAddress: {
        ...deliveryAddress,
        country: 'PR',
      },
    };
  }
  let taxData = {};
  try {
    const timeout = 1000 * 15;
    const res = await request
      .post(url)
      .timeout({ response: timeout, deadline: timeout })
      .retry(2)
      .send({ ...payload, ...extraObj });
    const { body = {} } = res;
    const { taxAmount = null, signature = '', signaturePayload = '' } = body;
    const normalizedAmount = parseFloat(taxAmount);
    if (!signature || !signaturePayload || Number.isNaN(normalizedAmount)) {
      console.warn('Failed to get tax data');
      taxData = { taxAmount: undefined, error: true };
    } else {
      taxData = { taxAmount: normalizedAmount, signature, signaturePayload };
    }
  } catch (err) {
    console.warn('Failed to get tax data with err', err.message);
    taxData = { taxAmount: undefined, error: true };
  }
  return taxData;
};

/**
 * Normalizing values in the form.
 *
 * @param {string} market
 * @returns bool isNorthAmerica
 */
export const isNAMarket = market => {
  return getSuperRegion(market)?.code === REGION_NA;
};

/**
 * Phone validator.
 *
 * @param {string} emailValue
 * @param {string} market
 */

export const phoneValidator = (value, market) => {
  /*eslint-disable */
  const phoneNumberRegEx = /^[0-9\+\(\)\[\]\s\.\-\#\*]+$/;

  const error__minLen = min => i18n('common.errors__minlength', { MINLENGTH: min });
  if (market === 'CN') {
    const formatted = String(value).replace(/\D/g, '');
    if (value && formatted.length < 8) {
      return error__minLen(8);
    } else if ((value && formatted.length > 15) || (value && !phoneNumberRegEx.test(value))) {
      return i18n('common.errors__phonenumberNotValid');
    } else {
      return;
    }
  } else {
    if (value && value.length < 8) {
      return error__minLen(8);
    }
    return value && !phoneNumberRegEx.test(value)
      ? i18n('common.errors__phonenumberNotValid')
      : undefined;
  }
};

/**
 * Zip Code validator.
 *
 * @param {string} zipCode
 * @param {string} market
 * @returns bool isValid
 */

export const zipCodeValidator = (zipCode, countryCode) => {
  if (zipCode && !validatePostalCode(zipCode, { countryCode })) {
    return i18n('common.errors__invalidZipCode');
  }
  return;
};

/**
 * Get delivery location payload details
 *
 * @param {object} state
 * @returns object payload
 */
export const getDeliveryLocationDetails = state => {
  const { postal, range } = state?.App?.query_params || {};
  const { CurrencyCode } = state?.Payment || {};
  const {
    DeliveryLocations,
    DeliveryDetails,
    vehicleDesign: { isEarlyDesignSelected = false, swapConfig = {} } = {},
    rangeRestrictedSalesMetro,
  } = state.ReviewDetails || {};
  const { selected: location, params, registrationZipCode, filtered = [] } =
    DeliveryLocations || {};
  const { SourceType, stateCode = '', PostalCode = '' } = DeliveryDetails || {};

  if (isEarlyDesignSelected) {
    const { Geolocation, Trt } = swapConfig || {};
    const coords = Geolocation?.split(',');

    return {
      deliveryLocationSelectionDetails: {
        searchZipCode: PostalCode,
        locationId: Trt,
        locationDetails: { latitude: coords?.[0], longitude: coords?.[1] },
        isSwap: true,
      },
    };
  }

  if (!location) {
    const { isDeliverySelectionEnabled } = state?.App || {};
    return isDeliverySelectionEnabled
      ? {
          registrationZipCode,
          deliveryLocationSelectionDetails: {
            searchZipCode: postal,
            registrationZipCode,
            version: getFalconDeliverySectionVersion(state),
          },
        }
      : {};
  }

  const { trt_id, transportFee, locationDetails, alternateVRLs, province: locationStateProvince } =
    location || {};
  const { distance, fee } = transportFee || {};
  const { units, province } = params || {};
  const locations = filtered?.map(location => {
    const { title, trt_id, transportFee, location_type, alternateVRLs } = location || {};
    return {
      trt: title,
      trt_id,
      transportFee: transportFee?.fee || 0,
      transportFeeCurrency: CurrencyCode,
      location_type,
      alternateVRLs,
    };
  });
  let normalizedRange = range ? parseInt(range) || null : null;
  normalizedRange = range > 200 ? null : normalizedRange;

  return {
    registrationZipCode,
    deliveryLocationSelectionDetails: {
      searchZipCode: postal,
      alternateVRLs,
      range: rangeRestrictedSalesMetro ? normalizedRange : null,
      locationId: trt_id,
      locationStateProvince,
      pickUpType: PICKUP_SERVICE_CENTER,
      distanceSource: SourceType,
      distanceType: units,
      distanceMove: distance,
      estimatedTransportationFee: fee,
      registrationRestrictionStates: getRegistrationRestrictionStates(state),
      registrationZipCode,
      registrationState: province || stateCode,
      locations,
      onSiteSale: isVehicleOnSite(state),
      locationDetails,
      version: getFalconDeliverySectionVersion(state),
    },
  };
};

/**
 * Check if in App Environment
 * @returns {boolean}
 */
export const isInAppEnv = () => window?.ReactNativeWebView !== undefined;

/**
 * Check if CountryHasVehicleAtLocation should be used
 * @param country {String} country code
 * @param model {String} model
 * @returns {boolean}
 */
export const getUseCountryHasVehicleAtLocation = ({ CountryCode }, isUsedInventory) => {
  return isEMEAMarket(CountryCode) && !isUsedInventory;
};

/**
 * Get Delivery Zipcode
 * @returns {string}
 */
export const getDeliveryZipCode = state => {
  const { ReviewDetails, Location } = state;
  const { sources = {} } = Location;
  const { geoIp = {} } = sources;
  const { postalCode: geoPostalCode = '' } = geoIp;

  const { DeliveryDetails } = ReviewDetails;
  const { PostalCode: zipCode } = DeliveryDetails || {};

  return zipCode === geoPostalCode || _isEmpty(zipCode) ? geoPostalCode : zipCode;
};

/**
 * Call to get static address
 * @param  state
 * @param  params
 * @return {object}  address
 */
export async function getStaticAddress(state, params) {
  const { routes: { staticAddressApi } = {}, countryCode, language: languageCode } =
    state?.App || {};
  const { type, id } = params;
  if (!type || !id) {
    return;
  }
  const response = await request
    .get(staticAddressApi)
    .query({ countryCode, languageCode, type, id });
  return response?.body;
}

/**
 * Use for Compare
 * @param state
 */
export function getNormalizedCompareData({
  state,
  data,
  allOptionsList,
  upgrades,
  addSpecsFromLexicon,
  hidePostOrderSwapLocation,
}) {
  const {
    OptionCodeData = [],
    PurchasePrice = 0,
    City = '',
    Availability = '',
    TrimCode = '',
    OptionCodeList = '',
  } = data;
  const result = [
    {
      code: getModelCode(state),
      group: 'MODEL',
      long_name: getModelName(state),
    },
    ...OptionCodeData,
  ];

  const formattedUpgrades = upgrades?.map(upgrade => {
    return {
      group: upgrade?.category,
      code: upgrade?.code,
      price: upgrade?.price,
      name: upgrade?.name,
      long_name: upgrade?.long_name,
      description: upgrade?.description,
    };
  });

  if (formattedUpgrades) {
    result.push(...formattedUpgrades);
  }

  let groupMapping = [
    'MODEL',
    'TRIM',
    'PAINT',
    ['INTERIOR', 'INTERIOR_PACKAGE', 'INTERIOR_COLORWAY'],
    'WHEELS',
    ['SEAT', 'REAR_SEATS'],
    'AUTOPILOT',
    'DRIVE_ANYWHERE_PACKAGE',
    'CONNECTIVITY_TRIAL',
    'STEERING_WHEEL',
    ['TOWING', 'TOW_PACKAGE'],
    ['WINTER_WHEELS', 'WINTER_TIRE', 'WINTER_TIRES', 'WINTER_WHEELS_OPTIONS'], // WINTER_WHEElS should stay first to align with TTP
  ];

  // Check to see if vehicle has option listed in groupMapping to display
  groupMapping = groupMapping.filter(itm => {
    if (Array.isArray(itm)) {
      return itm.reduce((acc, x) => acc || allOptionsList?.includes(x), false);
    } else {
      return allOptionsList?.includes(itm);
    }
  });

  let mappedResults = [];
  groupMapping.forEach(group => {
    if (group === 'AUTOPILOT') {
      const autopilotCodes = ['$APBS', 'DA02', 'TP02', '$APD2'];
      const enhancedAutoPilotCodes = ['APPB', '$APPB', '$APF1'];
      const techPackageCodes = ['$FB01'];
      const fsdCodes = ['$APF2'];
      const autoPilot =
        result.find(opt => fsdCodes.includes(opt.code)) ||
        result.find(opt => enhancedAutoPilotCodes.includes(opt.code)) ||
        result.find(opt => autopilotCodes.includes(opt.code));
      mappedResults.push(autoPilot);
    } else {
      let item = null;
      let groupName = group;
      if (Array.isArray(group)) {
        // groups groupMapping array values together by using the same group name, so they show up on the same line
        // i.e. INTERIOR is compared with INTERIOR_PACKAGE
        item = group.reduce((acc, x) => {
          let foundItem = result.find(itm => itm?.group === x);
          if (foundItem) {
            groupName = group[0];
            foundItem = {
              ...foundItem,
              group: group[0],
            };
          }
          return acc || foundItem;
        }, null);
      } else {
        item = result.find(itm => itm?.group === group);
      }

      // Groups that might have different name/descriptions that should show included if available
      const showIncludedGroups = ['DRIVE_ANYWHERE_PACKAGE'];
      if (item && showIncludedGroups?.includes(groupName)) {
        item = {
          ...item,
          long_name: i18n('common.included'),
          longName: i18n('common.included'),
        }
      }
      groupName = Array.isArray(groupName) ? groupName[0] : groupName;

      item
        ? mappedResults.push(item)
        : mappedResults.push({
            code: groupName,
            group: groupName,
            value: '-',
          });
    }
  });

  const trimCode = TrimCode || getTrimCode(state);
  const specsData = _get(state, `CustomGroups.battery_group_specs.options.${trimCode}`, {});
  const specsDataWithOverrides = applySpecsOverrides({
    data: specsData,
    configuration: OptionCodeList,
    App: state?.App,
  });
  const {
    rangeFormatted = '',
    range_units_override,
    topspeedFormatted,
    rawData = {},
    accelerationFormatted = '',
  } = specsDataWithOverrides;
  const rangeLabel = range_units_override?.label
    ? `${range_units_override?.label} ${range_units_override?.content}`
    : range_units_override;
  const range = {
    code: trimCode,
    group: 'SPECS_RANGE',
    value: rangeFormatted || '-',
    label: rangeLabel,
  };
  const topSpeed = {
    code: trimCode,
    group: 'SPECS_TOP_SPEED',
    value: topspeedFormatted || '-',
    label: rawData?.top_speed_units,
  };
  const acceleration = {
    code: trimCode,
    group: 'SPECS_ACCELERATION',
    value: accelerationFormatted || '-',
    label: rawData?.zero_to_road_limit_units,
  };
  mappedResults.splice(2, 0, range, topSpeed, acceleration);
  const customAvailability = getPostOrderSwapCustomAvailability(state);
  const swapVehicleAvailability = customAvailability || i18n('PostOrderSwap.now');
  const availability = Availability || (addSpecsFromLexicon ? '-' : swapVehicleAvailability);
  mappedResults.push(
    {
      code: `availability-${availability}`,
      group: 'AVAILABILITY',
      value: availability,
    },
    {
      code: `city-${City}`,
      group: 'PICKUP_LOCATION',
      value: City || '-',
    }
  );
  if (hidePostOrderSwapLocation) {
    const excludedGroups = ['AVAILABILITY', 'PICKUP_LOCATION'];
    mappedResults = mappedResults.filter(item => !excludedGroups.includes(item.group));
  }
  return mappedResults;
}

export const getCompoundDeliveryLocations = (vrlLocks = []) => {
  const deliveryLocations = Object.values(window?.tesla?.compoundLocations || {});

  if (deliveryLocations?.length) {
    const locations =
      deliveryLocations?.reduce((res, x) => {
        if (x?.isActive && (vrlLocks?.includes(x?.id) || !vrlLocks?.length)) {
          res.push({
            ...x,
            trt_id: x?.trtId,
            title: x?.street1,
            postal_code: x?.postalCode,
            id: `${x?.trtId}`,
          });
        }
        return res;
      }, []) || [];

    return {
      compoundLocations: locations,
      selected: locations?.find(x => x),
    };
  }

  return {};
};

export const hasLHD = (state, props = {}) => {
  const { optionCodes = '' } = props || {};
  const { option_string: config } = state?.Configuration || {};
  const driveCode = state?.ReviewDetails?.product?.data?.DRIVE?.[0] || '';
  return (driveCode || config || optionCodes)?.match(/DRLH/g);
};

export const hasRHD = (state, props = {}) => {
  const { optionCodes = '' } = props || {};
  const { option_string: config } = state?.Configuration || {};
  const driveCode = state?.ReviewDetails?.product?.data?.DRIVE?.[0] || '';
  return (driveCode || config || optionCodes)?.match(/DRRH/g);
};

export const getDeliveryDisclaimer = (data = '') => {
  const city = data?.toUpperCase();

  switch (city) {
    case CITY_DUBAI:
      return i18n('DeliveryLocation.home_delivery_disclaimer');
  }
  return;
};

export const getTaxesAndFeesData = async (url, payload) => {
  let result = {};
  try {
    const response = await request.post(url).send(payload);
    result = response?.body;
  } catch (err) {
    console.warn('Failed to get tax and fees data with err', err.message);
  }
  return result;
};

export const getPreQualData = async (url, payload) => {
  let result = { error: '' };
  const timeout = 1000 * 15;
  try {
    const response = await request
      .post(url)
      .send(payload)
      .timeout({ response: timeout, deadline: timeout });
    result = response?.body;
    const { decision, monthlyPayment } = result || {};
    const amount = monthlyPayment || 0;
    if (decision) {
      const data = { decision, amount };
      const in30Minutes = 1/48;
      Cookie.set('prequal', JSON.stringify(data), { expires: in30Minutes })
    }
  } catch (err) {
    const { message = 'No Data' } = err || {};
    result = { error: message };
    console.warn('Failed to get prequalify results:', message);
  }
  return result;
};

export const getInventorySwapPaymentErrorZip = () => {
  return getQueryParameters()?.inventorySwapPaymentErrorZip ?? '';
}

export const getYearList = (start = 0, interval = 1) => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const startYear = start ? start : (currentYear - 30);
  const length = (currentYear - startYear) / interval + 1;
  return Array.from({ length }, (_, i) => startYear + i * interval);
};

export const getVehiclesByYear = async (url, payload) => {
  let result = { error: '' };
  const timeout = 1000 * 15;
  try {
    const response = await request
      .post(url)
      .send(payload)
      .timeout({ response: timeout, deadline: timeout });
    result = response?.body;
  } catch (err) {
    const { message = 'No Vehicle Data' } = err || {};
    result = { error: message };
  }
  return result;
};

export const getVehicleInfoByVin = async (url, payload) => {
  let result = { error: '' };
  const timeout = 1000 * 15;
  try {
    const response = await request
      .post(url)
      .send(payload)
      .timeout({ response: timeout, deadline: timeout });
    result = response?.body;
  } catch (err) {
    const { message = 'No Vehicle Data' } = err || {};
    result = { error: message };
  }
  return result;
};

export const getTradeInValue = async (url, payload) => {
  let result = { error: '' };
  const timeout = 1000 * 30;
  try {
    const response = await request
      .post(url)
      .send(payload)
      .timeout({ response: timeout, deadline: timeout });
    result = response?.body;
  } catch (err) {
    const { message = 'No Vehicle Valuation Found', response } = err || {};
    const { error } = response?.body || {};
    result = { error: error || message };
  }
  return result;
};

export const setPreferredLocationCookie = (data) => {
  const regionCode = _get(data, 'stateCode', '');
  const postalCode = _get(data, 'postalCode', '');
  if (!data || !regionCode || !postalCode) {
    return;
  }
  const mappedData = {
    ip: _get(data, 'ip', null),
    location: {
      latitude: _get(data, 'latitude', ''),
      longitude: _get(data, 'longitude', ''),
    },
    region: {
      longName: _get(data, 'stateProvince', ''),
      regionCode,
    },
    city: _get(data, 'city', ''),
    county: _get(data, 'county', ''),
    country: _get(data, 'countryName', ''),
    countryCode: _get(data, 'countryCode', ''),
    postalCode,
  };
  document.cookie = `preferred_address=${JSON.stringify(mappedData)}; expires=0; path=/`;
};

export const getTCLBannerContent = data => {

  const slides = data?.reduce((res, itm) => {
    if (itm?.description) {
      const formattedSlides = {
        id: (res?.length + 1).toString(),
        body: itm?.description || '',
        mobileBody: itm?.description || '',
        cta: {
          label: itm?.btnCopy || '',
          href: itm?.href || '',
          target: itm?.target || '',
        },
        modalData: {
          modalTitle: itm?.modalTitle || '',
          modalContent: itm?.modalContent || ''
        },
      }

      const btnBehavior = itm?.btnBehavior?.map(behavior => behavior?.content)
      formattedSlides.btnBehavior = btnBehavior;

      res.push(formattedSlides);
    }
    return res;
  }, [])

  return {
    autoChangeInterval: 7,
    slides,

  }

};
