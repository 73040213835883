import React from 'react';
import { connect } from 'react-redux';
import { Icon } from '@tesla/design-system-react';
import { iconStatusSuccess } from '@tesla/design-system-icons';
import { bool, shape, number, string } from 'prop-types';

import { i18n } from 'utils';
import { isUsedInventory, getReferralCredit, getDiscountsCaptionText, getVeteranCredit, getFinanceProductType } from 'selectors';

const PricingDisclaimers = ({
  hasVerificationDiscount,
  referralCredit,
  purchasePriceDisclaimer,
  discountsDisclaimer,
}) => {
  return (
    <>
      <If
        condition={(!!referralCredit?.credit || hasVerificationDiscount) && discountsDisclaimer}
      >
        <div className="tds-o-flex_no-wrap tds-o-margin_bottom">
          <Icon data={iconStatusSuccess} className="tds-icon--small" />
          <span className="tds--horizontal_padding--small tds-text--caption">
            {discountsDisclaimer}
          </span>
        </div>
      </If>
      <If condition={purchasePriceDisclaimer}>
        <p className="items price review-page--disclaimer tds-text--caption">
          {purchasePriceDisclaimer}
        </p>
      </If>
    </>
  );
};

function mapStateToProps(state) {
  const veteranCredit = getVeteranCredit(state);
  const referralCredit = getReferralCredit(state);
  const discountsDisclaimer = getDiscountsCaptionText(state);
  const { region_code: region } = state?.SummaryPanel || {};
  const { model_code: modelCode = '' } = state?.Configuration || {};
  const productType = getFinanceProductType(state);

  return {
    hasVerificationDiscount: veteranCredit,
    referralCredit,
    discountsDisclaimer,
    purchasePriceDisclaimer: !isUsedInventory(state)
      ? i18n('SummaryPanel.disclaimers.purchasePriceDisclaimer', null, null, {
          specificOverride: [region, modelCode, productType],
          returnNullWhenEmpty: true,
          specificOverrideOperator: 'OR',
        })
      : '',
  };
}

PricingDisclaimers.propTypes = {
  hasVerificationDiscount: bool,
  referralCredit: shape({ credit: number }),
  discountsDisclaimer: string,
  purchasePriceDisclaimer: string,
};

PricingDisclaimers.defaultProps = {
  hasVerificationDiscount: false,
  referralCredit: {},
  discountsDisclaimer: '',
  purchasePriceDisclaimer: '',
};

export default connect(mapStateToProps)(PricingDisclaimers);