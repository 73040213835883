import React from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import _get from 'lodash/get';
import { getModelCode } from 'selectors';
import { i18n, htmlToReact, buttonStringExtractor } from 'utils';
import { BUTTON_START, BUTTON_END } from 'dictionary';
import ModalTrigger from '../../../components/ModalTrigger';

const ContactUsLink = ({ showContactUs, useUsedInventoryDisclaimerCopy, requestCallbackCopy }) => {
  if (!showContactUs) {
    return null;
  }
  const contactusSource = `SummaryPanel.disclaimers.contactus${
    useUsedInventoryDisclaimerCopy ? '_used' : ''
  }`;
  return (
    <>
      <Choose>
        <When condition={useUsedInventoryDisclaimerCopy}>
          <p>
            <span
              role="link"
              tabIndex={0}
              key="request-a-callback"
            >
              <span className="tds-text--caption">
                {requestCallbackCopy.firstPart}
              </span>
              <ModalTrigger
                options={{
                  props: {
                    componentName: 'RequestCallback',
                    props: {
                      genericWrapper: true,
                      size: 'MODAL_SMALL',
                      containerCss: 'tds-display--inline',
                    },
                  },
                }}
              >
                <span className="tds-link tds-text--caption">
                  {requestCallbackCopy.buttonPart}
                </span>
              </ModalTrigger>
              <span>{requestCallbackCopy.lastPart}</span>
            </span>
          </p>
        </When>
        <Otherwise>
          <div className="modifier--tds-link-primary tds-text--caption tds--vertical_padding tds-text--center">
            {htmlToReact(i18n(contactusSource))}
          </div>
        </Otherwise>
      </Choose>
    </>
  );
};

ContactUsLink.propTypes = {
  showContactUs: bool,
  useUsedInventoryDisclaimerCopy: bool,
};

ContactUsLink.defaultProps = {
  showContactUs: false,
  useUsedInventoryDisclaimerCopy: false,
};

function mapStateToProps(state) {
  const { ReviewDetails } = state;
  const { showContactUs, useUsedInventoryDisclaimerCopy } = ReviewDetails;
  const modelOption = getModelCode(state);
  const modelName = _get(state, `OMS.lexicon.options.${modelOption}.long_name`, '');
  const requestCallbackString = i18n('Inventory.common.requestCallbackLabel_used', {
    MODEL_NAME: modelName,
    BUTTON_START,
    BUTTON_END,
  });
  const requestCallbackCopy = buttonStringExtractor(i18n(requestCallbackString));

  return {
    showContactUs,
    useUsedInventoryDisclaimerCopy,
    requestCallbackCopy,
  };
}

export default connect(mapStateToProps)(ContactUsLink);
