import { formatCurrency, formatPercent } from '@tesla/coin-common-components';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { array, bool, number, string, shape } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  getAllCurrentFees,
  getDepositAmount,
  getFinancialIncentives,
  getOrderFeeFromFinancialSvc,
  getTransportationFeeAmount,
  getReferralCredit,
  getRegionCode,
  getReservationCredit,
} from 'selectors';
import { i18n, getVatRateForForwardCalculation } from 'utils';
import { ORDER_FEE_TYPE } from 'dictionary';

const FeesAndDiscounts = ({
  currentFeesArray,
  orderFee,
  incentivesArray,
  showIncentiveInFeesAndDiscounts,
  inventoryDiscount,
  showOrderFee,
  deliveryPostalCode,
  transportationFee,
  isTransportFeeCollectionEnabled,
  isTransportFeeEnabled,
  isDeliveryDetailsValid,
  referralCredit,
  regionCode,
  showDiscountFees,
  reservationCredit,
  isPostOrderSwap,
  swapWithDiscount,
  showRegionalTaxTypes,
  showDistinctTaxTotal,
  vehiclePriceInclFees,
  gstSalePrice,
}) => {
  const getOrderFee = () => {
    return (
      <If condition={showOrderFee}>
        <li className="tds-list-item tds-text_color--10" data-id={'order_fee-line-item'}>
          <span className="left">
            {isPostOrderSwap
              ? i18n('SummaryPanel.order_fee_paid')
              : i18n('SummaryPanel.PaymentSummary.order_fee')}
          </span>
          <span className="right">{formatCurrency(orderFee)}</span>
        </li>
      </If>
    );
  };
  return (
    <div className="tds-o-margin-top">
      <ol className="tds-list">
        <div className={cx("pricing--separater-after", { "tds-o-flex-direction--column": showDistinctTaxTotal })}>
          <If condition={showDistinctTaxTotal}>
            {getOrderFee()}
            <li className="tds-list-item tds-flex tds-text--medium">
              <p className="tds-flex-item tds-o-flex-direction--column">
                <span>{i18n('FinancingOptions.vehicle_subTotal')}</span>
                <If condition={gstSalePrice}>
                  <span className="items price review-page--disclaimer tds-text--caption">
                    {i18n('SummaryPanel.disclaimers.vehicle_GST', {
                      GST: formatCurrency(gstSalePrice),
                    })}
                  </span>
                </If>
              </p>
              <span className="value price-indicator">{formatCurrency(vehiclePriceInclFees)}</span>
            </li>
          </If>
          <If condition={inventoryDiscount || swapWithDiscount}>
            <li className="tds-list-item tds-text_color--10" data-id={`line-item`}>
              <span className="left">{i18n('Review.price_adjustment_label')}</span>
              <span className="right">
                {formatCurrency(-(inventoryDiscount || swapWithDiscount))}
              </span>
            </li>
          </If>

          {incentivesArray.map(incentiveObj => {
            const incentive = incentiveObj[0];

            if (
              incentive?.isUIHidden ||
              incentive?.isUIHidden ||
              !showIncentiveInFeesAndDiscounts?.includes(incentive?.incentiveType)
            ) {
              return null;
            }

            return (
              <li
                key={`fees-discount-incentives-${incentive?.incentiveType}`}
                className="review-page--line-item tds-list-item"
              >
                <div className="label left">
                  <p>
                    {i18n(`SummaryPanel.PaymentSummary.${incentive?.incentiveType}`, null, null, {
                      specificOverride: [incentive?.customerType].filter(Boolean),
                      specificOverrideOperator: 'OR',
                    })}
                  </p>
                </div>
                <span className="value">{formatCurrency(incentive?.amount)}</span>
              </li>
            );
          })}

          <If condition={referralCredit?.credit}>
            <li
              key="referral-bonus-discount"
              className="tds-list-item tds-text_color--10"
              data-id="referral-bonus-line-item"
            >
              <span className="left">{i18n('Referral.referrer_name')}</span>
              <span className="right">{formatCurrency(-referralCredit?.credit) || 0}</span>
            </li>
          </If>

          <If condition={showDiscountFees}>
            {currentFeesArray.map(feeObj => {
              const fee = feeObj[0];
              if (fee?.isUIHidden || (!fee?.amount && !fee?.isUIShownZero)) {
                return null;
              }
              return (
                <Choose>
                  <When condition={fee?.showFeesBreakdownOnUI}>
                    {showRegionalTaxTypes?.map(feeType => {
                      return (
                        <If condition={_has(fee, feeType)}>
                          <li
                            key={`fees-discount-fees-${feeType}`}
                            className="tds-list-item tds-text_color--10"
                            data-id={`${feeType}-line-item`}
                          >
                            <span className="left tds-o-margin_right-16">
                              {i18n(`FinancingOptions.${feeType}`)}
                            </span>
                            <span className="right">
                              {formatCurrency(fee?.[feeType] || 0, { useDynamicRounding: true })}
                            </span>
                          </li>
                        </If>
                      );
                    })}
                  </When>
                  <Otherwise>
                    <li
                      key={`fees-discount-fees-${fee.feeType}`}
                      className="tds-list-item tds-text_color--10"
                      data-id={`${fee.feeType}-line-item`}
                    >
                      <span className="left tds-o-margin_right-16">
                        {i18n(
                          `SummaryPanel.PaymentSummary.${fee.feeType}`,
                          {
                            PERCENT: formatPercent(fee?.percent, 0),
                          },
                          null,
                          {
                            specificOverride: regionCode,
                            specificOverrideOperator: 'OR',
                          }
                        )}
                      </span>
                      <span className="right">
                        {formatCurrency(fee?.amount || 0, { useDynamicRounding: true })}
                      </span>
                    </li>
                  </Otherwise>
                </Choose>
              );
            })}
          </If>
          {/* Order fee is calculated seperately if not in FinSvc */}
          <If condition={!showDistinctTaxTotal}>{getOrderFee()}</If>
          {/* Transportation fee is calculated independently of FinSvc */}
          <If
            condition={
              !isTransportFeeCollectionEnabled &&
              isTransportFeeEnabled &&
              isDeliveryDetailsValid &&
              transportationFee
            }
            key="transportationFeeCash"
          >
            <li className="tds-list-item">
              <span className="left tds-text--regular">
                {i18n('DeliveryLocation.estimate_transport_fee_label_short', {
                  POSTAL_CODE: deliveryPostalCode,
                })}
              </span>
              <span className="tds-text--end tds-text--regular">
                {formatCurrency(transportationFee)}
              </span>
            </li>
          </If>
          <If condition={reservationCredit}>
            <li
              className="tds-list-item tds-text_color--10"
              data-id={'reservation-credit-line-item'}
            >
              <span className="left">{i18n('SummaryPanel.reservation_credit')}</span>
              <span className="right">{formatCurrency(reservationCredit)}</span>
            </li>
          </If>
        </div>
      </ol>
    </div>
  );
};

function mapStateToProps(state) {
  const { App, FinancingOptions, ReviewDetails } = state;
  const { isInventoryPriceAdjustmentsEnabled = false, isPostOrderSwap } = App;
  const {
    showAfterSavingsPriceForTrims = false,
    includeOrderFeeInVehicleTotal = true,
    showDiscountFees = true,
    vehicleDesign: { swapConfig: { Discount: swapWithDiscount = 0 } } = {},
  } = ReviewDetails || {};
  const currentFees = getAllCurrentFees(state);
  const currentFeesArray = Object.keys(currentFees).map(key => currentFees[key]);
  const { orderPayment: orderFee = 0, paymentSourceSubType = '' } =
    getDepositAmount(state, true) || {};
  const { incentives } = getFinancialIncentives(state);
  const incentivesArray = Object.keys(incentives).map(key => incentives[key]);
  const { showIncentiveInFeesAndDiscounts = [], showRegionalTaxTypes = [], showDistinctTaxTotal } =
    FinancingOptions || {};
  const inventoryDiscount = _get(ReviewDetails, 'product.data.Discount', 0);
  const isOrderFee = paymentSourceSubType?.includes(ORDER_FEE_TYPE);
  const includeOrderFeeInVehicleTotalFlag = isOrderFee ? includeOrderFeeInVehicleTotal : false;
  const showOrderFee = includeOrderFeeInVehicleTotalFlag ? orderFee : 0;
  const deliveryPostalCode = _get(state, 'ReviewDetails.DeliveryDetails.PostalCode', '');
  const transportationFee = getTransportationFeeAmount(state);
  const isTransportFeeCollectionEnabled = (state, 'App.isTransportFeeCollectionEnabled', false);
  const isTransportFeeEnabled = _get(state, 'ReviewDetails.isTransportFeeEnabled');
  const isPickupOnlyEnabled = _get(state, 'App.isPickupOnlyEnabled', false);
  const isDeliveryDetailsValid = isPickupOnlyEnabled
    ? true
    : _get(state, 'ReviewDetails.isDeliveryDetailsValid', false);
  const vatRateForForwardCalculation = getVatRateForForwardCalculation(state) || 1;
  const gstSalePrice =
    state?.Pricing?.calculatorResult?.data?.apiResults?.fees?.current?.regional?.data?.[0]
      ?.variables?.gst_on_sale || 0;
  const { vehiclePriceWithDeliveryFee } = state?.Pricing?.cash || {};

  return {
    currentFeesArray,
    orderFee,
    incentivesArray,
    showAfterSavingsPriceForTrims,
    showIncentiveInFeesAndDiscounts,
    inventoryDiscount: isInventoryPriceAdjustmentsEnabled
      ? inventoryDiscount * vatRateForForwardCalculation
      : 0,
    showOrderFee,
    showDiscountFees,
    deliveryPostalCode,
    transportationFee,
    isTransportFeeCollectionEnabled,
    isTransportFeeEnabled,
    isDeliveryDetailsValid,
    referralCredit: getReferralCredit(state),
    regionCode: getRegionCode(state),
    reservationCredit: getReservationCredit(state),
    isPostOrderSwap,
    swapWithDiscount,
    showRegionalTaxTypes,
    showDistinctTaxTotal,
    gstSalePrice,
    vehiclePriceInclFees: vehiclePriceWithDeliveryFee,
  };
}

FeesAndDiscounts.propTypes = {
  currentFeesArray: array,
  orderFee: number,
  incentivesArray: array,
  showAfterSavingsPriceForTrims: bool,
  showIncentiveInFeesAndDiscounts: array,
  inventoryDiscount: number,
  showOrderFee: bool,
  showDiscountFees: bool,
  deliveryPostalCode: string,
  transportationFee: number,
  isTransportFeeCollectionEnabled: bool,
  isTransportFeeEnabled: bool,
  isDeliveryDetailsValid: bool,
  referralCredit: shape({ credit: number }),
  regionCode: string,
  isPostOrderSwap: bool,
  swapWithDiscount: number,
};

FeesAndDiscounts.defaultProps = {
  currentFeesArray: [],
  orderFee: 0,
  incentivesArray: [],
  showAfterSavingsPriceForTrims: false,
  showIncentiveInFeesAndDiscounts: [],
  inventoryDiscount: 0,
  showOrderFee: true,
  showDiscountFees: true,
  deliveryPostalCode: '',
  transportationFee: 0,
  isTransportFeeCollectionEnabled: false,
  isTransportFeeEnabled: false,
  isDeliveryDetailsValid: false,
  referralCredit: {},
  regionCode: '',
  isPostOrderSwap: false,
  swapWithDiscount: 0,
};

export default connect(mapStateToProps)(FeesAndDiscounts);
