import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { removeAlert } from 'actions';
import { htmlToReact } from 'utils';
import { StatusMessage } from '@tesla/design-system-react';
import ActionTrigger from '../ActionTrigger';

const renderAlert = data => {
  const { message, type, key, withTrigger, header } = data;
  const msg = (
    <StatusMessage
      className="tds--fade-in"
      body={message ? htmlToReact(message) : ''}
      type={type || 'error'}
      header={header}
      // eslint-disable-next-line react/no-array-index-key
      key={`alert_${key}`}
    />
  );
  if (withTrigger) {
    return <ActionTrigger>{msg}</ActionTrigger>;
  }
  return msg;
};
const Alert = ({ alerts, dispatch, classes = '' }) => {
  return (
    <div className={`alerts-container ${classes}`}>
      {/* Map each of the current alerts to a status message */}
      {alerts.map((alert, i) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={`alert_container_${i}`}
          onClick={() => dispatch(removeAlert(alert))}
          onKeyPress={() => dispatch(removeAlert(alert))}
          role="button"
          tabIndex={0}
          data-id="error-alert"
        >
          {renderAlert({ ...alert, key: i })}
        </div>
      ))}
    </div>
  );
};

Alert.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  dispatch: PropTypes.func,
};

Alert.defaultProps = {
  alerts: [],
  dispatch: () => {},
};

const mapStateToProps = (state, ownProps) => ({
  alerts: ownProps?.alerts || state.Alert.alerts,
});

export default connect(mapStateToProps)(Alert);
