import { formatCurrency } from '@tesla/coin-common-components';
import { MODAL_SMALL, PRICING_SAVINGS_CALCULATIONS, FinanceTypes } from 'dictionary';
import _get from 'lodash/get';
import { array, bool, number, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import {
  getFinancialIncentives,
  getModelCode,
  getRegionName,
  getFinanceType,
  getPrice,
} from 'selectors';
import { i18n } from 'utils';
import { PERIOD_CREDIT } from 'dictionary';
import ModalTrigger from '../../ModalTrigger';
import BannerModalTrigger from './BannerModalTrigger';

const Incentives = ({
  incentivesArray,
  showIncentiveInFeesAndDiscounts,
  showAfterSavingsPriceForTrims,
  priceAfterSavings,
  priceBeforeSavings,
  modelCode,
  modelName,
  region,
  showVehiclePriceInSummary,
}) => {
  return (
    <div className="review-page--block tds-o-margin-top">
      <div className="review-page--line-items">
        <ul className="review-page--line-items-list tds-list">
          {showAfterSavingsPriceForTrims &&
            incentivesArray.map((incentiveObj, objIndex) => {
              return incentiveObj.map((incentive, index) => {
                if (
                  incentive?.isUIHidden ||
                  incentive?.period === PERIOD_CREDIT ||
                  showIncentiveInFeesAndDiscounts?.includes(incentive?.incentiveType)
                ) {
                  return null;
                }
                return (
                  <li
                    key={`incentives-incentive-${incentive?.incentiveType}-${objIndex}-${index}`}
                    className="review-page--line-item tds-list-item"
                  >
                    <div className="label left">
                      <p>
                        {i18n(
                          `SummaryPanel.PaymentSummary.${incentive?.incentiveType}`,
                          {
                            REGION: region,
                          },
                          null,
                          {
                            specificOverride: [incentive?.incentiveSubType],
                            returnNullWhenEmpty: true,
                            specificOverrideOperator: 'OR',
                          }
                        )}
                      </p>
                      {incentive?.incentiveType === 'taxCredit' && (
                        <BannerModalTrigger
                          contentSource={incentive?.incentiveType}
                          label={i18n('Incentives.taxCredit_eligiblity')}
                        />
                      )}
                    </div>
                    <span className="value">{formatCurrency(incentive?.amount)}</span>
                  </li>
                );
              });
            })}
          <If condition={showAfterSavingsPriceForTrims}>
            <li className="review-page--line-item tds-list-item">
              <div className="label left">
                <p>{i18n('Review.est_savings_total')}</p>
              </div>
              <span className="value">{formatCurrency(priceAfterSavings)}</span>
            </li>
          </If>
          <If condition={showVehiclePriceInSummary}>
            <li className="tds-list-item tds-flex">
              <span className="tds-flex-item tds-text--medium">{i18n('Review.vehicle_price')}</span>
              <span className="value price-indicator tds-flex--col_1of3 tds-text--medium">
                {priceBeforeSavings}
              </span>
            </li>
            <li className="tds-list-item tds-flex">
              <span className="tds-flex-item">
                <If condition={modelCode === 'm3' || modelCode === 'my'}>
                  {i18n('SummaryPanel.disclaimers.myVatAmount', null, null, {
                    returnNullWhenEmpty: true,
                  })}
                </If>
                <If condition={modelCode === 'ms' || modelCode === 'mx'}>
                  {i18n('SummaryPanel.disclaimers.myVatAmountSX', null, null, {
                    returnNullWhenEmpty: true,
                  })}
                </If>
              </span>
            </li>
          </If>
        </ul>
        <If condition={showAfterSavingsPriceForTrims}>
          <ModalTrigger
            options={{
              props: {
                componentName: 'GasSaving',
                props: {
                  genericWrapper: true,
                  size: MODAL_SMALL,
                  modelName,
                  modelCode,
                  classes: 'tds-o-text_color--20 tds-o-text-align',
                  containerCss: 'gas-saving',
                },
              },
            }}
            analyticsInteraction={PRICING_SAVINGS_CALCULATIONS}
          >
            <span className="gas-saving--link tds-link tds-text--caption cf-payment-overview__title">
              {i18n('Review.savings_link_label')}
            </span>
          </ModalTrigger>
        </If>
      </div>
    </div>
  );
};

function mapStateToProps(state, { estPurchasePrice = 0 }) {
  const { showAfterSavingsPriceForTrims = false, showVehiclePriceInSummary = false } =
    state?.ReviewDetails || {};
  const { showIncentiveInFeesAndDiscounts = [] } = state?.FinancingOptions || {};

  const { incentives } = getFinancialIncentives(state);
  const incentivesArray = Object.keys(incentives).map(key => incentives[key]);

  const totalSavings = _get(state, 'Pricing.cash.totalSavingsAmount', 0);
  const priceAfterSavings = estPurchasePrice + totalSavings;

  const modelOption = getModelCode(state);
  const modelCode = _get(state, 'OMS.oms_params.model');
  const modelName = _get(state, `OMS.lexicon.options.${modelOption}.name`, {});
  const region = getRegionName(state) || '';
  const { vehiclePriceWithoutDiscounts = 0 } = state?.Pricing?.cash || {};
  const financeType = getFinanceType(state);
  const priceBeforeSavings = getPrice(state, 'cash');
  return {
    modelCode,
    modelName,
    incentivesArray,
    showIncentiveInFeesAndDiscounts,
    showAfterSavingsPriceForTrims,
    priceAfterSavings,
    region,
    showVehiclePriceInSummary,
    vehiclePriceWithoutDiscounts,
    financeType,
    priceBeforeSavings,
  };
}

Incentives.propTypes = {
  showIncentiveInFeesAndDiscounts: array,
  modelCode: string,
  modelName: string,
  incentivesArray: array,
  showAfterSavingsPriceForTrims: bool,
  priceAfterSavings: number,
  region: string,
  showVehiclePriceInSummary: bool,
  vehiclePriceWithoutDiscounts: number,
  financeType: string,
  priceBeforeSavings: string,
};

Incentives.defaultProps = {
  showIncentiveInFeesAndDiscounts: [],
  modelCode: '',
  modelName: '',
  incentivesArray: [],
  showAfterSavingsPriceForTrims: false,
  priceAfterSavings: 0,
  region: '',
  showVehiclePriceInSummary: false,
  vehiclePriceWithoutDiscounts: 0,
  financeType: FinanceTypes.CASH,
  priceBeforeSavings: '',
};

export default connect(mapStateToProps)(Incentives);
