const InitalState = {
  object: {
    deliveryTimingMatch: {},
    deliveryTimingLocation: 'CA',
    changeDeliveryLocation: false,
    deliverystateAddress: '',
    title: 'Location.sources.geoIp.region.longName',
    isLoading: false,
    showSimilarDesigns: false,
  },
};

export default InitalState;
