import { LOCAL_STORAGE_KEY, COMPONENT_CONTEXT_SEEKRET } from 'dictionary';

import platform from 'platform';
import { filterSalesRegion } from 'utils';

const { fleetSalesRegions } = filterSalesRegion();
const { showSeekretContext = false } = window?.tesla?.App || {};

const deltasV2 = [
  {
    selected_by: {
      market: 'US',
      model: ['m3', 'my'],
      isPostOrderSwap: true,
      isInventory: true,
      isUsedInventory: false,
    },
    object: {
      hasBannerWithConditions: 'regional',
    },
  },
  {
    selected_by: {
      isInventory: true,
      market: ['AU', 'NZ'],
    },
    object: {
      isLocationSelectorEnabled: true,
      fleetSalesRegions,
    },
  },
  {
    selected_by: {
      model: ['ms', 'mx'],
      market: ['AU', 'NZ'],
    },
    object: {
      isLocationSelectorEnabled: false,
    },
  },
  {
    selected_by: {
      isInventory: true,
      market: ['NZ'],
    },
    object: {
      isRegistrationTypeEnabled: false,
      hasBannerWithConditions: 'cleanVehicle',
    },
  },
  {
    selected_by: {
      isInventory: false,
      market: ['NZ'],
    },
    object: {
      hideRegistrationTypeSelector: true,
    },
  },
  {
    selected_by: {
      model: 'ts',
    },
    object: {
      hideEstimatedDeliveryOrderPage: true,
      isLocationSelectorEnabled: false,
    },
  },
  {
    selected_by: {
      market: ['TR'],
    },
    object: {
      hideEstimatedDeliveryEditDesignPage: true,
    },
  },
  {
    selected_by: {
      model: ['ct'],
      isReservationToOrderFlow: true,
    },
    object: {
      showSummaryPanelFooter: false,
    },
  },
  {
    selected_by: {
      market: 'CN',
    },
    object: {
      useTopBanner: true,
    },
  },
  {
    selected_by: {
      market: 'CN',
      isUsedInventory: false,
    },
    object: {
      useTopBanner: false,
      hideInventoryVin: false,
    },
  },
];

if (showSeekretContext) {
  deltasV2.push({
    selected_by: {
      market: 'US',
      model: ['ct'],
      isReservationToOrderFlow: false,
    },
    object: {
      lexiconComponentContext: COMPONENT_CONTEXT_SEEKRET,
    },
  });
}

export default ({ object, overrides = {} }) => ({
  object: {
    // Google Analytics tracking on?
    analytics: true,
    // Redux logging on?
    reduxLogger: false,
    // Debug mode (enhanced console and debug tools)
    debug: false,
    // is mobile
    isMobile: false,
    isLayoutMobile: false,
    // mobile layout breakpoint
    layoutMobileBefore: 640,
    layoutMobileAdjusted: 860,
    // tablet layout breakpoint
    layoutTabletBefore: 1024,
    userOS: '',
    // populated when url has query params
    query_params: {},
    // set application process (e.g., "scroll-up") to handle programmaticly triggered processes cross-components
    processes: [],
    // Referral active?
    referralActive: true,
    application_context: 'MobileConfigurator',
    localStorageKey: LOCAL_STORAGE_KEY,
    ...object,
    ...overrides,
    platform: {
      Browser: platform.name,
      OS: platform.os.toString(),
      BrowserVersion: platform.version,
    },
    showAssetGallery: true,
    hasBannerWithConditions: null,
    showEditDesignLink: true,
    getUpdates: {},
    currentFeatures: {},
    useTopBanner: true,
    showPostOrderConfirmation: false,
    hideInventoryVin: false,
    secondaryBannerEnabled: false,
  },
  deltasV2,
});
