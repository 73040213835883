import _get from 'lodash/get';
import { InitialState as InitialStateCT } from './ct';
import { InitialState as InitialStateMT } from './mt';
import { InitialState as InitialStateTS } from './ts';

const teslaObj = _get(window, 'tesla', {}) || {};
const { isCoinReloaded = false } = teslaObj?.App || {};
const { isAccessoriesEnabled = false, isInventorySwapEnabled = false, showRecommendedAccessories = false } = teslaObj || {};
const isLegacy = _get(teslaObj, 'product.data.IsLegacy', false);
const bkbaOpt = isLegacy ? 2 : 1;
const externalZoom = _get(teslaObj, 'product.data.CompositorViewsCustom.externalZoom.order', 1);
const zoom = externalZoom !== 1 ? externalZoom : undefined;
const configDisabledView = {
  gallery: [
    {
      component: 'MainGallery',
      key: 'MainGallery:gallery.EXTERIOR',
      group: 'EXTERIOR_GALLERY',
      sections: ['overview'],
    },
  ],
  main: [
    {
      component: 'LexiconGroup',
      key: 'LexiconGroup:main.BATTERY_AND_DRIVE',
      group: 'BATTERY_AND_DRIVE',
      sections: ['overview'],
    },
  ],
};

const InitialState = {
  merge_method: 'assign',
  object: {
    allowedSections: ['overview', 'earlydelivery', 'payment', 'confirmation'],
    section: 'overview',
    layouts: ['main', 'gallery'],
    layoutsData: {},
    activeGroupId: 'BATTERY_AND_DRIVE',
    main: [
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.BATTERY_AND_DRIVE',
        group: 'BATTERY_AND_DRIVE',
        sections: ['overview'],
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.PAINT',
        sections: ['overview'],
        group: 'PAINT',
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.WHEELS',
        sections: ['overview'],
        group: 'WHEELS',
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.TOWING',
        sections: ['overview'],
        group: ['TOWING', 'TOW_PACKAGE'],
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.PACKAGES',
        sections: ['overview'],
        group: 'PACKAGES',
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.AUTOPILOT_PACKAGE',
        sections: ['overview'],
        group: 'AUTOPILOT_PACKAGE',
        filterByContext: ['swap'],
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.CONNECTIVITY_TRIAL',
        sections: ['overview'],
        group: 'CONNECTIVITY_TRIAL',
      },
      {
        component: 'LexiconGroup',
        key: 'LexiconGroup:main.DRIVE_ANYWHERE_PACKAGE',
        sections: ['overview'],
        group: 'DRIVE_ANYWHERE_PACKAGE',
        filterByContext: ['swap'],
      },
      {
        component: 'EditDesignLink',
        key: 'EditDesignLink:main.PAYMENT',
        sections: ['earlydelivery', 'payment'],
        props: {
          classes:
            'tds-content_container tds--padding tds--no_vertical_padding coin-edit-design--section',
        },
        selected_by: {
          and: [
            {
              on: ['desktop'],
            },
          ],
        },
      },
      {
        component: 'EditDesignLink',
        key: 'EditDesignLink:main.PAYMENT',
        sections: ['earlydelivery', 'payment'],
        props: {
          classes:
            'tds-content_container option-widget--container tds--padding tds--no_vertical_padding',
        },
        selected_by: {
          or: [
            {
              on: ['tablet', 'mobile'],
            },
          ],
        },
      },
      {
        component: 'AccessoriesGroup',
        key: 'CHARGING_ACCESSORIES',
        group: 'CHARGING_ACCESSORIES',
        sections: ['overview'],
        props: {
          showAsset: isCoinReloaded,
          classes: 'tds--align_center',
        },
        filterByContext: ['swap'],
      },
      (showRecommendedAccessories ? {
        component: 'AccessoriesGroup',
        key: 'RECOMMENDED_ACCESSORIES',
        group: 'RECOMMENDED_ACCESSORIES',
        sections: ['overview'],
        props: {
          classes: 'tds--align_center',
          listType: 'recommended',
        },
        filterByContext: ['swap'],
      } : {}),
      {
        component: 'PaymentOverviewTrigger',
        key: 'OPTIONS',
        sections: ['overview'],
        props: {
          classes: 'tds--align_center',
        },
        filterByContext: ['swap'],
      },
      (!isCoinReloaded ? {
        component: 'AssetLoader',
        key: 'PAYMENT.asset',
        sections: ['payment'],
        props: {
          asset: {
            bkba: 1,
            type: 'image-compositor',
            view: 'REAR34',
            crop: [1300, 480, 300, 290],
          },
          className: 'group-main-content--full-asset',
          isAsset: true,
        },
        selected_by: {
          or: [
            {
              on: ['tablet', 'mobile'],
            },
          ],
        },
      }: {}),
      (isInventorySwapEnabled && !isCoinReloaded ? {
        component: 'AssetLoader',
        key: 'EARLYDELIVERY.asset',
        sections: ['earlydelivery'],
        props: {
          asset: {
            bkba: 1,
            type: 'image-compositor',
            view: 'FRONT34',
            crop: [1300, 460, 300, 300],
          },
          className: 'group-main-content--full-asset',
          isAsset: true,
        },
        selected_by: {
          or: [
            {
              on: ['tablet', 'mobile'],
            },
          ],
        },
      } : {}),
      (isInventorySwapEnabled && !isCoinReloaded ? {
        component: 'InventorySwap',
        key: 'InventorySwap:main:EARLY_DELIVERY',
        sections: ['earlydelivery'],
      } : {}),
      isCoinReloaded ?
      {
        component: 'PaymentSummary',
        group: 'ORDER',
        key: 'PAYMENT',
        sections: ['payment'],
        filterByContext: ['swap'],
      } :
      {
        component: 'PaymentOverview',
        group: 'ORDER',
        key: 'PAYMENT',
        sections: ['payment'],
      },
    ],
    gallery: [
      {
        component: 'MainGallery',
        key: 'MainGallery:gallery.FSD',
        group: 'FSD_GALLERY',
        excludeGroup: 'BATTERY_AND_DRIVE',
        sections: isCoinReloaded ? ['overview', 'payment'] : ['overview'],
      },
      {
        component: 'MainGallery',
        key: 'MainGallery:gallery.EXTERIOR',
        group: 'EXTERIOR_GALLERY',
        sections: isCoinReloaded ? ['overview', 'payment'] : ['earlydelivery', 'overview'],
      },
      (!isCoinReloaded && {
        component: 'MainGallery',
        key: 'MainGallery:gallery.EXTERIOR',
        group: 'EXTERIOR_GALLERY',
        gallery: {
          group: 'EXTERIOR_GALLERY',
          section: 2,
        },
        sections: ['payment'],
      }),
      {
        component: 'MainGallery',
        key: 'MainGallery:gallery.GENERIC',
        group: 'GENERIC_GALLERY',
        excludeGroup: 'BATTERY_AND_DRIVE',
        sections: isCoinReloaded ? ['overview', 'payment'] : ['overview'],
      },
    ],
    configDisabledView,
    containerBG: '',
  },
  deltas: {
    model: {
      ct: InitialStateCT.order,
      mt: InitialStateMT,
      ts: InitialStateTS,
    },
  },
  deltasV2: [
    {
      selected_by: {
        market: ['US', 'CA'],
        isEnterpriseOrder: false,
        model: ['ms', 'mx', 'm3', 'my'],
      },
      object: {
        main: [
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.BATTERY_AND_DRIVE',
            group: 'BATTERY_AND_DRIVE',
            sections: ['overview'],
          },
          {
            component: 'InventorySummary',
            key: 'LexiconGroup:main.BATTERY_AND_DRIVE_SWAP',
            group: 'BATTERY_AND_DRIVE',
            sections: ['overview'],
            context: ['swap'],
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.PAINT',
            sections: ['overview'],
            group: 'PAINT',
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.WHEELS',
            sections: ['overview'],
            group: 'WHEELS',
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.TOWING',
            sections: ['overview'],
            group: ['TOWING', 'TOW_PACKAGE'],
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.PACKAGES',
            sections: ['overview'],
            group: 'PACKAGES',
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.AUTOPILOT_PACKAGE',
            sections: ['overview'],
            group: 'AUTOPILOT_PACKAGE',
            filterByContext: ['swap'],
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.DRIVE_ANYWHERE_PACKAGE',
            sections: ['overview'],
            group: 'DRIVE_ANYWHERE_PACKAGE',
            filterByContext: ['swap'],
          },
          {
            component: 'EditDesignLink',
            key: 'EditDesignLink:main.PAYMENT',
            sections: ['earlydelivery', 'payment'],
            props: {
              classes:
                'tds-content_container tds--padding tds--no_vertical_padding coin-edit-design--section',
            },
            selected_by: {
              and: [
                {
                  on: ['desktop'],
                },
              ],
            },
          },
          {
            component: 'EditDesignLink',
            key: 'EditDesignLink:main.PAYMENT',
            sections: ['earlydelivery', 'payment'],
            props: {
              classes:
                'tds-content_container option-widget--container tds--padding tds--no_vertical_padding',
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          },
          {
            component: 'AccessoriesGroup',
            key: 'CHARGING_ACCESSORIES',
            group: 'CHARGING_ACCESSORIES',
            sections: ['overview'],
            props: {
              showAsset: isCoinReloaded,
              classes: 'tds--align_center',
            },
            filterByContext: ['swap'],
          },
          (showRecommendedAccessories ? {
            component: 'AccessoriesGroup',
            key: 'RECOMMENDED_ACCESSORIES',
            group: 'RECOMMENDED_ACCESSORIES',
            sections: ['overview'],
            props: {
              classes: 'tds--align_center',
              listType: 'recommended',
            },
            filterByContext: ['swap'],
          } : {}),
          {
            component: 'PaymentOverviewTrigger',
            key: 'OPTIONS',
            sections: ['overview'],
            props: {
              classes: 'tds--align_center',
            },
            filterByContext: ['swap'],
          },
          (!isCoinReloaded && {
            component: 'ConfigAssetLoader',
            group: 'ORDER',
            key: 'PAYMENT.asset',
            sections: ['payment'],
            props: {
              asset: {
                bkba: 1,
                type: 'image-compositor',
                view: 'REAR34',
                crop: [1300, 480, 300, 290],
                zoom: 1.2
              },
              className: 'group-main-content--full-asset',
              isAsset: true,
              classes:'option-widget--container tds-flex tds-flex--row-nowrap'
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          }),
          (isInventorySwapEnabled && !isCoinReloaded ? {
            component: 'AssetLoader',
            key: 'EARLYDELIVERY.asset',
            sections: ['earlydelivery'],
            props: {
              asset: {
                bkba: 1,
                type: 'image-compositor',
                view: 'FRONT34',
                crop: [1300, 460, 300, 300],
              },
              className: 'group-main-content--full-asset',
              isAsset: true,
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          } : {}),
          (isInventorySwapEnabled && !isCoinReloaded ? {
            component: 'InventorySwap',
            key: 'InventorySwap:main:EARLY_DELIVERY',
            sections: ['earlydelivery'],
          } : {}),
          isCoinReloaded ?
          {
            component: 'PaymentSummary',
            group: 'ORDER',
            key: 'PAYMENT',
            sections: ['payment'],
            filterByContext: ['swap'],
          } :
          {
            component: 'PaymentOverview',
            group: 'ORDER',
            key: 'PAYMENT',
            sections: ['payment'],
          },
        ],
        gallery: [
          {
            component: 'MainGallery',
            key: 'MainGallery:gallery.FSD',
            group: 'FSD_GALLERY',
            excludeGroup: 'BATTERY_AND_DRIVE',
            sections: isCoinReloaded ? ['overview', 'payment'] : ['overview'],
          },
          {
            component: 'MainGallery',
            key: 'MainGallery:gallery.EXTERIOR',
            group: 'EXTERIOR_GALLERY',
            sections: isCoinReloaded ? ['overview', 'payment'] : ['earlydelivery', 'overview'],
          },
          (!isCoinReloaded && {
            component: 'MainGallery',
            key: 'MainGallery:gallery.EXTERIOR',
            group: 'EXTERIOR_GALLERY',
            gallery: {
              group: 'EXTERIOR_GALLERY',
              section: 2,
            },
            sections: ['payment'],
          }),
          {
            component: 'MainGallery',
            key: 'MainGallery:gallery.GENERIC',
            group: 'GENERIC_GALLERY',
            excludeGroup: 'BATTERY_AND_DRIVE',
            sections: isCoinReloaded ? ['overview', 'payment'] : ['overview'],
          },
        ],
        configDisabledView,
        containerBG: '',
      },
    },
    {
      selected_by: {
        market: ['AU', 'NZ'],
        model: ['ms', 'mx'],
      },
      object: {
        allowedSections: ['overview'],
      },
    },
    {
      selected_by: {
        isInventory: true,
        isProductWithOverview: true,
        isUsedPhotos: false,
        model: 'ct',
      },
      object: {
        main: [
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.FOUNDATION_SERIES',
            sections: ['overview'],
            group: 'FOUNDATION_SERIES',
          },
          {
            component: 'InventorySummary',
            key: 'InventorySummary',
            sections: ['overview'],
          },
          {
            component: 'VehicleDetailsBlock',
            key: 'VehicleDetailsBlock',
            sections: ['overview'],
          },

          (isAccessoriesEnabled ? {
          component: 'LexiconGroup',
            key: 'LexiconGroup:main.ELECTRONIC_ACCESSORIES',
            group: 'ELECTRONIC_ACCESSORIES',
            sections: ['overview'],
          } : {}),
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.VEHICLE_FEATURES',
            sections: ['overview'],
            group: 'VEHICLE_FEATURES',
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.EXTERIOR',
            sections: ['overview'],
            group: 'EXTERIOR',
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.WHEELS',
            sections: ['overview'],
            group: 'WHEELS',
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.INTERIOR',
            sections: ['overview'],
            group: 'INTERIOR'
          },
          {
            component: 'InventoryFeatureHighlights',
            key: 'InventoryFeatureHighlights',
            sections: ['overview'],
            props: {
              classes: 'tds--no_vertical_padding'
            }
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.ACCESSORIES_PACKAGE',
            sections: ['overview'],
            group: 'ACCESSORIES_PACKAGE',
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main:CHARGING',
            sections: ['overview'],
            group: 'CHARGING',
          },
          {
            component: 'AccessoriesGroup',
            key: 'CHARGING_ACCESSORIES',
            group: 'CHARGING_ACCESSORIES',
            sections: ['overview'],
            props: {
              showAsset: isCoinReloaded,
              classes: 'tds--align_center',
            },
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.POWER_YOUR_HOME',
            sections: ['overview'],
            group: 'POWER_YOUR_HOME',
          },
          (showRecommendedAccessories ? {
            component: 'AccessoriesGroup',
            key: 'RECOMMENDED_ACCESSORIES',
            group: 'RECOMMENDED_ACCESSORIES',
            sections: ['overview'],
            props: {
              classes: 'tds--align_center',
              listType: 'recommended',
            },
          } : {}),
          {
            component: 'WarrantyBlock',
            key: 'WarrantyBlock',
            sections: ['overview'],
          },
          {
            component: 'AdditionalDetailsBlock',
            key: 'AdditionalDetailsBlock',
            sections: ['overview'],
          },
          {
            component: 'ConditionNotificationBlock',
            key: 'ConditionNotificationBlock',
            sections: ['overview'],
          },
          {
            component: 'InventoryConditionBlock',
            key: 'InventoryConditionBlock',
            sections: ['overview'],
          },
          {
            component: 'PaymentOverviewTrigger',
            key: 'OPTIONS',
            sections: ['overview'],
            props: {
              classes: 'tds--align_center',
            },
          },
          {
            component: 'EditDesignLink',
            key: 'EditDesignLink:main.PAYMENT',
            sections: ['payment'],
            props: {
              classes: 'tds-content_container tds--padding tds--no_vertical_padding',
            },
          },
          {
            component: 'PaymentSummary',
            group: 'ORDER',
            key: 'PAYMENT',
            sections: ['payment'],
          }
        ],
        gallery: [
          {
            component: 'MainGallery',
            key: 'MainGallery:gallery.FSD',
            group: 'FSD_GALLERY',
            excludeGroup: 'BATTERY_AND_DRIVE',
            sections: ['overview', 'payment'],
          },
          {
            component: 'MainGallery',
            key: 'MainGallery:gallery.EXTERIOR',
            group: 'EXTERIOR_GALLERY',
            sections: ['overview', 'payment'],
          },
        ],
      },
    },
    {
      selected_by: {
        isInventory: true,
        isProductWithOverview: true,
        isUsedPhotos: false,
      },
      object: {
        main: [
          (!isCoinReloaded && {
            component: 'InventoryAssetLoader',
            key: 'OVERVIEW.asset.frontViewUsed',
            sections: ['overview'],
            props: {
              asset: {
                bkba: bkbaOpt,
                type: 'image-compositor',
                zoom: 1,
                crop: [1300, 500, 300, 300],
              },
              className: 'group-main-content--full-asset',
              path: 'ReviewDetails.product.data.CompositorViews.frontView',
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          }),
          {
            component: 'InventorySummary',
            key: 'InventorySummary',
            sections: ['overview'],
          },
          (!isCoinReloaded && {
            component: 'InventoryAssetLoader',
            key: 'OVERVIEW.asset.interiorViewUsed',
            sections: ['overview'],
            props: {
              asset: {
                bkba: bkbaOpt,
                type: 'image-compositor',
                zoom: 1,
                crop: [0, 0, 0, 0],
              },
              className: 'group-main-content--full-asset',
              path: 'ReviewDetails.product.data.CompositorViews.interiorView',
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          }),
          {
            component: 'InventoryFeatureHighlights',
            key: 'InventoryFeatureHighlights',
            sections: ['overview'],
          },
          {
            component: 'AccessoriesGroup',
            key: 'CHARGING_ACCESSORIES',
            group: 'CHARGING_ACCESSORIES',
            sections: ['overview'],
            props: {
              showAsset: isCoinReloaded,
              classes: 'tds--align_center',
            },
          },
          (showRecommendedAccessories ? {
            component: 'AccessoriesGroup',
            key: 'RECOMMENDED_ACCESSORIES',
            group: 'RECOMMENDED_ACCESSORIES',
            sections: ['overview'],
            props: {
              classes: 'tds--align_center',
              listType: 'recommended',
            },
          } : {}),
          {
            component: 'WarrantyBlock',
            key: 'WarrantyBlock',
            sections: ['overview'],
          },
          {
            component: 'VehicleDetailsBlock',
            key: 'VehicleDetailsBlock',
            sections: ['overview'],
          },
          {
            component: 'AdditionalDetailsBlock',
            key: 'AdditionalDetailsBlock',
            sections: ['overview'],
          },
          {
            component: 'ConditionNotificationBlock',
            key: 'ConditionNotificationBlock',
            sections: ['overview'],
          },
          {
            component: 'InventoryConditionBlock',
            key: 'InventoryConditionBlock',
            sections: ['overview'],
          },
          {
            component: 'PaymentOverviewTrigger',
            key: 'OPTIONS',
            sections: ['overview'],
            props: {
              classes: 'tds--align_center',
            },
          },
          {
            component: 'EditDesignLink',
            key: 'EditDesignLink:main.PAYMENT',
            sections: ['payment'],
            props: {
              classes: 'tds-content_container tds--padding tds--no_vertical_padding',
            },
          },
          (!isCoinReloaded && {
            component: 'InventoryAssetLoader',
            key: 'PAYMENT.assetUsed',
            sections: ['payment'],
            props: {
              asset: {
                bkba: bkbaOpt,
                type: 'image-compositor',
                zoom: 1,
                crop: [1300, 460, 300, 300],
              },
              path: 'ReviewDetails.product.data.CompositorViews.frontView',
              className: 'group-main-content--full-asset',
              isAsset: true,
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          }),
          isCoinReloaded ?
          {
            component: 'PaymentSummary',
            group: 'ORDER',
            key: 'PAYMENT',
            sections: ['payment'],
          } :
          {
            component: 'PaymentOverview',
            group: 'ORDER',
            key: 'PAYMENT',
            sections: ['payment'],
          },
        ],
      },
    },
    {
      selected_by: {
        isInventory: true,
        isUsedPhotos: false,
        isProductWithCustomViews: false,
      },
      object: {
        gallery: [
          {
            component: 'MainGallery',
            key: 'MainGallery:gallery.EXTERIOR',
            group: 'EXTERIOR_GALLERY',
            sections: ['overview'],
          },
          {
            component: 'MainGallery',
            key: 'MainGallery:gallery.EXTERIOR',
            group: 'EXTERIOR_GALLERY',
            gallery: {
              group: 'EXTERIOR_GALLERY',
              section: 2,
            },
            sections: ['payment'],
          },
        ],
      },
    },
    {
      selected_by: {
        isInventory: true,
        isProductWithCustomViews: true,
        isUsedPhotos: false,
      },
      object: {
        gallery: [
          {
            component: 'MainGallery',
            key: 'MainGallery:gallery.EXTERIOR',
            group: 'EXTERIOR_GALLERY',
            source: {
              path: 'ReviewDetails.product.data.CompositorViews',
              defaultParams: {
                bkba: bkbaOpt,
                zoom,
              },
            },
            sections: ['overview'],
          },
          {
            component: 'MainGallery',
            key: 'MainGallery:gallery.EXTERIOR',
            group: 'EXTERIOR_GALLERY',
            source: {
              path: 'ReviewDetails.product.data.CompositorViews',
              defaultParams: {
                bkba: bkbaOpt,
                type: 'image-compositor',
                zoom,
              },
            },
            sections: ['payment'],
          },
        ],
      },
    },
    {
      selected_by: {
        isUsedPhotos: true,
      },
      object: {
        gallery: [
          {
            component: 'MainGallery',
            key: 'MainGallery:gallery.EXTERIOR',
            group: 'EXTERIOR_GALLERY',
            source: {
              path: 'ReviewDetails.product.data.VehiclePhotos',
              defaultParams: {
                bkba: bkbaOpt,
                zoom,
              },
            },
            sections: ['overview', 'payment'],
            props: {
              infinite: false,
              dots: true,
              theme: 'used-photos',
              enableModalView: false,
            },
          },
        ],
        main: [
          {
            component: 'InventorySummary',
            key: 'InventorySummary',
            sections: ['overview'],
          },
          {
            component: 'InventoryAssetLoader',
            key: 'OVERVIEW.asset.interiorViewUsed',
            sections: ['overview'],
            props: {
              asset: {
                bkba: bkbaOpt,
                type: 'image-compositor',
                zoom: 1,
                crop: [0, 0, 0, 0],
              },
              className: 'group-main-content--full-asset',
              path: 'ReviewDetails.product.data.CompositorViews.interiorView',
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          },
          {
            component: 'InventoryFeatureHighlights',
            key: 'InventoryFeatureHighlights',
            sections: ['overview'],
          },
          {
            component: 'AccessoriesGroup',
            key: 'CHARGING_ACCESSORIES',
            group: 'CHARGING_ACCESSORIES',
            sections: ['overview'],
            props: {
              showAsset: isCoinReloaded,
              classes: 'tds--align_center',
            },
          },
          (showRecommendedAccessories ? {
            component: 'AccessoriesGroup',
            key: 'RECOMMENDED_ACCESSORIES',
            group: 'RECOMMENDED_ACCESSORIES',
            sections: ['overview'],
            props: {
              classes: 'tds--align_center',
              listType: 'recommended',
            },
          } : {}),
          {
            component: 'WarrantyBlock',
            key: 'WarrantyBlock',
            sections: ['overview'],
          },
          {
            component: 'VehicleDetailsBlock',
            key: 'VehicleDetailsBlock',
            sections: ['overview'],
          },
          {
            component: 'AdditionalDetailsBlock',
            key: 'AdditionalDetailsBlock',
            sections: ['overview'],
          },
          {
            component: 'InventoryConditionBlock',
            key: 'InventoryConditionBlock',
            sections: ['overview'],
          },
          {
            component: 'PaymentOverviewTrigger',
            key: 'OPTIONS',
            sections: ['overview'],
            props: {
              classes: 'tds--align_center',
            },
          },
          {
            component: 'EditDesignLink',
            key: 'EditDesignLink:main.PAYMENT',
            sections: ['payment'],
            props: {
              classes: 'tds-content_container tds--padding tds--no_vertical_padding',
            },
          },
          isCoinReloaded ?
          {
            component: 'PaymentSummary',
            group: 'ORDER',
            key: 'PAYMENT',
            sections: ['payment'],
          } :
          {
            component: 'PaymentOverview',
            group: 'ORDER',
            key: 'PAYMENT',
            sections: ['payment'],
          },
        ],
      },
    },
    {
      selected_by: {
        isInventory: true,
        isUsedInventory: false,
        isProductWithOverview: true,
      },
      object: {
        main: [
          (!isCoinReloaded && {
            component: 'InventoryAssetLoader',
            key: 'OVERVIEW.asset.frontViewNew',
            sections: ['overview'],
            props: {
              asset: {
                bkba: 1,
                type: 'image-compositor',
                zoom: 1,
                crop: [1300, 500, 300, 300],
              },
              className: 'group-main-content--full-asset',
              path: 'ReviewDetails.product.data.CompositorViews.frontView',
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          }),
          {
            component: 'InventorySummary',
            key: 'InventorySummary',
            sections: ['overview'],
          },
          (!isCoinReloaded && {
            component: 'InventoryAssetLoader',
            key: 'OVERVIEW.asset.interiorViewNew',
            sections: ['overview'],
            props: {
              asset: {
                bkba: 1,
                type: 'image-compositor',
                zoom: 1,
                crop: [0, 0, 0, 0],
              },
              className: 'group-main-content--full-asset',
              path: 'ReviewDetails.product.data.CompositorViews.interiorView',
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          }),
          {
            component: 'InventoryFeatureHighlights',
            key: 'InventoryFeatureHighlights',
            sections: ['overview'],
          },
          {
            component: 'AccessoriesGroup',
            key: 'CHARGING_ACCESSORIES',
            group: 'CHARGING_ACCESSORIES',
            sections: ['overview'],
            props: {
              showAsset: isCoinReloaded,
              classes: 'tds--align_center',
            },
          },
          (showRecommendedAccessories ? {
            component: 'AccessoriesGroup',
            key: 'RECOMMENDED_ACCESSORIES',
            group: 'RECOMMENDED_ACCESSORIES',
            sections: ['overview'],
            props: {
              classes: 'tds--align_center',
              listType: 'recommended',
            },
          } : {}),
          {
            component: 'WarrantyBlock',
            key: 'WarrantyBlock',
            sections: ['overview'],
          },
          {
            component: 'VehicleDetailsBlock',
            key: 'VehicleDetailsBlock',
            sections: ['overview'],
          },
          // Disabled for now re-enable when ok to show Additional Details block for New Inventory
          {
            component: 'AdditionalDetailsBlock',
            key: 'AdditionalDetailsBlock',
            sections: ['overview'],
          },
          {
            component: 'ConditionNotificationBlock',
            key: 'ConditionNotificationBlock',
            sections: ['overview'],
          },
          {
            component: 'PaymentOverviewTrigger',
            key: 'OPTIONS',
            sections: ['overview'],
            props: {
              classes: 'tds--align_center',
            },
          },
          {
            component: 'EditDesignLink',
            key: 'EditDesignLink:main.PAYMENT',
            sections: ['payment'],
            props: {
              classes: 'tds-content_container tds--padding tds--no_vertical_padding',
            },
          },
          (!isCoinReloaded && {
            component: 'InventoryAssetLoader',
            key: 'PAYMENT.assetNew',
            sections: ['payment'],
            props: {
              asset: {
                bkba: 1,
                type: 'image-compositor',
                zoom: 1,
                crop: [1300, 460, 300, 300],
              },
              path: 'ReviewDetails.product.data.CompositorViews.frontView',
              className: 'group-main-content--full-asset',
              isAsset: true,
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          }),
          isCoinReloaded ?
          {
            component: 'PaymentSummary',
            group: 'ORDER',
            key: 'PAYMENT',
            sections: ['payment'],
          } :
          {
            component: 'PaymentOverview',
            group: 'ORDER',
            key: 'PAYMENT',
            sections: ['payment'],
          },
        ],
      },
    },
    {
      selected_by: {
        isEnterpriseOrder: true,
        isInventory: true,
        isUsedInventory: false,
        isProductWithOverview: true,
      },
      object: {
        gallery: [
          {
            component: 'OrderNavigation',
            key: 'OrderNavigation:Enterprise',
            sections: ['overview', 'payment'],
          },
          {
            component: 'MainGallery',
            key: 'MainGallery:Enterprise:gallery.EXTERIOR',
            group: 'EXTERIOR_GALLERY',
            sections: ['overview'],
          },
          {
            component: 'OrderOverview',
            key: 'OrderOverviewList',
            sections: ['payment'],
          },
          {
            component: 'MiniConfigurator',
            key: 'MiniConfigurator',
            sections: ['payment'],
          },
        ],
        main: [
          {
            component: 'InventoryAssetLoader',
            key: 'OVERVIEW.asset.frontViewNew',
            sections: ['overview'],
            props: {
              asset: {
                bkba: 1,
                type: 'image-compositor',
                zoom: 1,
                crop: [1300, 500, 300, 300],
              },
              className: 'group-main-content--full-asset',
              path: 'ReviewDetails.product.data.CompositorViews.frontView',
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          },
          {
            component: 'InventorySummary',
            key: 'InventorySummary',
            sections: ['overview'],
          },
          {
            component: 'InventoryAssetLoader',
            key: 'OVERVIEW.asset.interiorViewNew',
            sections: ['overview'],
            props: {
              asset: {
                bkba: 1,
                type: 'image-compositor',
                zoom: 1,
                crop: [0, 0, 0, 0],
              },
              className: 'group-main-content--full-asset',
              path: 'ReviewDetails.product.data.CompositorViews.interiorView',
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          },
          {
            component: 'InventoryFeatureHighlights',
            key: 'InventoryFeatureHighlights',
            sections: ['overview'],
          },
          {
            component: 'AccessoriesGroup',
            key: 'CHARGING_ACCESSORIES',
            group: 'CHARGING_ACCESSORIES',
            sections: ['overview'],
            props: {
              showAsset: isCoinReloaded,
              classes: 'tds--align_center',
            },
          },
          {
            component: 'WarrantyBlock',
            key: 'WarrantyBlock',
            sections: ['overview'],
          },
          {
            component: 'VehicleDetailsBlock',
            key: 'VehicleDetailsBlock',
            sections: ['overview'],
          },
          {
            component: 'PaymentOverviewTrigger',
            key: 'OPTIONS',
            sections: ['overview'],
            props: {
              classes: 'tds--align_center',
            },
          },
          {
            component: 'OrderOverview',
            key: 'OrderOverviewList',
            sections: ['payment'],
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          },
          {
            component: 'OrderSummary',
            key: 'Enterprise:OrderSummary',
            sections: ['payment'],
            props: {
              classes: 'tds--align_center',
            },
            selected_by: {
              and: [
                {
                  on: ['desktop'],
                },
              ],
            },
          },
          {
            component: 'OrderSummary',
            key: 'PAYMENT',
            sections: ['payment'],
            props: {
              classes: 'tds--vertical_padding',
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          },
        ],
      },
    },
    {
      selected_by: {
        isEnterpriseOrder: true,
      },
      object: {
        gallery: [
          {
            component: 'OrderNavigation',
            key: 'OrderNavigation:Enterprise',
            sections: ['overview', 'payment'],
          },
          {
            component: 'MainGallery',
            key: 'MainGallery:gallery.FSD',
            group: 'FSD_GALLERY',
            excludeGroup: 'BATTERY_AND_DRIVE',
            sections: ['overview'],
          },
          {
            component: 'MainGallery',
            key: 'MainGallery:gallery.EXTERIOR',
            group: 'EXTERIOR_GALLERY',
            sections: ['overview'],
          },
          {
            component: 'OrderOverview',
            key: 'OrderOverviewList',
            sections: ['payment'],
          },
          {
            component: 'MiniConfigurator',
            key: 'MiniConfigurator',
            sections: ['payment'],
          },
          {
            component: 'MainGallery',
            key: 'MainGallery:gallery.GENERIC',
            group: 'GENERIC_GALLERY',
            excludeGroup: 'BATTERY_AND_DRIVE',
            sections: isCoinReloaded ? ['overview', 'payment'] : ['overview'],
          },
        ],
        main: [
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.BATTERY_AND_DRIVE',
            group: 'BATTERY_AND_DRIVE',
            sections: ['overview'],
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.PAINT',
            sections: ['overview'],
            group: 'PAINT',
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.WHEELS',
            sections: ['overview'],
            group: 'WHEELS',
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.TOWING',
            sections: ['overview'],
            group: ['TOWING', 'TOW_PACKAGE'],
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.PACKAGES',
            sections: ['overview'],
            group: 'PACKAGES',
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.INTERIOR',
            sections: ['overview'],
            group: 'INTERIOR'
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.AUTOPILOT_PACKAGE',
            sections: ['overview'],
            group: 'AUTOPILOT_PACKAGE',
          },
          {
            component: 'LexiconGroup',
            key: 'LexiconGroup:main.DRIVE_ANYWHERE_PACKAGE',
            sections: ['overview'],
            group: 'DRIVE_ANYWHERE_PACKAGE',
          },
          {
            component: 'AccessoriesGroup',
            key: 'CHARGING_ACCESSORIES',
            group: 'CHARGING_ACCESSORIES',
            sections: ['overview'],
            props: {
              showAsset: isCoinReloaded,
              classes: 'tds--align_center',
            },
          },
          {
            component: 'PaymentOverviewTrigger',
            key: 'OPTIONS',
            sections: ['overview'],
            props: {
              classes: 'option-widget--container tds--align_center',
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          },
          {
            component: 'PaymentOverviewTrigger',
            key: 'OPTIONS',
            sections: ['overview'],
            selected_by: {
              and: [
                {
                  on: ['desktop'],
                },
              ],
            },
          },
          {
            component: 'OrderOverview',
            key: 'OrderOverviewList',
            sections: ['payment'],
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          },
          {
            component: 'OrderSummary',
            key: 'Enterprise:OrderSummary',
            sections: ['payment'],
            props: {
              classes: 'tds--align_center',
            },
            selected_by: {
              and: [
                {
                  on: ['desktop'],
                },
              ],
            },
          },
          {
            component: 'OrderSummary',
            key: 'PAYMENT',
            sections: ['payment'],
            props: {
              classes: 'tds--vertical_padding',
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          },
        ],
      },
    },
    {
      selected_by: {
        isPostOrderSwap: true,
        isInventory: true,
        isUsedInventory: false,
        isUsedPhotos: false,
      },
      object: {
        activeGroupId: 'InventorySummary',
        main: [
          (!isCoinReloaded && {
            component: 'InventoryAssetLoader',
            key: 'OVERVIEW.asset.frontViewNew',
            sections: ['overview'],
            props: {
              asset: {
                bkba: 1,
                type: 'image-compositor',
                zoom: 1,
                crop: [1300, 500, 300, 300],
              },
              className: 'group-main-content--full-asset',
              path: 'ReviewDetails.product.data.CompositorViews.frontView',
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          }),
          {
            component: 'InventorySummary',
            key: 'InventorySummary',
            sections: ['overview'],
          },
          (!isCoinReloaded && {
            component: 'InventoryAssetLoader',
            key: 'OVERVIEW.asset.interiorViewNew',
            sections: ['overview'],
            props: {
              asset: {
                bkba: 1,
                type: 'image-compositor',
                zoom: 1,
                crop: [0, 0, 0, 0],
              },
              className: 'group-main-content--full-asset',
              path: 'ReviewDetails.product.data.CompositorViews.interiorView',
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          }),
          {
            component: 'EditDesignLink',
            key: 'EditDesignLink:main.PAYMENT',
            sections: ['payment'],
            props: {
              classes: 'tds-content_container tds--padding tds--no_vertical_padding',
            },
          },
          {
            component: 'InventoryFeatureHighlights',
            key: 'InventoryFeatureHighlights',
            sections: ['overview'],
          },
          {
            component: 'CompareDesign',
            key: 'CompareDesign',
            sections: ['overview'],
          },
          {
            component: 'WarrantyBlock',
            key: 'WarrantyBlock',
            sections: ['overview'],
          },
          {
            component: 'AdditionalDetailsBlock',
            key: 'AdditionalDetailsBlock',
            sections: ['overview'],
          },
          {
            component: 'InventoryDeliverySelection',
            key: 'InventoryDeliverySelection',
            sections: ['overview'],
          },
          (!isCoinReloaded && {
            component: 'PaymentOverviewTrigger',
            key: 'OPTIONS',
            sections: ['overview'],
            props: {
              classes: 'tds--align_center',
            },
          }),
          (!isCoinReloaded && {
            component: 'InventoryAssetLoader',
            key: 'PAYMENT.assetNew',
            sections: ['payment'],
            props: {
              asset: {
                bkba: 1,
                type: 'image-compositor',
                zoom: 1,
                crop: [1300, 460, 300, 300],
              },
              path: 'ReviewDetails.product.data.CompositorViews.frontView',
              className: 'group-main-content--full-asset',
              isAsset: true,
            },
            selected_by: {
              or: [
                {
                  on: ['tablet', 'mobile'],
                },
              ],
            },
          }),
          isCoinReloaded ?
          {
            component: 'PaymentSummary',
            group: 'ORDER',
            key: 'PAYMENT',
            sections: ['payment'],
          } :
          {
            component: 'PaymentOverview',
            group: 'ORDER',
            key: 'PAYMENT',
            sections: ['payment'],
          },
          {
            component: 'PostOrderSwapConfirmation',
            key: 'PostOrderSwapConfirmation',
            sections: ['confirmation'],
          },
        ],
      },
    },
  ],
};

export default InitialState;
