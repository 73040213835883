import React from 'react';
import { connect } from 'react-redux';
import { string, bool, number } from 'prop-types';
import _get from 'lodash/get';
import classnames from 'classnames';

import { i18n, getMonthYear } from 'utils';
import { WARRANTY_MODAL, DEMO, AUTO_TYPE_CUSTOM } from 'dictionary';

import InfoBlock from '../InfoBlock';
import TextLoader from '../TextLoader';

import { formatNumber } from '@tesla/coin-common-components';

const WarrantyBlock = ({
  condition,
  isUsedInventory,
  WarrantyBatteryExpDate,
  WarrantyBatteryIsExpired,
  WarrantyBatteryMile,
  WarrantyBatteryYear,
  WarrantyMile,
  WarrantyVehicleExpDate,
  WarrantyVehicleIsExpired,
  UsedVehicleLimitedWarrantyMile,
  UsedVehicleLimitedWarrantyYear,
  WarrantyYear,
  locale,
  isLayoutMobile,
  IsDemo,
  enableCyberpunk,
  isCoinReloaded,
}) => {
  const limited = WarrantyVehicleIsExpired ? '' : 'limited';
  const mileageUnits = i18n('Inventory.common.mileageUnits');
  const isValidWarrantyExpDate =
    WarrantyVehicleExpDate && new Date(WarrantyVehicleExpDate).getTime() > new Date().getTime();
  const expiration = isValidWarrantyExpDate ? (IsDemo ? DEMO : condition) : AUTO_TYPE_CUSTOM;
  const isValidBatteryWarrantyExpDate =
    WarrantyBatteryExpDate && new Date(WarrantyBatteryExpDate).getTime() > new Date().getTime();
  const batteryExpiration = isValidBatteryWarrantyExpDate
    ? IsDemo
      ? DEMO
      : condition
    : AUTO_TYPE_CUSTOM;
  const usedLimitedWarrantyMile =
    locale === 'zh_CN' && isUsedInventory && !UsedVehicleLimitedWarrantyMile
      ? 20000
      : UsedVehicleLimitedWarrantyMile;
  const usedLimitedWarrantyYear =
    locale === 'zh_CN' && isUsedInventory && !UsedVehicleLimitedWarrantyYear
      ? 1
      : UsedVehicleLimitedWarrantyYear;

  const vehicleWarranty = {
    title: i18n('Inventory.warranties.label', null, null, { specificOverride: condition }),
    description: i18n(
      'Inventory.warranties.expiration',
      {
        DATE: getMonthYear(WarrantyVehicleExpDate, locale),
        MILEAGE: formatNumber(WarrantyMile),
        UNITS: mileageUnits,
        YEARS: WarrantyYear,
      },
      null,
      { specificOverride: expiration }
    ),
  };
  const vehicleWarrantyStartDate = {
    description: i18n('Inventory.warranties.usedStartDate'),
  };

  const usedVehicleWarranty = {
    title: i18n('Inventory.warranties.usedLabel'),
    description: i18n(
      'Inventory.warranties.usedExpiration',
      {
        MILEAGE: formatNumber(usedLimitedWarrantyMile),
        UNITS: mileageUnits,
        YEARS: usedLimitedWarrantyYear,
      },
      null,
      { specificOverride: limited }
    ),
  };
  const usedVehicleWarrantyStartDate = {
    description: i18n('Inventory.warranties.usedStartDate', null, null, {
      specificOverride: limited,
    }),
  };

  const batteryWarranty = {
    title: i18n('Inventory.warranties.batteryDriveWarranty'),
    description: i18n(
      WarrantyBatteryMile
        ? 'Inventory.warranties.expiration'
        : 'Inventory.warranties.expirationBatteryUnlimited',
      {
        DATE: getMonthYear(WarrantyBatteryExpDate, locale),
        MILEAGE: formatNumber(WarrantyBatteryMile),
        UNITS: mileageUnits,
        YEARS: WarrantyBatteryYear,
      },
      null,
      { specificOverride: batteryExpiration }
    ),
  };

  return (
    <div
      className={classnames(`warranty-block-container`, {
        'tds--vertical_padding--6x': isLayoutMobile && !isCoinReloaded,
      })}
    >
      <InfoBlock
        name="warranty"
        titleSection={i18n('Inventory.warranties.label')}
        titleSectionClass={classnames({ 'tds-o-no_padding_bottom' : !enableCyberpunk })}
        buttonLabel={i18n('Inventory.common.learnMore')}
        buttonClass={`tds-btn--tertiary${enableCyberpunk ? '' : ' tds-btn--medium'}`}
        componentName={WARRANTY_MODAL}
        analyticsInteraction="learn-more-warranty"
        contentsClass={classnames({ 'tds--vertical_padding--small' : enableCyberpunk })}
      >
        <If condition={!WarrantyVehicleIsExpired}>
          <TextLoader data={vehicleWarranty} className="text-loader--no-padding-top " />
          <If condition={!isUsedInventory && !IsDemo}>
            <TextLoader data={vehicleWarrantyStartDate} className="text-loader--no-padding-top " />
          </If>
        </If>
        <If condition={isUsedInventory}>
          <TextLoader data={usedVehicleWarranty} />
          <TextLoader data={usedVehicleWarrantyStartDate} />
        </If>
        <If condition={!WarrantyBatteryIsExpired}>
          <TextLoader
            data={batteryWarranty}
            className={classnames({ 'tds--padding_top-16': enableCyberpunk })}
          />
        </If>
      </InfoBlock>
    </div>
  );
};

WarrantyBlock.propTypes = {
  condition: string.isRequired,
  isUsedInventory: bool.isRequired,
  WarrantyBatteryExpDate: string.isRequired,
  WarrantyBatteryIsExpired: bool.isRequired,
  WarrantyBatteryMile: number.isRequired,
  WarrantyBatteryYear: number.isRequired,
  WarrantyMile: number.isRequired,
  WarrantyVehicleExpDate: string.isRequired,
  WarrantyVehicleIsExpired: bool.isRequired,
  UsedVehicleLimitedWarrantyMile: number.isRequired,
  UsedVehicleLimitedWarrantyYear: number.isRequired,
  WarrantyYear: number.isRequired,
  locale: string.isRequired,
  isLayoutMobile: bool,
  IsDemo: bool,
};

WarrantyBlock.defaultProps = {
  isLayoutMobile: false,
  IsDemo: false,
};

function mapStateToProps(state) {
  const product = _get(state, 'ReviewDetails.product', {});
  const { App } = state;
  const { locale, isLayoutMobile, enableCyberpunk, isCoinReloaded } = App;
  const {
    condition,
    data: { WarrantyData = {}, IsDemo = false },
    isUsedInventory,
  } = product;

  const {
    WarrantyBatteryExpDate,
    WarrantyBatteryIsExpired,
    WarrantyBatteryMile,
    WarrantyBatteryYear,
    WarrantyMile,
    WarrantyVehicleExpDate,
    WarrantyVehicleIsExpired,
    UsedVehicleLimitedWarrantyMile,
    UsedVehicleLimitedWarrantyYear,
    WarrantyYear,
  } = WarrantyData;

  return {
    condition,
    isUsedInventory,
    WarrantyBatteryExpDate,
    WarrantyBatteryIsExpired,
    WarrantyBatteryMile,
    WarrantyBatteryYear,
    WarrantyMile,
    WarrantyVehicleExpDate,
    WarrantyVehicleIsExpired,
    UsedVehicleLimitedWarrantyMile,
    UsedVehicleLimitedWarrantyYear,
    WarrantyYear,
    locale,
    isLayoutMobile,
    IsDemo,
    enableCyberpunk,
    isCoinReloaded,
  };
}

export default connect(mapStateToProps)(WarrantyBlock);
