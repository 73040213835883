import React, { useEffect } from 'react';
import { number, bool, string} from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { Icon, Tooltip, TooltipWrapper, useTooltipState } from '@tesla/design-system-react';
import { iconInfo } from '@tesla/design-system-icons';
import { i18n } from 'utils';
import { getPrice, getActiveFinanceProducts, getTaxCreditCurrentAvailable } from 'selectors';
import Analytics from 'analytics';
import { formatCurrency } from '@tesla/coin-common-components';
import { SUMMARY_PANEL_LEASE_TAB } from 'dictionary';

import InfoBlock from '../InfoBlock';

const FederalIncentiveChip = ({
  Discount,
  Model,
  price,
  showBlock,
  showVariant,
  federalTaxCredit,
  isUsedInventory,
  tradeInOperator,
  hasLeaseProduct,
}) => {
  if (!showBlock) {
    return null;
  }

  useEffect(() => {
    Analytics.fireTagEvent({
      event: Analytics.event,
      interaction: federalTaxCredit ? 'ftc-qualification' : 'not-qualify-federal-tax-credit',
      'cfg-type': `inventory-${Model}`,
    });
  }, []);

  const { open, handlers, wrapperRef } = useTooltipState();

  return (
    <>
      <InfoBlock
        name="taxCredit"
        title={i18n('Review.vehicle_price_amount', {PRICE: formatCurrency(price)})}
        titleClass="tds-text--h4 tds-o-vertical_padding-top--3x"
        centered
        containerClass="tds--vertical_padding"
      >
        <div className="tds-text--caption">
          <If condition={Discount}>
            <div>{i18n('Review.priceAdjustment', {DISCOUNT: formatCurrency(Discount)})}</div>
          </If>
          <div>
            {i18n('Incentives.federalIncentive.taxAndFeesDisclaimer', null, null, {
              specificOverride: [tradeInOperator],
            })}
          </div>
          <Choose>
            <When condition={federalTaxCredit}>
              <div className="tds--vertical_padding--large">
                <Choose>
                  <When condition={!showVariant}>
                    {/* Unable to use tooltip within the tds-chip */}
                    <div className="result-gallery-chip-container tds-text--center">
                      <span className="tds-text--contrast-high tds-text--medium">{i18n('Incentives.federalIncentive.priceAfterTaxCredit', {PRICE: formatCurrency(price + federalTaxCredit)})}</span>
                      <TooltipWrapper 
                        {...handlers}
                        wrapperRef={wrapperRef}
                        inline
                      >
                        <span>
                          <Icon
                            align="text-middle" 
                            className="chip-icon--info"
                            data={iconInfo}
                            inline
                          />
                        </span>
                        <Tooltip open={open}>
                          <div className="tds-text--body">
                            {i18n('Incentives.federalIncentive.disclaimerExcludingFees', {AMOUNT: formatCurrency(-federalTaxCredit)}, null, {
                              specificOverride: [tradeInOperator],
                            })}
                          </div>
                        </Tooltip>
                      </TooltipWrapper>
                    </div>
                  </When>
                  <Otherwise>
                    <span className="tds-chip tds-o-chip">
                      <span className="tds-text--contrast-high">{i18n('Incentives.federalIncentive.creditAtPurchase', {AMOUNT: formatCurrency(-federalTaxCredit)})}</span>
                    </span>
                  </Otherwise>
                </Choose>
              </div>
            </When>
            <Otherwise>
              <Choose>
                <When condition={isUsedInventory}>
                  <div className="tds--vertical_padding">
                    <span className="tds-chip tds-o-chip">
                    <span className="tds-text--contrast-high tds-text--medium">{i18n('Incentives.federalIncentive.disclaimerNotQualified__used')}</span>
                    </span>
                  </div>
                </When>
                <Otherwise>
                  <div className="tds--vertical_padding">
                    <span className="tds-chip tds-o-chip">
                    <span className="tds-text--contrast-high tds-text--medium">
                      {i18n(hasLeaseProduct ? 'Incentives.federalIncentive.disclaimerNotQualifiedLease__new' : 'Incentives.federalIncentive.disclaimerNotQualifiedNoLease__new')}</span>
                    </span>
                </div>
                </Otherwise>
              </Choose>
            </Otherwise>
        </Choose>
        </div>
      </InfoBlock>
    </>
  );
};

const mapStateToProps = state => {
  const { App = {}, ReviewDetails = {}, TradeIn } = state;
  const { isEnterpriseOrder = false, isTradeInEnabled = false } = App;
  const { product = {}, showIncentiveBlock = [] } = ReviewDetails;
  const { data = {}, isUsedInventory } = product || {};
  const { Discount = 0, Model = '' } = data || {};

  const federalTaxCredit = getTaxCreditCurrentAvailable(state);
  const showVariant = window?.showPosV2 || false;
  const { tradeInAmount } = TradeIn || {};
  const tradeInOperator = isTradeInEnabled && tradeInAmount > 0 ? 'tradein' : '';
  const price = getPrice(state, 'cash', {cashPriceValue: true}) || 0;
  const showBlock = price && !isEnterpriseOrder && showIncentiveBlock?.includes(Model);

  const contents = [i18n('Incentives.federalIncentive.taxAndFeesDisclaimer', null, null, {
    specificOverride: [tradeInOperator],
  })];
  Discount && contents.unshift(i18n('Review.priceAdjustment', {DISCOUNT: formatCurrency(Discount)}));

  const activeFinanceProducts = getActiveFinanceProducts(state);
  const hasLeaseProduct = !!activeFinanceProducts?.find((product) => product?.toLowerCase()?.includes(SUMMARY_PANEL_LEASE_TAB));

  return {
    contents,
    Discount,
    Model,
    price,
    showBlock,
    showVariant,
    federalTaxCredit,
    isUsedInventory,
    tradeInOperator,
    hasLeaseProduct,
  };
};

FederalIncentiveChip.propTypes = {
  Model: string,
  price: number || string,
  showBlock: bool.isRequired,
  showVariant: bool,
  federalTaxCredit: number,
  isUsedInventory: bool,
  tradeInOperator: string,
  hasLeaseProduct: bool,
};

FederalIncentiveChip.defaultProps = {
  IsTaxIncentiveEligible: false,
  Model: '',
  price: 0,
  showVariant: false,
  federalTaxCredit: 0,
  TaxIncentiveAmount: 0,
  isUsedInventory: false,
  tradeInOperator: '',
  hasLeaseProduct: true,
};

export default connect(mapStateToProps)(FederalIncentiveChip);