import React from 'react';
import { string, shape, bool } from 'prop-types';
import TextLoader from '../TextLoader';

const InfoHeader = ({
  title,
  secondaryTitle,
  pricing,
  secondaryBadge,
}) => {
  return (
    <div className="coin-vehicle-card--header">
      <div className="coin-vehicle-card--details tds-layout tds-o-layout--grid tds-layout-2col tds-layout--no_offset">
        <div className="tds-layout-item tds-text--contrast-high tds-text--medium">
          <If condition={title}>
            <TextLoader data={title} />
          </If>
        </div>
        <div className="tds-layout-item tds-text--contrast-high tds-text--medium tds-o-text-align--right">
          {pricing}
        </div>
      </div>
      <If condition={secondaryTitle}>
        <div className="coin-vehicle-card--subdetails tds-layout tds-o-layout--grid tds-layout--no_offset">
          <div className="tds-layout-item tds-text--contrast-low">
            <TextLoader data={secondaryTitle} />
          </div>
        </div>
      </If>
      <If condition={secondaryBadge}>
        <div className="coin-vehicle-card--subdetails tds-layout tds-o-layout--grid tds-layout--no_offset">
          <div className="tds-layout-item tds-text--contrast-low">
            <TextLoader data={secondaryBadge} />
          </div>
        </div>
      </If>
    </div>
  );
};

InfoHeader.propTypes = {
  title: string,
  secondaryTitle: string,
  pricing: string,
  secondaryBadge: string,
};

InfoHeader.defaultProps = {
  title: '',
  secondaryTitle: '',
  pricing: '',
  secondaryBadge: '',
};

export default InfoHeader;
