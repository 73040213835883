import _get from 'lodash/get';
import _omitBy from 'lodash/omitBy';
import _isArray from 'lodash/isArray';
import _has from 'lodash/has';
import _isNumber from 'lodash/isNumber';
import _intersection from 'lodash/intersection';
import _reduce from 'lodash/reduce';
import { formatCurrency, formatMonthlyPrice } from '@tesla/coin-common-components';
import { i18n, reverseFormatCurrencyBasic } from 'utils';
import { getCurrentModel, isPreOrder } from './index';
import {
  PRICE_INDICATOR_HIDDEN,
  BASE_PLUS_TRIM,
  CONTEXT_DEFAULT,
  CONTEXT_CONFIGURATOR,
} from 'dictionary';

export function getAllPricingContexts(contextsToExclude = []) {
  const currentModel = getCurrentModel();
  const contexts = _get(
    window,
    `tesla.DSServices["Lexicon.${currentModel}"].metadata.pricing.context_mapping`,
    {}
  );
  return _omitBy(contexts, value => contextsToExclude.indexOf(value) !== -1);
}

export function getCustomPricingIndicatorForTrim(
  res,
  option,
  includeFeesinBatteryCalculation = false,
  useOptionPriceOverridePayment,
  configuration,
  priceData,
  hideIndicatorOnZeroPrice = false
) {
  const pricingOverride =
    !includeFeesinBatteryCalculation && !useOptionPriceOverridePayment
      ? _get(option, 'price_indicator_override')
      : _get(option, 'option_price_override');

  let pricingOverride2 =
    _isArray(pricingOverride) && pricingOverride.length ? pricingOverride[0] : pricingOverride;
  const conditionState2 = _get(pricingOverride2, 'condition_state', 'and');
  const indicatorArr = !!_intersection(_get(pricingOverride2, 'configuration', []), configuration)
    .length;
  let indicatorOption = conditionState2 === 'and' ? indicatorArr : !indicatorArr;

  if (_get(pricingOverride, 'selected_by', null)) {
    pricingOverride2 = pricingOverride;
    indicatorOption = true;
  }

  const feeTotal = includeFeesinBatteryCalculation ? _get(priceData, 'fees.total.once', 0) : false;

  if (!res && indicatorOption) {
    const priceOverride = _get(pricingOverride2, 'content', true);
    if (priceOverride === 0) {
      includeFeesinBatteryCalculation = false;
    }

    const priceIndicator = hideIndicatorOnZeroPrice
      ? PRICE_INDICATOR_HIDDEN
      : _get(option, 'price_indicator', '');

    // eslint-disable-next-line no-nested-ternary
    return includeFeesinBatteryCalculation
      ? formatCurrency(priceOverride + feeTotal)
      : priceOverride === 0
      ? priceIndicator
      : priceOverride;
  }

  return res;
}

export function getCustomPricingIndicatorV2(
  options,
  configuration,
  priceData,
  includeFeesinBatteryCalculation = false,
  useOptionPriceOverridePayment = false,
  CustomGroups,
  paymentPageOverride = false,
  hideIndicatorOnZeroPrice = false,
  pricePostfix,
) {
  if (isPreOrder()) {
    return '​'; // return zero-width space
  }

  return _reduce(
    options,
    (res, option) => {
      const { code: optCode, price_indicator = '', cash_price: price = 0, isSwap = false } = option;
      const paymentOverride = _get(option, 'base_plus_trim_pricing_payment', null);
      const optionPriceOverride = _get(option, 'option_override_pricing', null);
      const pricingObject =
        (paymentPageOverride && paymentOverride
          ? _get(option, 'base_plus_trim_pricing_payment', null)
          : _get(option, 'base_plus_trim_pricing', null)) || optionPriceOverride;
      if (!pricingObject) {
        return getCustomPricingIndicatorForTrim(
          res,
          option,
          includeFeesinBatteryCalculation,
          useOptionPriceOverridePayment,
          configuration,
          priceData,
          hideIndicatorOnZeroPrice
        );
      }
      const { showOptionPriceByFinanceType = false, financeType } = priceData || {};
      const { fees, feesStandalone, incentives, basePlusTrimPrice = price, finplat } = optionPriceOverride
        ? priceData
        : _get(priceData, `apiResultsPerTrim.${optCode}`, {});
      if (showOptionPriceByFinanceType && financeType === 'finplat') {
        return formatMonthlyPrice(finplat?.monthlyPayment, { wrapAround: true });
      }
      const vatRateForForwardCalculation = priceData?.vatRateForForwardCalculation || 1;
      const { optionPriceKey } = pricingObject || {};
      const basePlusTrim = isSwap ? option?.basePlusTrimPrice : basePlusTrimPrice;
      const baseVehiclePrice = optionPriceKey === BASE_PLUS_TRIM ? basePlusTrim : price * vatRateForForwardCalculation;
      const postfix = _get(pricingObject, 'postfix', pricePostfix || '');
      const feesPrice =
        pricingObject?.fees?.reduce((total, feesPath) => {
          return total + _get(fees, `${feesPath}`, 0);
        }, 0) || 0;
      const feesStandalonePrice =
        !paymentPageOverride && pricingObject?.fees_standalone
          ? pricingObject?.fees_standalone?.reduce((total, feesPath) => {
              return total + _get(feesStandalone, `${feesPath}`, 0);
            }, 0)
          : 0;
      let incentivesPrice =
        pricingObject?.incentive?.reduce((total, incentivePath) => {
          return total + _get(incentives, `${incentivePath}`, 0);
        }, 0) || 0;

      if (priceData?.incentives?.hidePurchasePriceIncentive && !incentives) {
        incentivesPrice += _get(priceData?.incentives, `${pricingObject?.incentive}`, 0);
      }

      const totalTrimPrice = baseVehiclePrice + feesPrice + feesStandalonePrice + incentivesPrice;
      const { currency } = priceData;
      if (currency && totalTrimPrice > 0) {
        return `${formatCurrency(totalTrimPrice, {
          currency,
          useDynamicRounding: true,
        })}${postfix}`;
      }
      return totalTrimPrice > 0 ? `${formatCurrency(totalTrimPrice)}${postfix}` : price_indicator;
    },
    false
  );
}

export function getOverrideOptionPriceV2(options, priceData) {
  return _reduce(
    options,
    (res, option) => {
      const { showOptionPriceByFinanceType = false, financeType, calcParams = {} } = priceData || {};
      const { code: optCode, cash_price: price = 0, option_price_override: optionOverride = '' } = option || {};
      const pricingObject = _get(option, 'base_plus_trim_pricing_aftersavings', null);
      if (!pricingObject) {
        if (optionOverride && typeof optionOverride === 'string') {
          return optionOverride;
        }
        return false;
      }
      const postfix = _get(pricingObject, 'postfix', '');
      const { fees, incentives, basePlusTrimPrice = price, finplat } = _get(
        priceData,
        `apiResultsPerTrim.${optCode}`,
        {}
      );
      const { optionPriceKey } = pricingObject || {};
      const baseVehiclePrice = optionPriceKey === BASE_PLUS_TRIM ? basePlusTrimPrice : price;
      if (showOptionPriceByFinanceType && financeType === 'finplat') {
        const { once = 0, fuel = 0 } = incentives?.total || {};
        const { termLength: term } = finplat || {};
        const { months } = calcParams || {};
        const fuelMonthly = Math.round(fuel / months);
        const incentivesMonthly = term ? Math.round((once) / term) || 0 : 0;
        const monthlySavings = (fuelMonthly + incentivesMonthly) || 0;
        const monthlyPaymentAfterSavings = finplat?.monthlyPayment + monthlySavings;
        return formatMonthlyPrice(monthlyPaymentAfterSavings > 0 ? monthlyPaymentAfterSavings : 0, { wrapAround: true });
      } 

      /**
       * Handles incentives total once, before delivery, total fuel
       * Using beforeDelivery.total instead of beforeDelivery.data
       */
      const incentivesPrice =
        pricingObject.incentive?.reduce((total, incentivePath) => {
          return total + _get(incentives, `${incentivePath}`, 0);
        }, 0) || 0;
      const feesPrice =
        pricingObject.fees?.reduce((total, feesPath) => {
          return total + _get(fees, `${feesPath}`, 0);
        }, 0) || 0;
      return `${formatCurrency(
        baseVehiclePrice + incentivesPrice + feesPrice
      )}${postfix}`;
    },
    false
  );
}

export function getPriceReturnedFromAgent(selectedOptionsPrice, option) {
  return _reduce(
    selectedOptionsPrice,
    (optionPrice = 0, detail) => {
      let priceVal = optionPrice;
      if (detail.code === option) {
        priceVal = detail.price;
      }
      return priceVal;
    },
    false
  );
}

export function getPricingContextCurrency({ context = CONTEXT_CONFIGURATOR, contextMap }) {
  return _get(contextMap, context, _get(contextMap, CONTEXT_DEFAULT));
}

export function addRegionalFeesToList(list, regionalFeesValues) {
  if (!regionalFeesValues) {
    return list;
  }
  const regionalFeeTypesMap = [
    'luxury_cost_tax', 'local_stamp_duty', 'local_reg_costs', 'local_ctp', 'plate_fee',
  ];
  return _reduce(regionalFeeTypesMap, (sum, val) => {
    if (_has(regionalFeesValues, val) && (regionalFeesValues[val] >= 0)) {
      sum.push({
        label: i18n(`FinancingOptions.${val}`),
        value: formatCurrency(regionalFeesValues[val]),
      });
    }
    return sum;
  }, list);
}

export function getAmountWithTaxCredit(formattedAmount, taxCredit) {
  const numericAmount = _isNumber(formattedAmount) ? formattedAmount : reverseFormatCurrencyBasic(formattedAmount);
  return formatCurrency(numericAmount + taxCredit);
}