import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { arrayOf, bool, func, string } from 'prop-types';
import cx from 'classnames';
import { i18n, htmlToReact, getOrderAgreementUrl } from 'utils';
import { isOrderProcessingFeeEnabled, isAccessoriesSelected, isInventory, isEarlyDesignSelected } from 'selectors';
import { AUTO_TYPE_CUSTOM, PAYMENT_SUMMARY } from 'dictionary';
import { Button } from '@tesla/design-system-react';

import { OrderLegalDisclaimers } from './LegalDisclaimers';
import DepositAmountTable from './DepositAmountTable';
import LegalDataTable from './LegalDataTable';
import { UI_DATA_IDS } from '../../../common/dictionary';
import LegalConsent from '../../../components/AccountForm/LegalConsent';
import DeliveryZip from '../../../components/PaymentSummary/Sections/DeliveryZip';
import {
  GIO_TAG_ENABLE,
  WEB_DESIGN_PAYMENT_SUBMIT,
  WEB_DESIGN_INVENTORY_PAYMENT_SUBMIT,
} from 'gioStatistics';

const DisclaimerSection = ({
  displayOtherEUDisclaimer,
  canModifyOrder,
  orderButtonDisabled,
  invitedOrderButtonDisabled,
  validateAndPlaceOrder,
  enableCyberpunk,
  rnLoaded,
  isReservation,
  displayDueAmount,
  showBusinessInformation,
  condition,
  showLegalDataTable,
  showSelectedAccessories,
  isInventory,
  orderAgreementUrl,
  showLegalConsentAfterPayX,
  isEarlyAvailabilityEnabled,
  market,
  isSwapDesignSelected,
}) => {
  const orderPlacementLabel = isReservation
    ? 'Review.btn_label__place_reservation'
    : 'Review.btn_label__place_order';
  const submitOrderButtonGioEventName = isInventory
    ? WEB_DESIGN_INVENTORY_PAYMENT_SUBMIT
    : WEB_DESIGN_PAYMENT_SUBMIT;
  return (
      <>
        <If condition={isEarlyAvailabilityEnabled && !isSwapDesignSelected}>
          <DeliveryZip source={PAYMENT_SUMMARY} />
        </If>
        <If condition={showLegalConsentAfterPayX.length}>
          <div className="tds--vertical_padding-bottom">
            <LegalConsent orderAgreementUrl={orderAgreementUrl} showLegalConsentAfterPayX={showLegalConsentAfterPayX} />
          </div>
        </If>
      <div
        className={cx('tds-o-vertical_padding-top--3x', {
          'tds--padding_top-8': showSelectedAccessories,
          'tds--border_top': !enableCyberpunk && market !== 'CN',
        })}
      >
          <If condition={displayOtherEUDisclaimer}>
            <p className="order-disclaimer">{htmlToReact(i18n('SummaryPanel.disclaimers.otherEU'))}</p>
          </If>
          <If condition={showLegalDataTable}>
            <LegalDataTable />
          </If>
          <section className="review-page--pricing">
            <div className="pricing-container">
              <If condition={!canModifyOrder && displayDueAmount}>
                <div className="items payment-due">
                  <DepositAmountTable bolded={enableCyberpunk} />
                </div>
              </If>
              <div className="tds--vertical_padding tds-text--caption">
                <OrderLegalDisclaimers hasBTCDisclaimer={!displayDueAmount} />
              </div>
              <div className="items payment-due">
                <Choose>
                  <When condition={rnLoaded}>
                    <Button
                        variant="primary"
                        height="large"
                        width="full"
                        disabled={invitedOrderButtonDisabled}
                        onClick={validateAndPlaceOrder}
                        data-id={UI_DATA_IDS?.paymentPage?.placeOrderBtn}
                        data-subtype="btn-order--primary"
                        type="button"
                        className="payment-order-button"
                    >
                      {i18n('Review.btn_label__place_order')}
                    </Button>
                  </When>
                  <Otherwise>
                    <Button
                        variant="primary"
                        height="large"
                        width="full"
                        disabled={orderButtonDisabled}
                        onClick={validateAndPlaceOrder}
                        data-id={UI_DATA_IDS?.paymentPage?.placeOrderBtn}
                        data-subtype="btn-order--primary"
                        data-gio-track={GIO_TAG_ENABLE}
                        data-gio-eventname={submitOrderButtonGioEventName}
                        type="button"
                        className="payment-order-button"
                    >
                      {i18n(orderPlacementLabel)}
                    </Button>
                  </Otherwise>
                </Choose>
              </div>
              <If condition={showBusinessInformation}>
                <div className="tds--vertical_padding">
                  <p className="tds-text--caption">
                    {htmlToReact(
                        i18n('SummaryPanel.disclaimers.businessInformation', null, null, {
                          specificOverride: condition,
                        })
                    )}
                  </p>
                </div>
              </If>
            </div>
          </section>
        </div>
      </>
  );
};

DisclaimerSection.propTypes = {
  displayOtherEUDisclaimer: bool,
  canModifyOrder: bool.isRequired,
  orderButtonDisabled: bool.isRequired,
  enableCyberpunk: bool.isRequired,
  invitedOrderButtonDisabled: bool.isRequired,
  validateAndPlaceOrder: func.isRequired,
  rnLoaded: bool.isRequired,
  isReservation: bool,
  displayDueAmount: bool,
  condition: string,
  showBusinessInformation: bool,
  showLegalDataTable: bool,
  showSelectedAccessories: bool,
  isInventory: bool,
  orderAgreementUrl: string.isRequired,
  showLegalConsentAfterPayX: arrayOf(string),
  market: string,
  isSwapDesignSelected: bool,
};

DisclaimerSection.defaultProps = {
  displayOtherEUDisclaimer: false,
  isReservation: false,
  displayDueAmount: true,
  condition: '',
  showBusinessInformation: false,
  showLegalDataTable: false,
  enableCyberpunk: false,
  showSelectedAccessories: false,
  showLegalConsentAfterPayX: [],
  market: '',
  isSwapDesignSelected: false,
};

function mapStateToProps(state) {
  const { ApplicationFlow, Payment, ReviewDetails, App } = state;
  const { canModifyOrder } = ApplicationFlow;
  const { nonRefundPolicyDisclaimer = false, product = {}, showLegalDataTable } = ReviewDetails;
  const { isReservation = false } = product || {};
  const { displayOtherEUDisclaimer = false } = Payment;
  const { enableCyberpunk, isEarlyAvailabilityEnabled } = App;

  return {
    canModifyOrder,
    displayOtherEUDisclaimer,
    showOrderFeeDisclaimer: isOrderProcessingFeeEnabled(state),
    showNonRefundableDisclaimer: nonRefundPolicyDisclaimer,
    enableCyberpunk,
    isReservation,
    condition: _get(state, 'ReviewDetails.product.condition', '') || AUTO_TYPE_CUSTOM,
    showBusinessInformation: _get(state, 'ReviewDetails.showBusinessInformation', false),
    showLegalDataTable,
    showSelectedAccessories: isAccessoriesSelected(state),
    isInventory: isInventory(state),
    orderAgreementUrl: getOrderAgreementUrl(state),
    showLegalConsentAfterPayX: _get(state, 'ReviewDetails.showLegalConsentAfterPayX', []),
    isEarlyAvailabilityEnabled,
    isSwapDesignSelected: isEarlyDesignSelected(state),
  };
}

export default connect(mapStateToProps)(DisclaimerSection);
