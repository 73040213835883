/* eslint-disable jsx-control-statements/jsx-jcs-no-undef */
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import _isString from 'lodash/isString';
import { request } from 'utils/requestAgent';

import {
  GOOGLE_GEOCODE_SUCCESS,
  GOOGLE_GEOCODE_FAIL,
  GOOGLE_GEOCODE_REQUEST,
  LOCATION_SOURCE_CHANGE,
  UPDATE_AVAILABILITY_TIMIING,
  FETCH_DELIVERY_TIMING,
  SET_VEHICLE_DESIGN,
} from 'dictionary';
import { memoize } from 'utils';

/**
 * Get Country / City / State for a specified zipcode
 * M3 orders will be limited for some time after initial release
 *
 * for M3 Configurator only, will be a temporary endpoint that will be
 * used in lieu of official deliveryDate until TBD
 *
 * @see TWS-13459
 * @return {Object} [geocode response from API]
 */
export const getLocationByZip = address => (dispatch, getState) => {
  const state = getState();
  const market = 'US';
  // _get(state, 'OMS.oms_params.market')
  let addressVal = address;
  if (!addressVal) {
    addressVal = _get(state, 'Location.components.registration.zipCode');
  }

  addressVal = `${addressVal}, ${market}`;

  if (geocoder) {
    _debounce(() => {
      dispatch({
        type: GOOGLE_GEOCODE_REQUEST,
      });

      geocoder.geocode(
        {
          addressVal,
        },
        (results, status) => {
          if (status === 'OK') {
            dispatch({
              type: LOCATION_SOURCE_CHANGE,
              sourceName: 'geocode_by_zip',
              sourceData: results,
            });
            dispatch({
              type: GOOGLE_GEOCODE_SUCCESS,
              results,
            });
          } else {
            dispatch({
              type: GOOGLE_GEOCODE_FAIL,
              error: status,
            });
          }
        }
      );
    }, 1000);
  } else {
    // initGeoCoder(dispatch)
  }
};

export const deliverystateupdate = address => ({
  type: 'DELIVERY_STATE_ADDRESS',
  address,
});
export const changeDeliveryLocation = isOpen => ({
  type: 'CHANGE_DELIVERY_LOCATION',
  isOpen,
});

export const getGeocodeByLocation = (locationObj, cb) => {
  const { postal_code, country } = locationObj;
  if (!postal_code || !country) {
    return cb('Postal code or country missing', null);
  }
  const { routes = {} } = window.tesla;
  const { getAddress = '/configurator/api/v1/address' } = routes;
  request
    .post(getAddress)
    .type('form')
    .send({ postal_code, country_code: country })
    .set('Accept', 'application/json')
    .end((err, res) => {
      if (!err && res.statusCode === 200) {
        try {
          const responseBody = _isString(res.body) && res.body ? JSON.parse(res.body) : res.body;
          const data =
            _isString(responseBody.data) && responseBody.data
              ? JSON.parse(responseBody.data)
              : responseBody.data;
          const code = responseBody?.code || null;
          if (code !== 200) {
            return cb('Invalid payload', null);
          }
          return cb(null, data);
        } catch (e) {
          return cb(e, null);
        }
      } else {
        return cb(err, null);
      }
    });
};

export const getInventoryAvailability = (payload, cb) => {
  const { routes = {}, product, isInventorySwapEnabled = false } = window.tesla || {};
  // temporarily disable the get Available inventory function call
  // leaving if we want to re-enable in the future
  if (product || isInventorySwapEnabled || true) {
    return cb(null, null);
  }
  const { inventoryAvailability = '/configurator/api/v3/is-inventory-available' } = routes;
  request
    .post(inventoryAvailability)
    .type('form')
    .send(payload)
    .set('Accept', 'application/json')
    .end((err, res) => {
      if (!err && res.statusCode === 200) {
        try {
          const responseBody = res?.body;
          return cb(null, responseBody);
        } catch (e) {
          return cb(e, null);
        }
      } else {
        return cb(err, null);
      }
    });
};

export const setIsLoading = flag => ({
  type: FETCH_DELIVERY_TIMING,
  flag,
});

export const getDeliveryData = async (url, payload, timeout) => {
  let result;
  try {
    const response = await request.post(url).send(payload).timeout({ response: timeout, deadline: timeout }).retry(1);
    result = response?.body || {};
  } catch (error) {
    result = { error };
    console.warn('Failed to get availability results', error);
  }
  return result;
};

export const getEarlyAvailability = ({ fetchSimiliarDesigns = false, retry = 0 } = {}) => {
  return async (dispatch, getState) => {
    let result = {};
    const {
      App,
      SummaryPanel: { region_code } = {},
      Configuration: { option_string } = {},
      Pricing,
      Location,
      OMS,
      ReviewDetails: {
        DeliveryDetails,
        deliveryZipGalleryStates = [],
        vehicleDesign: {
          initialDesign = {},
          selectedConfig,
          error: swapError
        } = {},
      } = {},
      CustomGroups: {
        TRIM = {},
        PAINT = {},
        WHEELS = {},
        INTERIOR_PACKAGE = {},
        STEERING_WHEEL = {},
      } = {}
    } = getState();
    const {
      routes = {},
      isEarlyAvailabilityEnabled = false,
    } = App || {};
    const { availabilityMatch } = routes || {};
    const { market, model, language } = OMS?.oms_params || {};
    const { latitude = '', longitude = '' } = Location?.sources?.geoIp?.location || {};
    const { Latitude: lat, Longitude: lon, stateCode, error } = DeliveryDetails || {};
    if (error) {
      return;
    }
    const regionCode = stateCode || region_code;
    const payload = {
      country: market,
      lat: lat || latitude || null,
      lon: lon || longitude || null,
      model,
      language,
      options: option_string,
      price: Pricing?.total,
      regionCode,
      isApproximateMatch: fetchSimiliarDesigns,
      configuratorTrims: TRIM?.options || [],
      configuratorOptions: {
        PAINT: PAINT?.options || [],
        WHEELS: WHEELS?.options || [],
        INTERIOR_PACKAGE: INTERIOR_PACKAGE?.options || [],
        STEERING_WHEEL: STEERING_WHEEL?.options || [],
      },
      retry,
    };
    // TODO: clean up
    if (selectedConfig) {
      return dispatch({
        type: UPDATE_AVAILABILITY_TIMIING,
        available: !deliveryZipGalleryStates.includes(regionCode),
        showSimilarDesigns: false,
      });
    }
    if (isEarlyAvailabilityEnabled && availabilityMatch) {
        dispatch(setIsLoading(true));
        const memoizedDeliveryData = memoize(getDeliveryData, payload);
        result = await memoizedDeliveryData(availabilityMatch, payload, 1000 * 15);
    }

    if (fetchSimiliarDesigns) {
      const { ApproximateMatch = [], error = null } = result || {};
      dispatch({
        type: SET_VEHICLE_DESIGN,
        payload: {
          earlyVehicleDesigns: ApproximateMatch || [],
          initialDesign: { ...initialDesign, ...payload },
          selectedConfig: '',
          swapConfig: {},
          totalSwapConfig: ApproximateMatch?.length,
          error,
        }
      })

    } else {
      dispatch({
        type: UPDATE_AVAILABILITY_TIMIING,
        available: !deliveryZipGalleryStates.includes(regionCode) ? result?.ExactMatch || false : false,
        showSimilarDesigns: !result?.ExactMatch ? result?.ApproximateMatch : false || false,
      });
    }
    dispatch(setIsLoading(false));
  };
};