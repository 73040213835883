/* eslint-disable no-param-reassign */
import React from 'react';
import { connect } from 'react-redux';
import { bool, func, shape, string } from 'prop-types';
import Entry from './entry';
import Alert from '../../../components/Alert';
import { getPostalCode } from 'selectors';
import { getEarlyAvailability, setVehicleDesign, openModal, resetVehicleDesign } from 'actions';
import { getDesignAlerts } from 'utils';
import Analytics from 'analytics';
import { SPECS_ACCELERATION, SPECS_RANGE, SPECS_TOP_SPEED } from 'dictionary';

const PreOrderSwap = props => {
  const { alerts = [] } = props || {};

  return (
    <>
      <If condition={alerts?.length}>
        <Alert alerts={alerts} classes='post-order-swap--alerts'/>
      </If>
      <Entry {...props} />
    </>
  );
};

function mapStateToProps(state) {
  const {
    DeliveryTiming: { showSimilarDesigns, isAvailableNow } = {},
    Configuration: { option_string: options, options_by_group } = {},
    Pricing: { total, grossPrice } = {},
    ReviewDetails: {
      DeliveryDetails: { stateCode } = {},
      deliveryZipGalleryStates = [],
      vehicleDesign: { earlyVehicleDesigns = [], selectedConfig } = {},
    } = {},
    App: { isInventorySwapEnabled } = {},
    CustomGroups: { TRIM: { currentSelected = [] } = {} } = {},
    SummaryPanel: { region_code } = {},
    Modal: { open = false } = {},
    OMS: { oms_params: { model } = {} } = {},
  } = state || {};
  const deliveryZip = getPostalCode(state);
  const currentSelectedTrim = currentSelected.find(opt => opt.selected) ?? {};
  const { formattedSpecs: batterySpecs = {}, code } = currentSelectedTrim;
  const { range, topspeed, acceleration } = batterySpecs;
  const regionCode = stateCode || region_code;

  return {
    showPreOrderSwap: !!(isInventorySwapEnabled && showSimilarDesigns && deliveryZip),
    initialDesign: {
      options,
      price: total,
      grossPrice,
      specs: {
        [SPECS_RANGE]: range,
        [SPECS_TOP_SPEED]: topspeed,
        [SPECS_ACCELERATION]: acceleration,
      },
    },
    initialConfigByGroup: options_by_group,
    deliveryZip,
    earlyVehicleDesigns,
    isAvailableNow,
    selectedConfig,
    alerts: getDesignAlerts(state),
    isGalleryState: deliveryZipGalleryStates?.includes(regionCode),
    isModalOpen: open,
    model,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setCurrentDesign: props => {
      dispatch(setVehicleDesign(props));
    },
    getEarlyDeliveryDesigns: props => {
      dispatch(getEarlyAvailability(props));
    },
    openModal: ({ componentName, props }) => dispatch(openModal(componentName, props)),
    resetToCustomDesign: () => dispatch(resetVehicleDesign()),
  };
}

PreOrderSwap.propTypes = {
  showPreOrderSwap: bool.isRequired,
  initialDesign: shape({}).isRequired,
  initialConfigByGroup: shape({}).isRequired,
  setCurrentDesign: func.isRequired,
  model: string.isRequired,
};

PreOrderSwap.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PreOrderSwap);
