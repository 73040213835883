import React, { useState } from 'react';
import { string, bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { i18n, getTerritoryCodeList } from 'utils';
import { hideRegistrationTypeAboveSummary } from 'selectors';
import { setSummaryPanelRegion } from 'actions';
import StateDropdown from '../StateDropdown';
import RegistrationTypeSelector from '../RegistrationTypeSelector';

const RegistrationStateAndType = ({
  showTerritoryList,
  setRegion,
  region,
  classes = '',
  showRegistrationType = false,
  showRegistrationTypeSelector,
  regType,
  linkClasses = '',
}) => {
  if (!showTerritoryList) {
    return null;
  }

  const [show, setShow] = useState(false);
  const showRegType = showRegistrationTypeSelector && showRegistrationType;

  return (
    <div className={classes}>
      <Choose>
        <When condition={show}>
          <StateDropdown
            onChange={region => {
              setRegion(region);
              setShow(false);
            }}
            id="financing-options--state-selector"
          />
          <If condition={showRegType}>
            <RegistrationTypeSelector
              radioName="summary"
              classes="tds-o-vertical_padding-top--1x"
            />
          </If>
        </When>
        <Otherwise>
          <div className={linkClasses}>
            <a onClick={() => setShow(true)} className="tds-link">
              {i18n('FinancingOptions.registration_selection', { REGION: region }, null, {
                specificOverride: regType,
                specificOverrideOperator: 'OR',
              })}
            </a>
          </div>
        </Otherwise>
      </Choose>
      <If condition={showRegType && showTerritoryList}>
        <p className="tds-text--caption tds-o-vertical_padding-top--1x">{i18n('Review.summary_page__disclaimer')}</p>
      </If>
    </div>
  );
};

RegistrationStateAndType.propTypes = {
  showTerritoryList: bool.isRequired,
  setRegion: func.isRequired,
  region: string,
  classes: string,
  showRegistrationTypeSelector: bool.isRequired,
};

RegistrationStateAndType.defaultProps = {
  region: '',
  classes: '',
};

function mapStateToProps(state) {
  const {
    App: { countryCode } = {},
    FinancingOptions: { showTerritoryList } = {},
    SummaryPanel: { region_code: region } = {},
    ReviewDetails: {
      showRegistrationTypeSelector,
      RegistrationDetail: { RegistrantType: regType } = {},
    } = {},
  } = state || {};

  return {
    showTerritoryList: showTerritoryList ? !!getTerritoryCodeList(countryCode, '')?.length : false,
    region,
    showRegistrationTypeSelector:
      showRegistrationTypeSelector && !hideRegistrationTypeAboveSummary(state),
    regType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setRegion: regionCode => dispatch(setSummaryPanelRegion(regionCode)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationStateAndType);
