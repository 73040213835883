import React, { useRef, useEffect } from 'react';
import { string, bool, func, arrayOf, shape, oneOfType } from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import classnames from 'classnames';
import {
  PRIVACY_CONSENT,
  MVOA_CONSENT,
  PERSONAL_DATA_CONSENT,
  TERM_OF_USE_CONSENT,
  RETURN_POLICY_CONSENT,
  SERIES,
  ORDER_CONSENT,
  DECLARATION_OF_CONSENT,
  CONFIGURATION_CONFIRMATION_CONSENT,
  VIN_INTERNATIONAL_TRANSFER_CONSENT,
  LHD_VEHICLE_CONSENT,
  INCENTIVE_PLAN_CONSENT,
  PRICE_ACCEPTANCE,
} from 'dictionary';
import { Relevant } from 'informed';
import { Form, Checkbox, Hidden } from '@tesla/informed-tds';
import { i18n, htmlToReact, constructUrl, isPreOrder, hasLHD } from 'utils';
import { getModelName } from 'selectors';
import { setLegalConsentValidFlag } from 'actions';
import { UI_DATA_IDS } from '../../common/dictionary';
import ActionTrigger from '../../components/ActionTrigger';
import { isMetShowIncentiveCondition } from '../../common/selectors';

/* LegalConsent component */
const LegalConsent = ({
  showLegalConsent = [],
  country,
  locale,
  sibling,
  model,
  modelName,
  orderDisclaimer,
  returnPolicyDisclaimer,
  vinInternationalTransferDisclaimer,
  setValidFlag,
  orderAgreementUrl,
  isReservedPreOrder,
  isBookedPreOrder,
  isLHD,
  showMVOAConsent,
  showPreOrderPriceAcceptance,
  showIncentiveConsent,
  openIncentiveModal,
}) => {
  const { MT, MTF, TS, TSF } = SERIES;
  const formApiRef = useRef();

  useEffect(() => {
    if (
      showLegalConsent?.length === 1 &&
      showLegalConsent?.includes(LHD_VEHICLE_CONSENT) &&
      !isLHD
    ) {
      setValidFlag(true);
    }
    if (showLegalConsent?.length === 1 && showMVOAConsent) {
      setValidFlag(false);
    }
    onChange();
  }, []);

  const mvpaType = isPreOrder()
    ? i18n('SummaryPanel.disclaimers.reserveMVPA')
    : i18n('SummaryPanel.disclaimers.orderMVPA');

  const getRoadsterOrderAgreement = () => {
    return htmlToReact(
      i18n('Review.cc_agreement_mvoa_consent_reservation', {
        ROADSTER_RESERVATION_AGREEMENT_URL: constructUrl(
          `sites/all/modules/custom/tesla_smartling/smartling/api-translations/documents/reservation-agreement/${locale.replace(
            '_',
            '-'
          )}/TeslaRoadsterReservationAgreement.pdf`,
          sibling
        ),
      })
    );
  };

  const getSemiOrderAgreement = () => {
    return htmlToReact(
      i18n('Review.cc_agreement_mvoa_consent_reservation', {
        SEMI_RESERVATION_AGREEMENT_URL: constructUrl(
          `sites/all/modules/custom/tesla_smartling/smartling/api-translations/documents/reservation-agreement/${locale.replace(
            '_',
            '-'
          )}/TeslaRoadsterReservationAgreement.pdf`,
          sibling
        ),
      })
    );
  };

  const getOtherOrderAgreement = () => {
    const presale = isPreOrder() ? `&is_presale=true` : '';
    return htmlToReact(
      i18n('Review.cc_agreement_mvoa_consent', {
        ORDER_AGREEMENT_URL: orderAgreementUrl,
        MVPA_TYPE: mvpaType,
      })
    );
  };

  let mvoaPreorder = null;
  if (model === MT || model === MTF) {
    mvoaPreorder = getRoadsterOrderAgreement();
  } else if (model === TS || model === TSF) {
    mvoaPreorder = getSemiOrderAgreement();
  }

  const mvoaConsentTitle = mvoaPreorder || getOtherOrderAgreement();

  const showConfigurationConfirmationConsent =
    showLegalConsent.includes(CONFIGURATION_CONFIRMATION_CONSENT) &&
    (isReservedPreOrder || isBookedPreOrder);

  const configurationConfirmationDisclaimer = showConfigurationConfirmationConsent
    ? htmlToReact(
        i18n(
          'SummaryPanel.disclaimers.configurationConfirmationDisclaimer',
          {
            MODEL: modelName,
            ORDER_AGREEMENT_URL: orderAgreementUrl,
            PRIVACY_POLICY_URL: constructUrl(
              'about/legal?redirect=no#privacy-statement',
              sibling,
              locale
            ),
            LEGAL_TERMS_URL: constructUrl('legal/terms?redirect=no', sibling, locale),
            PAYMENT_TERMS_URL: constructUrl(
              'about/legal?redirect=no#payment-terms-for-services',
              sibling,
              locale
            ),
            LINK_TARGET: '_blank',
            MVPA_TYPE: mvpaType,
          },
          null,
          {
            returnNullWhenEmpty: true,
          }
        )
      )
    : '';

  const onChange = () => {
    const { values = {} } = formApiRef?.current?.getFormState() || {};
    const flag = showLegalConsent.reduce((result, item) => {
      const value = values[item] || false;
      return result && value;
    }, true);
    setValidFlag(flag);
  };

  const debouncedOnChange = _debounce(onChange, 500);
  const onChangeCallback = () => {
    setValidFlag(false);
    debouncedOnChange();
  };
  return (
    <Form formApiRef={formApiRef}>
      <Relevant when={() => showLegalConsent.includes(LHD_VEHICLE_CONSENT)}>
        <Choose>
          <When condition={isLHD}>
            <Checkbox
              name={LHD_VEHICLE_CONSENT}
              id={LHD_VEHICLE_CONSENT}
              data-id={UI_DATA_IDS?.termsAndConditions?.vehicleConsentCheckbox}
              label={htmlToReact(i18n('Review.vehicle_consent'))}
              required
              onChange={onChangeCallback}
            />
          </When>
          <Otherwise>
            <Hidden name={LHD_VEHICLE_CONSENT} id={LHD_VEHICLE_CONSENT} initialValue={true} />
          </Otherwise>
        </Choose>
      </Relevant>

      <Relevant when={() => showLegalConsent.includes(PRIVACY_CONSENT)}>
        <Checkbox
          name={PRIVACY_CONSENT}
          id={PRIVACY_CONSENT}
          data-id={UI_DATA_IDS?.termsAndConditions?.privacyConsentCheckbox}
          label={htmlToReact(i18n('Review.cc_agreement_privacy_consent'))}
          required
          onChange={onChangeCallback}
        />
      </Relevant>

      <Relevant when={() => showLegalConsent.includes(PERSONAL_DATA_CONSENT)}>
        <Checkbox
          name={PERSONAL_DATA_CONSENT}
          id={PERSONAL_DATA_CONSENT}
          data-id={UI_DATA_IDS?.termsAndConditions?.personalDataConsentCheckbox}
          label={htmlToReact(i18n('Review.cc_agreement_personal_data_consent'))}
          required
          onChange={onChangeCallback}
        />
      </Relevant>

      <Relevant when={() => showLegalConsent.includes(MVOA_CONSENT)}>
        <Checkbox
          name={MVOA_CONSENT}
          id={MVOA_CONSENT}
          data-id={UI_DATA_IDS?.termsAndConditions?.mvoaConsentCheckbox}
          label={mvoaConsentTitle}
          required
          onChange={onChangeCallback}
        />
      </Relevant>

      <Relevant when={() => showLegalConsent.includes(TERM_OF_USE_CONSENT)}>
        <Checkbox
          name={TERM_OF_USE_CONSENT}
          id={TERM_OF_USE_CONSENT}
          data-id={UI_DATA_IDS?.termsAndConditions?.termsOfUseConsent}
          label={htmlToReact(
            i18n('Review.cc_agreement_term_of_use_consent', {
              LOCALE: locale,
              MODEL: modelName,
              ORDER_AGREEMENT_URL: orderAgreementUrl,
              SUPERCHARGER_POLICY_URL: constructUrl(
                'about/legal?redirect=no#supercharger-fair-use',
                sibling,
                locale
              ),
              PRIVACY_POLICY_URL: constructUrl(
                'about/legal?redirect=no#privacy-statement',
                sibling,
                locale
              ),
              NEW_PRIVACY_POLICY_URL: constructUrl('legal/privacy?redirect=no', sibling, locale),
              LEGAL_TERMS_URL: constructUrl('legal/terms?redirect=no', sibling, locale),
              PAYMENT_TERMS_URL: constructUrl(
                'about/legal?redirect=no#payment-terms-for-services',
                sibling,
                locale
              ),
              LINK_TARGET: '_blank',
              MVPA_TYPE: mvpaType,
            })
          )}
          required
          onChange={onChangeCallback}
        />
      </Relevant>

      <Relevant when={() => showLegalConsent.includes(DECLARATION_OF_CONSENT)}>
        <Checkbox
          name={DECLARATION_OF_CONSENT}
          id={DECLARATION_OF_CONSENT}
          data-id={UI_DATA_IDS?.termsAndConditions?.declarationOfConsentCheckbox}
          label={i18n('Review.declaration_of_consent')}
          required
          onChange={onChangeCallback}
        />
      </Relevant>

      <Relevant when={() => showLegalConsent.includes(ORDER_CONSENT)}>
        <ActionTrigger>
          <Checkbox
            name={ORDER_CONSENT}
            id={ORDER_CONSENT}
            data-id={UI_DATA_IDS?.termsAndConditions?.orderConsentCheckbox}
            label={
              <div className={classnames({ 'modifier--tds-link': country === 'CN' })}>
                {orderDisclaimer}
              </div>
            }
            required
            onChange={onChangeCallback}
          />
        </ActionTrigger>
      </Relevant>

      <Relevant when={() => showLegalConsent.includes(RETURN_POLICY_CONSENT)}>
        <Checkbox
          name={RETURN_POLICY_CONSENT}
          id={RETURN_POLICY_CONSENT}
          data-id={UI_DATA_IDS?.termsAndConditions?.returnPolicyConsentCheckbox}
          label={returnPolicyDisclaimer}
          required
          onChange={onChangeCallback}
        />
      </Relevant>

      <Relevant
        when={() =>
          showLegalConsent.includes(VIN_INTERNATIONAL_TRANSFER_CONSENT) &&
          vinInternationalTransferDisclaimer
        }
      >
        <Checkbox
          name={VIN_INTERNATIONAL_TRANSFER_CONSENT}
          id={VIN_INTERNATIONAL_TRANSFER_CONSENT}
          data-id={UI_DATA_IDS?.termsAndConditions?.returnPolicyConsentCheckbox}
          label={vinInternationalTransferDisclaimer}
          required
          onChange={onChangeCallback}
        />
      </Relevant>

      <Relevant when={() => showConfigurationConfirmationConsent}>
        <Checkbox
          name={CONFIGURATION_CONFIRMATION_CONSENT}
          id={CONFIGURATION_CONFIRMATION_CONSENT}
          data-id={UI_DATA_IDS?.termsAndConditions?.configurationConfirmationConsent}
          label={configurationConfirmationDisclaimer}
          required
          onChange={onChangeCallback}
        />
      </Relevant>

      <Relevant when={() => showPreOrderPriceAcceptance}>
        <Checkbox
          name={PRICE_ACCEPTANCE}
          id={PRICE_ACCEPTANCE}
          label={htmlToReact(i18n('PriceAcceptance.label_checkbox_price_acceptance'))}
          data-id={UI_DATA_IDS?.termsAndConditions?.priceAcceptanceCheckbox}
          required
          onChange={onChangeCallback}
        />
      </Relevant>

      <If condition={showIncentiveConsent}>
        <Checkbox
          name={INCENTIVE_PLAN_CONSENT}
          id={INCENTIVE_PLAN_CONSENT}
          data-id={UI_DATA_IDS?.termsAndConditions?.incentivePlanConsentCheckbox}
          label={
            <div>
              {i18n('SummaryPanel.disclaimers.readAndAgree', null, null, {
                returnNullWhenEmpty: true,
              })}
              <button
                className="modal-trigger tds-link "
                onClick={e => {
                  e.preventDefault();
                  openIncentiveModal();
                }}
              >
                {i18n('SummaryPanel.disclaimers.incentiveCondition', null, null, {
                  returnNullWhenEmpty: true,
                })}
              </button>
            </div>
          }
          required
          onChange={onChangeCallback}
        />
      </If>
    </Form>
  );
};

function mapStateToProps(state, ownProps) {
  const country = _get(state, 'App.countryCode', '');
  const isPostOrderSwap = _get(state, 'App.isPostOrderSwap', false);
  let showLegalConsent = _get(state, 'ReviewDetails.showLegalConsent', []);
  const isReservedPreOrder = _get(state, 'ApplicationFlow.isReservedPreOrder', false);
  const isBookedPreOrder = _get(state, 'ApplicationFlow.isBookedPreOrder', false);
  const isUnbuildable = _get(state, 'ApplicationFlow.isUnbuildable', false);
  const showPreOrderPriceAcceptance = _get(
    state,
    'ReviewDetails.showPreOrderPriceAcceptance',
    false
  );
  const showIncentiveConsent = isMetShowIncentiveCondition(state);
  if (country === 'CN') {
    showLegalConsent = showLegalConsent.filter(item => item !== INCENTIVE_PLAN_CONSENT);
    if (showIncentiveConsent) {
      showLegalConsent.push(INCENTIVE_PLAN_CONSENT);
    }
  }
  const showConfigurationConfirmationConsent =
    showLegalConsent.includes(CONFIGURATION_CONFIRMATION_CONSENT) &&
    country === 'CN' &&
    (isReservedPreOrder || isBookedPreOrder);
  showLegalConsent = showConfigurationConfirmationConsent
    ? [CONFIGURATION_CONFIRMATION_CONSENT]
    : showLegalConsent.filter(item => item !== CONFIGURATION_CONFIRMATION_CONSENT);
  const canModifyOrder = _get(state, 'ApplicationFlow.canModifyOrder', false);
  if (country === 'CN' && (canModifyOrder || isPostOrderSwap)) {
    showLegalConsent = showLegalConsent.filter(
      item => item !== RETURN_POLICY_CONSENT && item !== VIN_INTERNATIONAL_TRANSFER_CONSENT
    );
  }
  const showLegalConsentEditDesign = _get(state, 'ReviewDetails.showLegalConsentEditDesign');
  const showMVOAConsent =
    showLegalConsent.includes(MVOA_CONSENT) &&
    showLegalConsentEditDesign &&
    (isReservedPreOrder || isBookedPreOrder || canModifyOrder || isUnbuildable);
  showLegalConsent =
    showMVOAConsent && showLegalConsentEditDesign ? [MVOA_CONSENT] : showLegalConsent;
  if (ownProps?.showLegalConsentAfterPayX?.length) {
    showLegalConsent = ownProps.showLegalConsentAfterPayX;
  }

  return {
    showLegalConsent: showLegalConsent,
    showIncentiveConsent: showLegalConsent.includes(INCENTIVE_PLAN_CONSENT),
    model: _get(state, 'OMS.oms_params.model', ''),
    modelName: getModelName(state),
    sibling: _get(state, 'App.sibling', ''),
    locale: _get(state, 'App.locale', ''),
    country: country,
    isReservedPreOrder,
    isBookedPreOrder,
    isLHD: hasLHD(state),
    showMVOAConsent,
    showPreOrderPriceAcceptance,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setValidFlag: flag => {
      dispatch(setLegalConsentValidFlag(flag));
    },
    openIncentiveModal: () => {
      dispatch({
        type: 'SHOW_INCENTIVE_MODAL_CN',
      });
    },
  };
}

LegalConsent.propTypes = {
  setValidFlag: func.isRequired,
  showLegalConsent: arrayOf(string),
  showLegalConsentAfterPayX: arrayOf(string),
  country: string,
  locale: string,
  sibling: string,
  model: string,
  modelName: string,
  orderDisclaimer: shape({}),
  returnPolicyDisclaimer: oneOfType([string, shape({})]),
  orderAgreementUrl: string,
  isReservedPreOrder: bool,
  isBookedPreOrder: bool,
  showPreOrderPriceAcceptance: bool,
  vinInternationalTransferDisclaimer: oneOfType([string, shape({})]),
  showIncentiveConsent: bool,
  openIncentiveModal: func,
};

LegalConsent.defaultProps = {
  showLegalConsent: [],
  showLegalConsentAfterPayX: [],
  country: '',
  locale: '',
  sibling: '',
  model: '',
  modelName: '',
  orderDisclaimer: null,
  returnPolicyDisclaimer: '',
  orderAgreementUrl: '',
  isReservedPreOrder: false,
  isBookedPreOrder: false,
  showPreOrderPriceAcceptance: false,
  vinInternationalTransferDisclaimer: '',
  showIncentiveConsent: false,
  openIncentiveModal: () => {},
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalConsent);
