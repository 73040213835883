import React from 'react';
import { string, func, bool, arrayOf, shape } from 'prop-types';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import cx from 'classnames';
import { FormInputCheckbox } from '@tesla/design-system-react';
import {
  getFinanceProductType,
  getFuelYears,
  getMonthlyFuelSavings,
  getCurrentAvailableTotal,
  getCurrentAvailableMonthlyFuel,
  getRegionName,
  getPricePostfix,
} from 'selectors';
import Analytics from 'analytics';

import { formatMonthlyPrice, formatCurrency } from '@tesla/coin-common-components';
import { toggleTrimSavingsPrice } from 'actions';
import { i18n } from 'utils';
import { 
  UPDATE_INCENTIVES, 
  FINANCIAL_MODAL_STANDALONE, 
  MODAL_MEDIUM 
} from 'dictionary';
import { 
  GIO_TAG_ENABLE, 
  WEB_CONFIGURATOR_FINANCING_CALCULATOR 
} from '../../common/gioStatistics';
import IncentivesSavings from './IncentivesSavings';
import FinanceDisclaimer from './FinanceDisclaimer';
import ModalTrigger from '../ModalTrigger';

const IncentivesToggle = ({
  showProbableSavings,
  financeTab,
  toggleSavings,
  disclaimerOverrides,
  disclaimerVars = {},
  eligibleIncentives,
  updateIncentives,
  classes,
  isMobile,
  pricePostfix,
  showProbableSavingsToggle,
  hideToggle,
  showLegacyFooterDisclaimers,
  showFinanceModalTrigger = false,
  hideFinanceDisclaimer = false,
  availableFuelSavings,
}) => {
  if (hideToggle) {
    return null;
  }

  let i18nFinanceType = financeTab ? 'finance' : 'default';
  
  if(hideFinanceDisclaimer) {
    i18nFinanceType = 'default';
    disclaimerOverrides = ['savings'];
  }

  const financeModal = () => {
    return (
      <div className='tds-text--center'>
        <ModalTrigger
          analyticsInteraction={'edit-terms-savings'}
          data-gio-eventname={WEB_CONFIGURATOR_FINANCING_CALCULATOR}
          data-gio-track={GIO_TAG_ENABLE}
          options={{
            props: {
              componentName: FINANCIAL_MODAL_STANDALONE,
              props: {
                genericWrapper: true,
                size: MODAL_MEDIUM,
                classes: 'tds-text--center',
                isMega: true,
              },
            },
          }}
        >
          <span className="tds-link">
            {i18n('common.editTermsAndSavings', null, null, {
              specificOverride: i18nFinanceType,
              returnNullWhenEmpty: true,
              specificOverrideOperator: 'OR',
            })}
          </span>
        </ModalTrigger>
      </div>
    );
  };

  if (showFinanceModalTrigger) {
    return financeModal();
  }

  return (
    <>
      <div
        className={cx(
          'tds-o-flex-direction--column tds-flex--justify-center tds-flex-gap--8 tds--padding_top-16 incentives-toggle--container',
          {
            [classes]: classes,
          },
          {
            'tds--vertical_padding-bottom': isMobile,
          }
        )}
      >
        <If condition={!hideFinanceDisclaimer}>
          <FinanceDisclaimer className="tds-text--center tds-o-no-margin" showLegacyFooterDisclaimers={showLegacyFooterDisclaimers} />
        </If>
        <div className="tds-text--center tds-text--contrast-low">
          <Choose>
            <When condition={showProbableSavingsToggle}>
              <FormInputCheckbox
                  className="incentives-toggle--section"
                  name="showProbableSavings"
                  onChange={() => {
                    const flag = !showProbableSavings;
                    toggleSavings(flag);
                    updateIncentives(flag ? eligibleIncentives : []);
                    Analytics.fireInteractionEvent(
                        `potential_savings_${showProbableSavings ? 'uncheck' : 'check'}`
                    );
                  }}
                  checked={showProbableSavings}
                  label={
                    <span className="tds-display--block">
                      {showProbableSavings && pricePostfix && <span>{pricePostfix}</span>}
                      {i18n(
                          `FinancingOptions.incentives.${i18nFinanceType}.caption`,
                          disclaimerVars,
                          null,
                          {
                            specificOverride: disclaimerOverrides,
                            returnNullWhenEmpty: true,
                            specificOverrideOperator: 'OR',
                          }
                      )}
                    </span>
                  }
              />
            </When>
            <Otherwise>
              {i18n(
                `FinancingOptions.excludesSavings.${i18nFinanceType}.caption`,
                { ...disclaimerVars, FUEL_AMOUNT: formatCurrency(availableFuelSavings) },
                null,
                {
                  specificOverride: disclaimerOverrides,
                  returnNullWhenEmpty: true,
                  specificOverrideOperator: 'OR'
                })}
            </Otherwise>
          </Choose>
        </div>
        {financeModal()}
      </div>
      <IncentivesSavings />
    </>
  );
};

function mapStateToProps(state) {
  const { userSelectedIncentives } = state?.Financial || {};
  const { showProbableSavingsToggle = false } = state?.SummaryPanel || {};
  const { current, currentAvailable = {} } =
    state?.Financial?.incentives || {};
  const { taxCredit = 0 } =
    state?.Pricing?.calculatorResult?.data?.apiResults?.incentives?.total || {};
  const incentivesTotal = state?.Financial?.incentives?.total;
  const { once = 0, fuel = 0 } = incentivesTotal || {};
  const { termLength: term } = state?.Pricing?.finplat?.output?.inputs || {};
  const { showAfterSavingsPriceForTrims: showProbableSavings } =
    state?.ReviewDetails || {};
  const eligibleIncentives = getCurrentAvailableTotal(state) || {};
  const { fuel: fuelIncentive } = current || {};
  const currentFilt = Object.keys(current)?.filter(x => !_get(current, `${x}[0].isPermanent`, false));
  const potentialSavings = _isEmpty(currentFilt)
    ? Math.abs(eligibleIncentives?.once + eligibleIncentives?.taxCredit)
    : Math.abs(once + taxCredit);
    console.log({ eligibleIncentives })
  let fuelSavings = _isEmpty(currentFilt) ? Math.abs(eligibleIncentives?.fuel) : Math.abs(fuel);
  const region = getRegionName(state) || '';
  let disclaimerOverrides = [];
  if (potentialSavings && !fuelSavings) {
    disclaimerOverrides.push('savings');
  }
  if (fuelSavings && !potentialSavings) {
    disclaimerOverrides.push('fuel');
  }
  if (potentialSavings && region) {
    disclaimerOverrides.push('region');
  }
  const incentivesMonthly = term ? Math.round(potentialSavings / term) || 0 : 0;
  const fuelMonthly = _isEmpty(fuelIncentive)
    ? getCurrentAvailableMonthlyFuel(state)
    : getMonthlyFuelSavings(state);
  const monthlySavings = Math.abs(fuelMonthly) + Math.abs(incentivesMonthly);

  return {
    financeTab: getFinanceProductType(state),
    showProbableSavings,
    potentialSavings,
    disclaimerOverrides,
    eligibleIncentives: userSelectedIncentives?.length ? Object.values(currentAvailable).flat() : undefined,
    disclaimerVars: {
      MONTHLY_SAVINGS: formatMonthlyPrice(monthlySavings),
      TAX_CREDIT: formatCurrency(Math.abs(taxCredit || 0)),
      FUEL_AMOUNT: formatCurrency(fuelSavings),
      FUEL_YEAR: getFuelYears(state),
      POTENTIAL_SAVINGS: formatCurrency(potentialSavings),
      MONTHLY_INCENTIVES: formatMonthlyPrice(Math.abs(incentivesMonthly)),
      MONTHLY_FUEL: formatMonthlyPrice(Math.abs(fuelMonthly)),
      REGION_NAME: region,
    },
    isMobile: state?.App?.isLayoutMobileAdjusted,
    pricePostfix: getPricePostfix(state),
    showProbableSavingsToggle,
    hideToggle: _isEmpty(incentivesTotal),
    availableFuelSavings: Math.abs(eligibleIncentives?.fuel),
  };
}

const mapDispatchToProps = dispatch => ({
  toggleSavings: flag => dispatch(toggleTrimSavingsPrice(flag)),
  updateIncentives: incentives =>
    dispatch({
      type: UPDATE_INCENTIVES,
      incentives,
    }),
});

IncentivesToggle.propTypes = {
  financeTab: string,
  toggleSavings: func.isRequired,
  showProbableSavings: bool.isRequired,
  disclaimerOverrides: arrayOf(string),
  disclaimerVars: shape({}),
  updateIncentives: func.isRequired,
  classes: string,
  isMobile: bool.isRequired,
  pricePostfix: string,
  showProbableSavingsToggle: bool.isRequired,
  hideToggle: bool.isRequired,
  showLegacyFooterDisclaimers: bool,
  showFinanceModalTrigger: bool,
  hideFinanceDisclaimer: bool
};

IncentivesToggle.defaultProps = {
  financeTab: '',
  pricePostfix: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(IncentivesToggle);
