import _get from 'lodash/get'
import _isNumber from 'lodash/isNumber'
import _find from 'lodash/find'
import _findKey from 'lodash/findKey'
import { formatCurrency, formatPercent, formatMonthlyPrice } from '@tesla/coin-common-components';

import {
    SUMMARY_PANEL_REGION_CHANGE,
    SUMMARY_PANEL_TAB_CHANGE,
    SUMMARY_PANEL_DISTANCE_CHANGE,
    SUMMARY_PANEL_FUEL_INCENTIVE_CHANGE,
    SUMMARY_PANEL_INCENTIVE_TOGGLE,
    SUMMARY_PANEL_DOWNPAYMENT_CHANGE,
    SUMMARY_PANEL_LEASE_TERM_CHANGE,
    SUMMARY_PANEL_USER_LEASE_AMOUNT_CHANGE,
    SUMMARY_PANEL_LEASE_APR_CHANGE,
    SUMMARY_PANEL_FINANCE_TERM_CHANGE,
    SUMMARY_PANEL_FINANCE_DISTANCE_CHANGE,
    SUMMARY_PANEL_FINANCE_APR_CHANGE,
    SUMMARY_PANEL_FINANCE_BALLOON_PAYMENT_CHANGE,
    SUMMARY_PANEL_INCLUDE_SERVICE_FEE_CHANGE,
    SUMMARY_PANEL_RESIDUAL_AMOUNT_CHANGE,
    SUMMARY_PANEL_INCLUDE_FEES,
    SUMMARY_PANEL_CONFIGURATION_CHANGED,
    PRICE_CHANGED,
    SUMMARY_PANEL_CASH_TAB,
    SUMMARY_PANEL_LEASE_TAB,
    SUMMARY_PANEL_FINANCE_TAB,
    TOGGLE_LOAN_TYPE,
    OMS_RECEIVED_DEPENDENCIES,
    SUMMARY_PANEL_DELIVERY_INCENTIVE_CHANGE,
    SUMMARY_PANEL_INTEREST_RATE_TYPE_CHANGE,
    TOGGLE_PROBABLE_SAVINGS,
    LOCATION_CHANGED,
    PRODUCT_TYPE,
    FinanceTypes,
    SWITCH_TO_DEFAULT_TRIM,
    FINANCE_MODAL_CLOSE,
    CUSTOMER_TYPES,
}
from 'dictionary';

import { vehicleHasDiscounts, isLeasingUnavailableInState, getPercentageByDownPayment } from 'selectors';

function formatPrices(state, app_state, action) {
  //STATE
  const downPaymentPercent        = _get(state,  'downPaymentPercent')
  const referral                  = _get(app_state, 'Pricing.discount.referral.value')

  //CASH
  const netPrice                  = _get(action, 'data.result.cash.netPrice')
  const grossPrice                = _get(action, 'data.result.cash.grossPrice')
  const priceExcludingSaving      = _get(action, 'data.result.cash.priceExcludingSaving')

  const vehiclePrice              = _get(action, 'data.total')

  //LEASE
  const leaseAmountDueAtSigning   = _get(action, 'data.result.lease.amountDueAtSigning')
  const leaseMonthlyPayment       = _get(action, 'data.result.lease.monthlyPayment')
  const leaseNetMonthlyPayment    = _get(action, 'data.result.lease.netMonthlyPayment')
  const leaseMonthlyPaymentExVAT  = _get(action, 'data.result.lease.monthlyPaymentWithoutVat')
  const leaseNetMonthlyPaymentExVAT = _get(action, 'data.result.lease.netMonthlyPaymentWithoutVat')
  const leaseResidualAmount       = _get(action, 'data.result.lease.residualAmount')
  const leaseInterestRate         = _get(action, 'data.result.lease.interestRate')
  const leaseCostOfCredit         = _get(action, 'data.result.lease.costOfCredit')


  //LOAN
  const financeAmountDueAtSigning = _get(action, 'data.result.loan.amountDueAtSigning')
  const financeMonthlyPayment     = _get(action, 'data.result.loan.monthlyPayment')
  const financeNetMonthlyPayment  = _get(action, 'data.result.loan.netMonthlyPayment')
  const financeResidualAmount     = _get(action, 'data.result.loan.residualAmount')

  const financeEffectiveRate      = _get(action, 'data.result.loan.effectiveRate')
  const financeNominalRate        = _get(action, 'data.result.loan.nominalRate')

  const financeCostOfCredit       = _get(action, 'data.result.loan.costOfCredit')
  const financeLoanFee            = _get(action, 'data.result.loan.DNBLoanFee')

  const financeAdminFee           = _get(action, 'data.result.loan.DNBAdminFee')
  const financeDownpayment        = _get(action, 'data.result.loan.downPayment')

  const financedAmountPayment     = _get(action, 'data.result.loan.financedAmount')

  //INCENTIVES
  const incentives                = _get(action, 'data.apiResults.incentives.total.once')
  const discounts                 = _get(action, 'data.variables.discounts')
  const incentivesPerMonth        = _get(action, 'data.apiResults.incentives.total.month')

  const fuelSavings               = _get(action, 'data.apiResults.variables.fuelSavings.total')
  const fuelSavingsPerMonth       = _get(action, 'data.apiResults.variables.fuelSavings.perMonth')

  const bonusValue                = _get(action, 'data.apiResults.incentives.current.environmental_bonus.total')

  //FEES
  const vat                       = _get(action, 'data.apiResults.fees.current.vat_percent.total')
  const feeAmount                 = _get(action, 'data.apiResults.fees.current.personal_delivery.total')
  const recyclingFee              = _get(action, 'data.apiResults.fees.current.recycling_fee.total')
  const tireFee                   = _get(action, 'data.apiResults.fees.current.tire_fee.total')
  const winterTireFee             = _get(action, 'data.apiResults.fees.current.winter_tire_fee.total')
  const firstRegistrationTax      = _get(action, 'data.apiResults.fees.current.first_registration_tax.total')
  const inspectPrep               = _get(action, 'data.apiResults.fees.current.inspect_prep.total')
  const personalDelivery          = _get(action, 'data.apiResults.fees.current.personal_delivery.total')
  const destinationAndDocFee      = ( inspectPrep + personalDelivery ) || _get(action, 'data.apiResults.fees.current.reg_and_doc_fee.total', 0)
  const purchaseTax      = _get(action, 'data.apiResults.fees.current.purchase_tax.total')


  //Please don't add unformatted values, those are stored or should be in Pricing
  //Representation should handle properly if it needs to be displayed by making an assert of the value
  return {
    //STATE
    downPaymentPercent:            downPaymentPercent           ? formatPercent(downPaymentPercent, 0)      : null,
    referral:                      referral                     ? formatCurrency(referral)                  : null,

    //CASH
    grossPrice:                    grossPrice                   ? formatCurrency(grossPrice)                : null,
    netPrice:                      netPrice                     ? formatCurrency(netPrice)                  : null,
    vehiclePrice:                  vehiclePrice                 ? formatCurrency(vehiclePrice)              : null,
    priceExcludingSaving:          priceExcludingSaving         ? formatCurrency(priceExcludingSaving)      : null,

    //LEASE
    leaseMonthlyPayment:           leaseMonthlyPayment          ? formatMonthlyPrice(leaseMonthlyPayment)   : null,
    leaseNetMonthlyPayment:        leaseNetMonthlyPayment       ? formatMonthlyPrice(leaseNetMonthlyPayment): null,
    leaseMonthlyPaymentExVAT:      leaseMonthlyPaymentExVAT     ? formatMonthlyPrice(leaseMonthlyPaymentExVAT) : null,
    leaseNetMonthlyPaymentExVAT:   leaseNetMonthlyPaymentExVAT  ? formatMonthlyPrice(leaseNetMonthlyPaymentExVAT) : null,
    leaseAmountDueAtSigning:       leaseAmountDueAtSigning      ? formatCurrency(leaseAmountDueAtSigning)   : null,
    leaseResidualAmount:           leaseResidualAmount          ? formatCurrency(leaseResidualAmount)       : null,
    leaseCostOfCredit:             leaseCostOfCredit            ? formatCurrency(leaseCostOfCredit)         : null,
    leaseInterestRate:             leaseInterestRate            ? formatPercent(leaseInterestRate, 2)       : null,

    //LOAN
    financeMonthlyPayment:         financeMonthlyPayment        ? formatMonthlyPrice(financeMonthlyPayment) : null,
    financeNetMonthlyPayment:      financeNetMonthlyPayment     ? formatMonthlyPrice(financeNetMonthlyPayment) : null,
    financeAmountDueAtSigning:     _isNumber(financeAmountDueAtSigning)    ? formatCurrency(financeAmountDueAtSigning) : null,
    financeResidualAmount:         financeResidualAmount        ? formatCurrency(financeResidualAmount)     : null,
    financedAmountPayment:         financedAmountPayment        ? formatCurrency(financedAmountPayment)     : null,
    financeAdminFee:               financeAdminFee              ? formatCurrency(financeAdminFee)           : null,
    financeCostOfCredit:           financeCostOfCredit          ? formatCurrency(financeCostOfCredit)       : null,
    financeLoanFee:                financeLoanFee               ? formatCurrency(financeLoanFee)            : null,
    financeDownpayment:            financeDownpayment           ? formatCurrency(financeDownpayment)        : null,
    financeNominalRate:            financeNominalRate           ? formatPercent(financeNominalRate, 2)             : null,
    financeEffectiveRate:          financeEffectiveRate         ? formatPercent(Math.abs(financeEffectiveRate), 2) : null,

    //INCENTIVES
    incentives:                    incentives                   ? formatCurrency(incentives)                : null,
    incentivesPerMonth:            incentivesPerMonth           ? formatMonthlyPrice(incentivesPerMonth)    : null,
    fuelSavingsPerMonth:           fuelSavingsPerMonth          ? formatMonthlyPrice(fuelSavingsPerMonth)   : null,
    fuelSavings:                   fuelSavings                  ? formatCurrency(fuelSavings)               : null,
    bonusValue:                    bonusValue                   ? formatCurrency(Math.abs(bonusValue))      : null,

    //FEES
    vat:                           vat                          ? formatCurrency(vat)                       : null,
    recyclingFee:                  recyclingFee                 ? formatCurrency(recyclingFee, { useDynamicRounding: true }) : null,
    tireFee:                       tireFee                      ? formatCurrency(tireFee, { useDynamicRounding: true }) : null,
    winterTireFee:                 winterTireFee                ? formatCurrency(winterTireFee, { useDynamicRounding: true }) : null,
    feeAmount:                     feeAmount                    ? formatCurrency(feeAmount)                 : null,
    firstRegistrationTax:          firstRegistrationTax         ? formatCurrency(firstRegistrationTax)      : null,
    destinationAndDocFee:          destinationAndDocFee         ? formatCurrency(destinationAndDocFee)      : null,
    purchaseTax:                   purchaseTax                  ? formatCurrency(purchaseTax)               : null,

    discounts: discounts.reduce(function(result, item){
      return item.value ? Object.assign(result, {[item.key]: formatCurrency(item.value)}) : result;
    }, {}),

    payment: (()=>{
      switch(state.selected_tab){
        case SUMMARY_PANEL_CASH_TAB:
          return formatCurrency(vehiclePrice);
        case SUMMARY_PANEL_LEASE_TAB:
          return formatMonthlyPrice(leaseMonthlyPayment);
        case SUMMARY_PANEL_FINANCE_TAB:
          return formatMonthlyPrice(financeMonthlyPayment);
      }
    })(),
    netPayment: (()=>{
      switch(state.selected_tab){
        case SUMMARY_PANEL_CASH_TAB:
          return formatCurrency(netPrice);
        case SUMMARY_PANEL_LEASE_TAB:
          return formatMonthlyPrice(leaseNetMonthlyPayment);
        case SUMMARY_PANEL_FINANCE_TAB:
          return formatMonthlyPrice(financeNetMonthlyPayment);
      }
    })()
  }
}

function SummaryPanel(state = {}, action, { app_state = {} }) {
  const formState = { ...app_state, SummaryPanel: state };
  switch (action.type) {
    case SUMMARY_PANEL_REGION_CHANGE:
      return Object.assign({}, state, {
        region_code: action.regionCode
      })
    case SUMMARY_PANEL_DISTANCE_CHANGE:
      return Object.assign({}, state, {
        leaseDistance: action.leaseDistance,
        // when we are on the leasing tab, we use the value from the lease distance driven
        fuelDistPer: action.leaseDistance
      })

    case SUMMARY_PANEL_FINANCE_DISTANCE_CHANGE:
      return Object.assign({}, state, {
        loanDistance: action.loanDistance,
      })

    case SUMMARY_PANEL_FINANCE_TERM_CHANGE:
      return Object.assign({}, state, {
        loanTerm: action.financeTerm,
        residualAmount: null,
      })

    case SUMMARY_PANEL_FINANCE_APR_CHANGE:
      return Object.assign({}, state, {
        loanApr: action.loanApr
      })

    case SUMMARY_PANEL_FINANCE_BALLOON_PAYMENT_CHANGE:
      return Object.assign({}, state, {
        balloonPayment: action.balloonPayment
      })

    case SUMMARY_PANEL_LEASE_TERM_CHANGE:
      return Object.assign({}, state, {
        leaseTerm: action.leaseTerm,
        residualAmount: null,
      })
    
    case SUMMARY_PANEL_USER_LEASE_AMOUNT_CHANGE:
      return Object.assign({}, state, {
        userLeaseAmount: action.userLeaseAmount
      })

    case SUMMARY_PANEL_INTEREST_RATE_TYPE_CHANGE:
      return Object.assign({}, state, {
        interestRateType: action.interestRateType,
      })

    case SUMMARY_PANEL_LEASE_APR_CHANGE:
      return Object.assign({}, state, {
        leaseApr: action.leaseApr
      })

    case SUMMARY_PANEL_TAB_CHANGE:
      return Object.assign({}, state, {
        selected_tab: action.tabID
      })
    case SUMMARY_PANEL_FUEL_INCENTIVE_CHANGE:
      return Object.assign({}, state, action.params)

    case SUMMARY_PANEL_RESIDUAL_AMOUNT_CHANGE:
      return Object.assign({}, state, {
        residualAmount: action.residualAmount
      })

    case SUMMARY_PANEL_DELIVERY_INCENTIVE_CHANGE:
      return Object.assign({}, state, {
        selectedIncentives: action.params
      })

    case SUMMARY_PANEL_INCENTIVE_TOGGLE:
      return Object.assign({}, state, action.incentiveParams)

    case SUMMARY_PANEL_DOWNPAYMENT_CHANGE:
      const market = _get(app_state, 'OMS.oms_params.market', '');
      const calculatedDownpaymentPercent = market === 'CN' ? getPercentageByDownPayment(action.grossPrice, action.downPayment) : action.downPaymentPercent;
      const formattedPrices = Object.assign({}, state.formattedPrices,
        { downPaymentPercent: formatPercent(calculatedDownpaymentPercent, 0)}
      )
      return Object.assign({}, state, {
        // According to Minu, we send downPayment as percent for all markets except US
        // Note: Only GB allows for arbitrary downpayments < 30%
        // set downPayment (gets sent as param to leaseCalculation)
        downPayment: action.downPayment,
        downPaymentPercent: action.downPaymentPercent || state.downPaymentPercent || calculatedDownpaymentPercent,
        formattedPrices
      })

    case SUMMARY_PANEL_INCLUDE_FEES:
      return Object.assign({}, state, {
        includeFees: true
      })

    case SUMMARY_PANEL_INCLUDE_SERVICE_FEE_CHANGE:
      return Object.assign({}, state, {
        includeServiceFee: action.includeServiceFee
      })

    // triggered when market has a special configurationChangeDelta
    case SUMMARY_PANEL_CONFIGURATION_CHANGED:
      return Object.assign({}, state, action.props)

    case PRICE_CHANGED: {
      // TODO: find a better way to handle this
      const { financeType = '', customerType = '', market = '', result = {} } = action.data ?? {};
      const newState = { ...state };

      if (financeType === FinanceTypes.FINPLAT && typeof result?.finplat?.output?.outputs?.monthlyPayment !== 'number') {
        newState.selected_tab = market === 'TR' && customerType === CUSTOMER_TYPES.CT_BUSINESS ? FinanceTypes.CASH_BUSINESS : SUMMARY_PANEL_CASH_TAB;
      }

      return Object.assign({}, newState, { formattedPrices: formatPrices(newState, app_state, action) })
    }

    case TOGGLE_LOAN_TYPE:
      return Object.assign({}, state, {
        loanType: action.loanType,
        loanTerm: [`Financial.fms_loan.loan[0].variables.loanTerm.${action.loanType}`], // @todo support financeProductId
      });

    case OMS_RECEIVED_DEPENDENCIES: {
      let newSelectedTab = state.selected_tab;
      const isEnterprise = _get(app_state, 'App.isEnterpriseOrder', false);
      if (isEnterprise) {
        return {
          ...state,
        }
      }

      switch (state.selected_tab) {
        case 'loan':
        case 'lease':
        case 'lease.contract_hire': {
          let financeType = state.selected_tab;
          financeType = financeType.indexOf('.') !== -1 ? financeType.split('.')[0] : financeType;
          // We filter out failed ALD products by checking 'incomplete'.
          const productData = _get(action, `data.${financeType}.${financeType}`, []).filter(item => !item.incomplete);
          if (productData.length) {
            let defaultProduct = _find(productData, item => item.default);
            defaultProduct = defaultProduct || productData[0];

            if (defaultProduct.id) {
              newSelectedTab = `${financeType}.${defaultProduct.id}`;
            }
          }
          break;
        }
      }
      const isDiscountedVehicle = vehicleHasDiscounts();
      if (state?.defaultDiscountedToCash && isDiscountedVehicle) {
        newSelectedTab = SUMMARY_PANEL_CASH_TAB;
      }

      // If we can find a Finplat product that is marked as default, we will default to that.
      // If not, we will let the Configurator decide what is the default.
      const isFinplatEnabled = _get(app_state, 'App.isFinplatEnabled', false);
      const { finplat, lexicon } = action?.data || {};
      const trim = lexicon?.sku?.default_trim || '';
      const finplatProducts = finplat?.[trim] || finplat || {};
      let finplatSelectedTab = SUMMARY_PANEL_CASH_TAB;
      if (!isDiscountedVehicle && isFinplatEnabled && !isLeasingUnavailableInState(formState)) {
        const setSelected = _findKey(finplatProducts, ({ parameters: { isDefault = false } = {} }) => isDefault);
        if (Object.keys(finplatProducts).length && typeof setSelected === 'string') {
          finplatSelectedTab = `finplat.${setSelected}`;
        }

        // Only if the legacy selected tab is NOT an ALD product, we will override the default with the
        // default from Finplat.
        if (newSelectedTab !== 'lease.operational_lease') {
          newSelectedTab = finplatSelectedTab;
        }
      }

      const finplatDefaultCustomerType = newSelectedTab.includes('CT_BUSINESS') ? 'business' : 'personal';

      return {
        ...state,
        selected_tab: newSelectedTab,
        finplatDefaultCustomerType: isFinplatEnabled ? finplatDefaultCustomerType : null,
      }
    }

    case TOGGLE_PROBABLE_SAVINGS: {
      return {
        ...state,
        showProbableSavings: action.toggle,
      }
    }

    case LOCATION_CHANGED: {
      const { regionCode } = action?.location?.region || {};
      const { selected_tab: tab } = state || {};
      const financeType =  tab ? tab.split(':')[0] : '';
      const isLeasingUnavailable = regionCode && financeType?.includes(PRODUCT_TYPE.LEASE) && isLeasingUnavailableInState({
        ...formState,
        SummaryPanel: { ...state, region_code: regionCode },
      });
      return {
        ...state,
        ...(isLeasingUnavailable ? { selected_tab: SUMMARY_PANEL_CASH_TAB } : {}),
      };
    }

    case SWITCH_TO_DEFAULT_TRIM:
      return {
        ...state,
        switchToDefaultTrim: action?.payload || {},
      }

    case FINANCE_MODAL_CLOSE: 
      return {
        ...state,
        switchToDefaultTrim: {},
      }

    default:
        return state;
  }
}

export default SummaryPanel;
