import React, { useEffect } from 'react';
import { number, bool, string} from 'prop-types';
import { connect } from 'react-redux';
import { i18n } from 'utils';
import { getPrice, getActiveFinanceProducts, getTaxCreditCurrentAvailable } from 'selectors';
import Analytics from 'analytics';
import { SUMMARY_PANEL_LEASE_TAB } from 'dictionary';

const FederalIncentiveChipShort = ({
  showBlock,
  model,
  price,
  federalTaxCredit,
  hasLeaseProduct,
}) => {
  if (Math.abs(federalTaxCredit) || !showBlock) {
    return null;
  }

  useEffect(() => {
    Analytics.fireTagEvent({
      event: Analytics.event,
      interaction: 'not-qualifiy-federal-tax-credit',
      'cfg-type': `inventory-${model}`,
    });
  }, []);

  return (
    <div className="tds-text--caption tds-text--center tds--padding_top-16 tds--padding_bottom-8">
      <span className="tds-chip tds-o-chip tds-chip--full-width">
        <span className="tds-text--contrast-high tds-text--medium">
          {i18n(hasLeaseProduct ? 'Incentives.federalIncentive.disclaimerNotQualifiedLease__new' : 'Incentives.federalIncentive.disclaimerNotQualifiedNoLease__new')}
         </span>
      </span>
    </div>
  );
};

const mapStateToProps = state => {
  const { App = {}, ReviewDetails = {} } = state;
  const { isEnterpriseOrder = false } = App;
  const { product = {}, showIncentiveBlock = [] } = ReviewDetails;
  const { data = {}, isUsedInventory } = product || {};
  const { Model: model = '' } = data || {};
  const federalTaxCredit = getTaxCreditCurrentAvailable(state);
  const price = getPrice(state, 'cash', {cashPriceValue: true}) || 0;
  const showBlock = price && !isUsedInventory && !isEnterpriseOrder && showIncentiveBlock?.includes(model);
  
  const activeFinanceProducts = getActiveFinanceProducts(state);
  const hasLeaseProduct = !!activeFinanceProducts?.find((product) => product?.toLowerCase()?.includes(SUMMARY_PANEL_LEASE_TAB));

  return {
    model,
    price,
    showBlock,
    federalTaxCredit,
    hasLeaseProduct,
  };
};

FederalIncentiveChipShort.propTypes = {
  model: string,
  showBlock: bool,
  federalTaxCredit: number,
  hasLeaseProduct: bool,
};

FederalIncentiveChipShort.defaultProps = {
  model: '',
  price: 0,
  showBlock: false,
  federalTaxCredit: 0,
  hasLeaseProduct: true,
};

export default connect(mapStateToProps)(FederalIncentiveChipShort);
